import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Multimedia from "../Subcomponents/Multimedia";
import { Icon } from "../../IconLib";

function AziendaArticle(props: { container: ContainerType }) {
    let image, ctas;
    if (props.container) {
        if (props.container.containers) {
            const _images = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT");
            if (_images) {
                image = _images[0];
            }
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }
    return <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:pt-20">
        <Multimedia loading="lazy" containerRef={image} large className="block order-2 w-full lg:w-[567px] mt-9 lg:mt-0 lg:order-1" />
        <div className="order-1 lg:order-2 relative">
            <div className="px-4 lg:px-0 lg:pl-6">
                <h1 className="pt-9 pb-7 lg:pt-6 lg:pb-16 text-h1 lg:text-md-h1">{props.container.title}</h1>
                <div className="text-p lg:text-md-p" dangerouslySetInnerHTML={{ __html: props.container.description || "" }}></div>
                {ctas && ctas.length > 0 ? <div className="absolute bottom-10 hidden lg:block">{ctas.map((cta, ctak) => {
                    return <div key={ctak} >
                        {cta.routeTo ? <Link className="underline hover:no-underline flex items-center text-p lg:text-md-p" to={cta.routeTo || "/"} >
                            {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                        </Link> : <></>}
                        {cta.externalUrl ? <a className="underline hover:no-underline flex items-center text-p lg:text-md-p" href={cta.externalUrl || "/"}>
                            {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                        </a> : <></>}</div>
                })}</div>: <></>}
            </div>
        </div>
    </div>
}


export function IntroAzienda(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const articlesReact =
        articles.map((ba, bak) => {
            return <AziendaArticle container={ba} key={bak} />
        })

    return (
        <section className="azienda-article">
            {articlesReact}
        </section>
    );
}
import React, { useEffect, useRef, useState } from "react";
import { ContainerRef, Container } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import Multimedia from "../Subcomponents/Multimedia";
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react';
import classNames from "classnames";
import { Icon } from "../../IconLib";
import { HighlightContainer } from "./Highlight";

export default function ScrollGallery(props: { container: Container }) {
    const { container } = props;
    const [currentSlide, _setCurrentSlide] = useState(0)
    const [currentSlideContainer, setCurrentSlideContainer] = useState<Container>();
    let items: ContainerRef[] = [];
    let ctas: ContainerRef[] = [];
    const { dataBackend } = useFEContext();
    const setCurrentSlide = (inde: number) => {
        _setCurrentSlide(inde);
        if (items && items.length > 0) {
            dataBackend.getContainer(items[inde].id).then(c => setCurrentSlideContainer(c));
        }
    }
    useEffect(() => {
        setCurrentSlide(0);
    }, [props.container])

    if (container) {
        let _items: Container[] | undefined = [];
        _items = container.containers?.filter(cc => cc.containerClass === "CONTENT");
        if (!_items || _items.length === 0) {
            _items = container.containers?.filter(cc => cc.containerClass === "MULTIMEDIA_CONTENT");
        }
        if (!_items || _items.length === 0) {
            _items = container.containers?.filter(cc => cc.containerClass === "BLOCK" && cc.directAction === 'Highlight');
        }
        ctas = container.containers?.filter(cc => cc.containerClass === "BLOCK" && cc.directAction !== 'Highlight') || [];
        items = _items || [];
    }

    const hideNav = container.config ? container.config.hideNav : false;
    const fullScreen = container.config ? container.config.fullScreen : false;
    return <section data-container-id={container.id} className="scroll-gallery-section pt-9 md:pt-16">
        <div className="relative">
            {!hideNav ? <div className="mx-4 max-w-7xl xl:mx-auto min-h-[2rem] flex items-center justify-between mb-6">
                {container?.showTitle ? <h3 className="title text-h2 md:text-md-h2">{container.title}</h3> : <div className="text-h2 md:text-md-h2">&nbsp;</div>}
            </div> : <></>}
            {container && container.introduction && container.introduction?.length > 0 ? <div className="relative mx-4 max-w-7xl xl:mx-auto mb-6 md:mb-8 text-p md:text-md-p">
                <div dangerouslySetInnerHTML={{ __html: container.introduction || "" }} />
                {ctas.map((cta, ctak) => {
                    return <div className="mt-4" key={ctak}>{cta.externalUrl ? <a className="inline-flex items-center underline" href={cta.externalUrl}>{cta.title || "Scopri di più"} <Icon name="chevron" className="ml-2 w-5 h-5" /></a> : <></>}</div>;
                })}
            </div> : <></>}
            {currentSlideContainer && currentSlideContainer.description && currentSlideContainer.description?.length > 0 ? <div className="relative mx-4 max-w-7xl xl:mx-auto mb-4" dangerouslySetInnerHTML={{ __html: currentSlideContainer.description || "" }} /> : <></>}
            {items && items.length > 0 ? <div data-container-id={container.id}><ContainerScrollGallery fullScreen={fullScreen} hideNav={hideNav} narrowSlides={container.config ? container.config.narrow : false} items={items} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} /></div> : <></>}
        </div>
    </section>;
}

function GalleryItem(props: { className?: string, containerRef: ContainerRef }) {
    return (
        <div className={classNames(props.className ? props.className : "w-64")}>
            {props.containerRef?.containerClass === "MULTIMEDIA_CONTENT" ? <Multimedia className="w-full" containerRef={props.containerRef} /> : <></>}
            {props.containerRef?.containerClass === "CONTENT" ? <ArticleGalleryItem containerRef={props.containerRef} /> : <></>}
            {props.containerRef?.containerClass === "BLOCK" ? <HighlightGalleryItem containerRef={props.containerRef} /> : <></>}
        </div>
    );
}
function HighlightGalleryItem(props: { containerRef: ContainerRef }) {
    const [container, setContainer] = useState<Container>();
    const { dataBackend } = useFEContext();
    useEffect(() => {
        if (props.containerRef) {
            dataBackend.getContainer(props.containerRef.id).then(c => {
                if (c) {
                    setContainer(c);
                }
            })
        }

    }, [props.containerRef]);


    return <> {container ? <HighlightContainer container={container} /> : <></>} </>;

}
function ArticleGalleryItem(props: { containerRef: ContainerRef }) {
    const [container, setContainer] = useState<Container>();
    const { dataBackend } = useFEContext();
    useEffect(() => {
        if (props.containerRef) {
            dataBackend.getContainer(props.containerRef.id).then(c => {
                if (c) {
                    setContainer(c);
                }
            })
        }

    }, [props.containerRef]);

    return <>

        <div className="bg-antoniazzi-900 text-white px-2 py-1.5 text-h2 md:text-md-h2">{container?.title}</div>
        <div className="px-2 py-2 text-h4 md:text-md-h4">{container?.subtitle}</div>
    </>;

}

function ContainerScrollGallery(props: { items: ContainerRef[], fullScreen?: boolean, hideNav: boolean, narrowSlides: boolean, currentSlide: number, setCurrentSlide: Function }) {

    const { currentSlide, setCurrentSlide, items } = props;

    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        loop: false,
        initial: 0,
        rtl: false,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        mode: "snap",
        slides: { perView: "auto", spacing: 6 },
    })
    return <>
        {loaded && !props.hideNav && instanceRef.current && (
            <div className="absolute  top-2 scroll-gallery-nav">
                <button className="pr-2 cursor-pointer" onClick={(e) => {
                    if (currentSlide > 0)
                        setCurrentSlide(currentSlide - 1);
                    e.stopPropagation();
                    instanceRef.current?.prev()
                }}
                    disabled={currentSlide === 0}
                ><Icon name="chevron" className="w-5 h-5 rotate-180" /> </button>
                <button className="pl-2 cursor-pointer" onClick={(e) => {
                    setCurrentSlide(currentSlide + 1);
                    e.stopPropagation();
                    instanceRef.current?.next()
                }
                }
                    disabled={
                        currentSlide ===
                        instanceRef.current.track.details.slides.length - 1
                    }> <Icon name="chevron" className="w-5 h-5" /></button>
            </div>
        )
        }
        <div className={classNames("scroll-gallery", props.fullScreen ? "scroll-gallery-fullscreen" : "")}>
            <div ref={sliderRef} className="keen-slider">
                {items?.map((aa, aak) => {
                    return <div className={classNames("keen-slider__slide", props.narrowSlides ? "min-w-[10rem] max-w-[10rem] md:min-w-[16rem] md:max-w-[16rem]" : "min-w-[16rem] max-w-[16rem]")} key={aak}><GalleryItem className="w-full" containerRef={aa} /></div>
                })}
            </div>
        </div>
        {loaded && instanceRef.current && (<>
            <div className="mx-4 pt-3 pb-7 max-w-7xl xl:mx-auto">
                <input className={classNames(
                    "relative w-full mb-[5px] box-border appearance-none  flex items-center cursor-pointer bg-transparent z-[19]",
                    "[&::-webkit-slider-thumb]:border-0 [&::-webkit-slider-thumb]:h-[3px] [&::-webkit-slider-thumb]:rounded-sm [&::-webkit-slider-thumb]:-translate-y-[1px] [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-antoniazzi-900 [&::-webkit-slider-runnable-track]:h-[1px] [&::-webkit-slider-runnable-track]:bg-antoniazzi-300 [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:appearance-none bg-opacity-80",
                    "[&::-webkit-slider-thumb]:w-[29px]")} type="range" step={1} min={0} max={items.length - 1} value={(currentSlide)} onChange={(ev) => {
                        setCurrentSlide(ev.target.valueAsNumber);
                        instanceRef.current?.moveToIdx(ev.target.valueAsNumber)
                    }} />
            </div>
        </>
        )} </>;
}
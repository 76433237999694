import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { AlertType, CartType, ContainerType, ContainerRefType, FEContextType, PowerLayerType, UserType, SessionType } from "../Interfaces";
import { DataBackend } from "../DataBackend";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { AlertPropsType, BackdropPropsType } from "../backend/BEContext";
import { Location, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PowerlayerGallery } from "./Wrapper";
import CartPanel from "./Subcomponents/CartPanel";
import { Localizer } from "../Localizer";
import LoginRegistrationPanel from "./Subcomponents/LoginRegistrationPanel";
import Preventivo, { PreventivoSemplice, RichiestaInformazioni } from "./Subcomponents/Preventivo";
import SearchPanel from "./Subcomponents/SearchPanel";
import Candidatura from "./Subcomponents/Candidatura";
import { Icon } from "../IconLib";
import CartEditDestination from "./Subcomponents/CartEditDestination";
const logo = <svg className="w-full h-full" fill="currentColor" viewBox="0 0 153.14 16.74"><path d="m6.61,4.45h.21l2.24,6.4h-4.67l2.21-6.4ZM7.42,0L1.92,15.68H0v.68h5.17v-.68h-2.45l1.41-4.13h5.18l1.43,4.13h-2.42v.68h7.53v-.68h-1.95L8.27.03V0h-.85Z" /><path d="m28.51.34v.7h2.32v9.94h-.18L23.82.35v-.02h-6.19v.7h2.32v14.64h-2.32v.68h5.38v-.68h-2.32V1.42h.16l9.76,15.18v.02h.96V1.04h2.32V.34h-5.38Z" /><path d="m49.98.36h-13.86l-.22,5.1v.04h.79v-.03c.66-3.54,1.53-4.51,4.05-4.51h.7v14.72h-2.56v.68h8.32v-.68h-2.58V.96c.25-.02.5-.02.73-.02,2.46,0,3.44,1.1,4.05,4.53v.03h.8l-.22-5.11v-.03Z" /><path id="Tracciato_121" d="m60,0c-2.01,0-3.93.86-5.25,2.37-1.41,1.68-2.16,3.82-2.11,6.01-.05,2.18.7,4.31,2.1,5.99,2.56,2.9,6.98,3.18,9.88.62.22-.19.43-.4.62-.62,1.4-1.68,2.14-3.8,2.09-5.99.05-2.19-.69-4.33-2.1-6.01C63.92.86,62.01-.01,60,0m-3.88,6.4c0-4.06,1.12-5.72,3.86-5.72s3.86,1.65,3.86,5.72v3.94c0,4.06-1.12,5.72-3.86,5.72s-3.86-1.66-3.86-5.72v-3.94Z" /><path id="Tracciato_122" d="m80.43,1.04h2.32v9.94h-.18L75.74.35v-.02h-6.19v.7h2.32v14.64h-2.32v.68h5.38v-.68h-2.32V1.42h.15l9.76,15.18v.02h.96V1.04h2.32V.34h-5.38v.7Z" /><path id="Tracciato_123" d="m88.04,1.04h2.34v14.64h-2.34v.68h7.82v-.68h-2.31V1.04h2.31V.34h-7.82v.7Z" /><path id="Tracciato_124" d="m105.91.03V0h-.85l-5.5,15.68h-1.92v.67h5.17v-.67h-2.44l1.41-4.13h5.18l1.43,4.13h-2.42v.67h7.53v-.67h-1.94L105.91.03Zm.79,10.82h-4.67l2.22-6.4h.21l2.24,6.4Z" /><path id="Tracciato_125" d="m127.17,11.05c-.64,3.55-1.7,4.63-4.55,4.63h-3.78v-.12l9-14.38h0V.34h-11.52l-.32,4.35h.72v-.03c.63-2.71,1.75-3.62,4.42-3.62h3.09v.12l-8.96,14.38h0v.82h12.29l.34-5.34h-.72v.03Z" /><path id="Tracciato_126" d="m141.98,11.05c-.64,3.55-1.7,4.63-4.55,4.63h-3.78v-.12l9-14.38h0V.34h-11.52l-.32,4.35h.72v-.03c.63-2.71,1.75-3.63,4.41-3.63h3.09v.12l-8.96,14.38h0v.82h12.29l.34-5.34h-.72v.04Z" /><path id="Tracciato_127" d="m153.14,1.04V.34h-7.82v.7h2.34v14.64h-2.34v.68h7.82v-.68h-2.32V1.04h2.32Z" /></svg>;


const FEContext = createContext<FEContextType>({
    cart: undefined,
    user: undefined,
    //@ts-ignore
    setCart: () => { },
    setUser: () => { },
    refreshSession: () => { },
    terminateSession: () => { },
    updateSession: () => { },
    strings: { get: () => { return ""; } },
    showAlert: () => { },
    showToast: () => { },
    showCart: () => { },
    hideCart: () => { },
    showPowerLayer: () => { },
    setShowGallery: () => { },
    setInitialGallerySlide: () => { },
    setGalleryImages: () => { },
    galleryImages: undefined,
    container: undefined,
    site: undefined,
    dataBackend: {} as DataBackend,
    showLoadingOverlay: () => { },
    showMainMenuOverlay: () => { }
});
export const useKeyDown = (callback: any, keys: any) => {
    const onKeyDown = (event: any) => {
        const wasAnyKeyPressed = keys.some((key: any) => event.key === key);
        if (wasAnyKeyPressed) {
            event.preventDefault();
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);
};

type FEProviderPropsType = {
    dataBackend: DataBackend
    children?: React.ReactNode
}
const PowerLayer = (props: { powerLayer?: PowerLayerType, children?: any, className?: string }) => {
    const pl: PowerLayerType = props.powerLayer || { active: false };

    useKeyDown(() => {
        if (pl && pl.onClose) {
            pl.onClose();
        }
    }, ["Escape"]);
    //bottom-0 

    return <><Transition
        className="fixed left-0 right-0 bottom-0 z-50"
        show={pl.active}
        enter="transition-all delay-75 duration-150"
        enterFrom="translate-y-full pointer-events-none"
        enterTo="translate-y-0 pointer-events-auto"
        leave="transition-all duration-150"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full pointer-events-none"
    >
        <div className="max-w-3xl mx-auto"><button className="w-6 h-6 mb-8 block mx-auto xl:ml-auto xl:mr-2 text-white/80" onClick={pl.onClose}><XMarkIcon className="w-full h-full" /></button></div>
        <div className={classNames(props.className || "", "bg-white max-h-[90svh] rounded-t-2xl shadow overflow-hidden overflow-y-auto overscroll-contain max-w-3xl mx-auto")}>{props.children}</div>
    </Transition>
        {/*<Transition className="fixed  top-0 right-0 left-0 bottom-0 overflow-hidden z-40 bg-antoniazzi-900/70 backdrop-blur-lg"
            show={pl.active}
            enter="transition-all duration-75"
            enterFrom="opacity-0 pointer-events-none"
            enterTo="opacity-100 pointer-events-auto"
            leave="transition-scale delay-150 duration-75"
            leaveFrom="opacity-100"
leaveTo="opacity-0 pointer-events-none" onClick={pl.onClose} />*/}</>;
}
const Alert = (props: AlertPropsType) => {
    const alert = props.alert;
    const s: any = alert && alert.severity ? alert.severity : "warning";
    const title = alert ? alert.title || "Attenzione!" : "";
    const active: boolean = alert !== undefined && alert.active;

    useKeyDown(() => {
        alert.onClick();
    }, ["Escape"]);


    const alertComponent =
        <div className="bg-white rounded-sm overflow-hidden text-antoniazzi-900 w-80 mx-auto z-[51] shadow-lg ">
            <div className={classNames(
                "border p-4",
                s === 'warning' ? "bg-alert-warning/20 border-alert-warning" : "",
                s === 'error' ? "bg-alert-error/20 border-alert-error" : "",
                s === 'success' ? "bg-alert-success/20 border-alert-success" : "",
            )}>
                <div className="flex items-center px-2 mb-4">
                    <div className="flex-shrink-0">
                        {s === 'warning' ? <Icon name="alert-warning-icon" className="h-7 w-7 text-alert-warning" /> : <></>}
                        {s === 'error' ? <Icon name="alert-error-icon" className="h-7 w-7 text-alert-error" /> : <></>}
                        {s === 'success' ? <Icon name="alert-success-icon" className="h-7 w-7 text-alert-success" /> : <></>}
                    </div>
                    <div className="mt-0 text-sm ml-4">
                        {props.children}
                    </div>
                </div>



                {!alert.confirm ? <button className="px-1 py-2 font-bold text-sm w-full block text-center bg-white/40 rounded-sm hover:bg-white/60" onClick={alert.onClick ? alert.onClick : () => { }}>OK</button> :
                    <div className="grid gap-x-3 grid-cols-2">
                        <button className="px-1 py-2 font-bold text-sm w-full block text-center bg-white/40 rounded-sm hover:bg-white/60" onClick={alert.onConfirm ? alert.onConfirm : () => { }}>OK</button>
                        <button className="px-1 py-2 font-bold text-sm w-full block text-center bg-white/40 rounded-sm hover:bg-white/60" onClick={alert.onClick ? alert.onClick : () => { }}>Annulla</button>
                    </div>}
            </div></div>;
    return <Transition onClick={alert.onClick} className=" fixed top-1/2 -translate-y-1/2 z-50 left-4 right-4" show={active} enter="transition-scale duration-150"
        enterFrom="scale-0 pointer-events-none"
        enterTo="scale-100 pointer-events-auto"
        leave="transition-scale duration-150"
        leaveFrom="scale-100"
        leaveTo="scale-0 pointer-events-none">{alertComponent}</Transition>
        ;
}

export function AlertInline(props: AlertPropsType) {
    const alert = props.alert;
    const s: any = alert && alert.severity ? alert.severity : "warning";
    const active: boolean = alert !== undefined && alert.active;
    const alertComponent =
        <button onClick={alert.onCancel} className="bg-white block w-full text-left rounded-sm overflow-hidden my-4 text-antoniazzi-900">
            <div className={classNames(
                "border p-4",
                s === 'warning' ? "bg-alert-warning/20 border-alert-warning" : "",
                s === 'error' ? "bg-alert-error/20 border-alert-error" : "",
                s === 'success' ? "bg-alert-success/20 border-alert-success" : "",
            )}>
                <div className="flex items-center px-2">
                    <div className="flex-shrink-0">
                        {s === 'warning' ? <Icon name="alert-warning-icon" className="h-7 w-7 text-alert-warning" /> : <></>}
                        {s === 'error' ? <Icon name="alert-error-icon" className="h-7 w-7 text-alert-error" /> : <></>}
                        {s === 'success' ? <Icon name="alert-success-icon" className="h-7 w-7 text-alert-success" /> : <></>}
                    </div>
                    <div className="mt-0 text-sm ml-4">
                        {props.alert.message}
                    </div>
                </div>
            </div>
        </button>;
    return <>{active ? <>{alertComponent}</> : <></>}</>;
}
const Toast = (props: AlertPropsType) => {
    const alert = props.alert;
    const s: any = alert && alert.severity ? alert.severity : "warning";
    const title = alert ? alert.title : undefined;
    const active: boolean = alert !== undefined && alert.active;

    useKeyDown(() => {
        if (alert && alert.onClick) {
            alert.onClick();
        }
    }, ["Escape"]);

    const alertComponent = <button onClick={alert.onClick ? alert.onClick : () => { }} className={classNames("rounded-md border max-w-xl mx-auto shadow-lg",
        (s === 'warning' ? "bg-alert-warning/20 border-alert-warning" : ""),
        (s === 'error' ? "bg-alert-error/20 border-alert-error" : ""),
        (s === 'success' ? "bg-alert-success/20 border-alert-success" : ""),
    )}>
        <div className="flex items-center p-2">
            <div className="flex-shrink-0">
                {s === 'warning' ? <Icon name="alert-warning-icon" className="h-5 w-5 text-alert-warning" aria-hidden="true" /> : <></>}
                {s === 'error' ? <Icon name="alert-error-icon" className="h-5 w-5 text-alert-error" aria-hidden="true" /> : <></>}
                {s === 'success' ? <Icon name="alert-success-icon" className="h-5 w-5 text-alert-success" aria-hidden="true" /> : <></>}
            </div>
            <div className="ml-1 w-full">
                {title ? <h3 className={classNames("text-xs font-medium",
                    (s === 'warning' ? "text-alert-warning" : ""),
                    (s === 'error' ? "text-alert-error" : ""),
                    (s === 'success' ? "text-alert-success" : ""))}>{title}</h3> : <></>}
                <div className={classNames(title ? "mt-2" : "", " text-sm",
                    (s === 'warning' ? "text-alert-warning" : ""),
                    (s === 'error' ? "text-alert-error" : ""),
                    (s === 'success' ? "text-alert-success" : ""))}>
                    {props.children}
                </div>
            </div>
        </div>

    </button>;
    return <Transition className="fixed top-2 right-2 z-50" show={active} 
        enter="transition-all duration-150"
        enterFrom="translate-x-full pointer-events-none"
        enterTo="translate-x-0 pointer-events-auto"
        leave="transition-scale duration-150"
        leaveFrom="scale-100"
        leaveTo="scale-0 pointer-events-none">{alertComponent}</Transition>;
}
const Backdrop = (props: BackdropPropsType) => {
    const onClick = props.onClick || function () { };
    return <>
        {props.children}
        <Transition show={props.visible} enter="transition-opacity duration-250"
            enterFrom="opacity-0 pointer-events-none"
            enterTo="opacity-100 pointer-events-auto"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 pointer-events-none" className="fixed top-0 left-0 right-0 bottom-0 z-40 bg-antoniazzi-900/70 backdrop-blur-lg" onClick={onClick}>
        </Transition>
    </>;
}



export const FEProvider = (props: FEProviderPropsType) => {
    const [prevLocation, setPrevLocation] = useState<Location>();
    const [alert, setAlert] = useState<AlertType>({ active: false });
    const [toast, setToast] = useState<AlertType>({ active: false });
    const [cart, _setCart] = useState<CartType>({});
    const [user, _setUser] = useState<UserType>();
    const [toastTimeout, setToastTimeout] = useState<number>();
    const [container, setContainer] = useState<ContainerType>();
    const [site, setSite] = useState<ContainerType>();
    const [loading, setLoading] = useState<boolean>(true);
    const [mainMenuOverlay, setMainMenuOverlay] = useState<boolean>(false);
    const [showGallery, setShowGallery] = useState<boolean>(false);
    const [initialGallerySlide, setInitialGallerySlide] = useState<number>(0);
    const [galleryImages, setGalleryImages] = useState<ContainerRefType[]>();
    const [initDone, setInitDone] = useState(false);

    const showCart = () => {
        const pl: PowerLayerType = {
            active: true, content: <CartPanel />, onClose: () => {
                setPowerLayer({ "active": false });
                window.location.hash = "";
            }
        };
        setPowerLayer(pl);
    };
    const showCheckout = () => {
        if (cart && cart.readyForCheckout) {
            //console.log("checkout", cart.readyForCheckout);
            const pl: PowerLayerType = {
                active: true, content: <CartPanel checkout={true} />, onClose: () => {
                    setPowerLayer({ "active": false });
                    window.location.hash = "";
                }
            };
            setPowerLayer(pl);
        } else {
            showCart();
        }
    };
    const showCartDestination = () => {
        if (cart && cart.readyForCheckout) {
            const pl: PowerLayerType = {
                active: true, content: <CartEditDestination />, onClose: () => {
                    setPowerLayer({ "active": false });
                    window.location.hash = "";
                }
            };
            setPowerLayer(pl);
        } else {
            console.error("cart problems", cart);
        }
    };
    const hideCart = () => {
        window.location.hash = '';
        const pl: PowerLayerType = {
            active: false
        };
        setPowerLayer(pl);
    };
    const refreshSession = () => {
        if (dataBackend.session) {
            dataBackend.updateStore(dataBackend.session).then((resp) => {
                _setCart(resp.cart);
                _setUser(resp.user);
            });
        }
    };
    const setCart = async (cart: CartType) => {
        if (!dataBackend.session) {
            const resp = await dataBackend.newStore({ cart: cart });
            _setCart(resp.cart);
            return resp.cart as CartType;
        } else {
            const s = { ...dataBackend.session };
            s.cart = cart;
            const resp = await dataBackend.updateStore(s);/*.then((resp) => {
                _setCart(resp.cart);
                return resp.cart as CartType;
            });*/
            _setCart(resp.cart);
            return resp.cart as CartType;
        }
    };
    const setUser = (newUser: UserType) => {
        if (!dataBackend.session) {
            dataBackend.newStore({ user: newUser }).then((resp) => {
                _setUser(resp.user);
            });
        } else {
            const s = { ...dataBackend.session };
            s.user = newUser;
            dataBackend.updateStore(s).then((resp) => {
                _setUser(resp.user);
            });
        }
    };
    let location = useLocation();
    useEffect(() => {
        setLoading(true);
        dataBackend.getSite().then(c => {
            setLoading(false);
            setSite(c);
            setTimeout(() => { setInitDone(true) }, 200);
        });
        if (dataBackend.session) {
            _setCart(dataBackend.session.cart);
            _setUser(dataBackend.session.user);
        }
    }, []);

    useEffect(() => {
        let loadContainer = true;
        const changeLocation = !prevLocation || (prevLocation?.pathname != location.pathname || prevLocation.search !== location.search);
        if (!changeLocation) {
            loadContainer = false;
        }
        if ("#cart" === location.hash) showCart();
        if ("#checkout" === location.hash) showCheckout();
        if ("#cart-destination" === location.hash) showCartDestination();
        if ("#search" === location.hash) {
            const pl: PowerLayerType = { active: true, content: <SearchPanel /> };
            showPowerLayer(pl);
        }
        if ("#login" === location.hash) {
            if (!user?.confermato && !user?.ospite) {
                const pl: PowerLayerType = { active: true, content: <LoginRegistrationPanel /> };
                showPowerLayer(pl);
            }
        }
        if ("#preventivo" === location.hash) {
            const pl: PowerLayerType = { active: true, content: <Preventivo /> };
            showPowerLayer(pl);
        }
        if ("#preventivo-semplice" === location.hash) {
            const pl: PowerLayerType = { active: true, content: <PreventivoSemplice /> };
            showPowerLayer(pl);
        }
        if ("#informazioni" === location.hash) {
            const pl: PowerLayerType = { active: true, content: <RichiestaInformazioni /> };
            showPowerLayer(pl);
        }
        if (location.hash.startsWith("#candidatura")) {
            const pl: PowerLayerType = { active: true, content: <Candidatura /> };
            showPowerLayer(pl);
        }
        if (loadContainer) {
            setLoading(true);
            window.scrollTo(0, 0);
            dataBackend.getContainer().then(c => {
                setLoading(false);
                setContainer(c);
                const titleTag = document.querySelector("head title");
                if (titleTag && c) {
                    titleTag.innerHTML = (site && site.title ? site?.title + " " : "") + c.title;
                }
            });
            setMainMenuOverlay(false);
        }
        setPrevLocation(location);
    }, [location]);


    const showAlert = (al: AlertType) => {
        if (!al.onClick) {
            al.onClick = () => {
                setAlert({ active: false });
            };
        }
        else {
            const oc = al.onClick;
            al.onClick = () => {
                oc();
                setAlert({ active: false });
            };
        }
        setAlert(al);
    }
    const showToast = (al: AlertType) => {
        clearTimeout(toastTimeout);
        if (!al.onClick) {
            al.onClick = () => {
                setToast({ active: false });
            };
        }
        else {
            const oc = al.onClick;
            al.onClick = () => {
                oc();
                setToast({ active: false });
            };
        }
        setToast(al);
        setToastTimeout(setTimeout(() => { setToast({ active: false }); }, 2500))
    }
    const showLoadingOverlay = (b: boolean) => {
        setLoading(b);
    }
    const showMainMenuOverlay = (b: boolean) => {
        setMainMenuOverlay(b);
    }


    let onBackdropClick = alert.onClick;
    const dataBackend = props.dataBackend;
    const [powerLayer, setPowerLayer] = useState<PowerLayerType>({ active: false });
    const showBackdrop = alert.active || loading || powerLayer.active;
    const showPowerLayer = (al: PowerLayerType) => {
        if (!al.active) {
            window.location.hash = "";
        }
        if (!al.onClose) {
            al.onClose = () => {
                window.location.hash = "";
                setPowerLayer({ active: false });
            };
        }
        else {
            const oc = al.onClose;
            al.onClose = () => {
                oc();
                window.location.hash = "";
                setPowerLayer({ active: false });
            };
        }
        setPowerLayer(al);
    }
    const strings = new Localizer();
    const updateSession = (payload: any) => {
        if (window.localStorage.getItem("xtstoredsession") != null) {
            if (payload.uuid !== window.localStorage.getItem("xtstoredsession")) {
                const al: AlertType = { active: true, message: strings.get("Si è verificato un errore, prova più tardi"), title: strings.get("Errore registrazione"), severity: "error" }
                showAlert(al);
                return;
            } else {
                _setCart(payload.cart);
                _setUser(payload.user);
                const se: SessionType = {
                    cart: payload.cart,
                    user: payload.user,
                    uuid: payload.uuid
                }
                dataBackend.setSession(se);
            }
        } else if (payload.uuid) {
            window.localStorage.setItem("xtstoredsession", payload.uuid)
            _setCart(payload.cart);
            _setUser(payload.user);
            const se: SessionType = {
                cart: payload.cart,
                user: payload.user,
                uuid: payload.uuid
            }
            dataBackend.setSession(se);

        }
    }
    const terminateSession = () => {
        if (window.localStorage.getItem("xtstoredsession") != null) {
            window.localStorage.removeItem("xtstoredsession")
        }
        _setCart({});
        _setUser(undefined);
        dataBackend.setSession(undefined);
    }
    const value: FEContextType = { strings, refreshSession, terminateSession, updateSession, user, setUser, showCart, hideCart, setInitialGallerySlide, setShowGallery, galleryImages, setGalleryImages, showAlert, cart, setCart, container, site, showMainMenuOverlay, showToast, showPowerLayer, showLoadingOverlay, dataBackend };
    /*const validateSession = async () => {
        clearTimeout(validateTimer);
        const url = new URL(dataBackend.endpointBase + "/wa/DataBackend/validateSession");
        url.searchParams.set("wosid", dataBackend.wosid);
        const resp = await fetch(url);
        const json = await resp.json();
        setValidSession(!json.error);
        if (!json.error) {
            setValidateTimer(setTimeout(() => {
                validateSession()
            }, 5 * 60 * 1000));
        }
    }
    useEffect(() => {
        validateSession();
    }, [dataBackend])*/


    /*if (powerLayer && powerLayer.active) {
        document.querySelector('html')?.classList.add("power-layer-active");
    } else {
        document.querySelector('html')?.classList.remove("power-layer-active");
    }*/

    return (<FEContext.Provider value={value}>
        {/*(!site || !container) && initDone ? <div className="bg-bordeaux text-white fixed top-0 left-0 right-0 z-50">container not found <Link to="/">home</Link></div> : <></>*/}
        <>{props.children}</>
        {mainMenuOverlay ? <div className="fixed z-10 backdrop-blur-lg top-14 bg-antoniazzi-900/50 bottom-0 left-0 right-0"></div> : <></>}

        <PowerLayer className={alert.active ? "grayscale opacity-75 pointer-events-none" : ""} powerLayer={powerLayer}>
            {powerLayer.content}
        </PowerLayer>
        <Backdrop visible={showBackdrop || false} onClick={onBackdropClick} />

        {loading ? <><div className="text-white font-bold top-1/2 animate-pulse fixed z-50 left-1/2 -translate-x-1/2 text-center -translate-y-1/2">
            {logo}
        </div></> : <></>}
        <PowerlayerGallery initial={initialGallerySlide} images={galleryImages || []} visible={showGallery} onClose={() => { setShowGallery(false); setInitialGallerySlide(0); }} />

        <Alert alert={alert}>
            {alert.message}
            <button className="w-full block"></button>
        </Alert>
        <Toast alert={toast}>
            {toast.message}
        </Toast>
    </FEContext.Provider>)
}

export function useFEContext() {
    const context = useContext(FEContext);
    if (!context) {
        throw new Error("useFEContext must be used within BEContext");
    }
    return context;
}

import { ContainerType, ContainerRefType, DeliveryMethodType, Entity, PayloadRequestType, SessionType } from "./Interfaces";
import Cookies from "js-cookie";
export interface ResponseType {
    records: any[]
    totalCount: number,
    pages: number,
    pageItems: number,
    currentPage: number,
    error: boolean,
    message?: string
}

export interface OrderingType {
    label: string
    value: string
}

export class LabelValueType {
    label: string
    value: string
}
export class DataBackend {
    static saveTimeout: number = 1500;
    endpointBase: string;
    wosid: string;
    lang: string;
    categorie: ContainerRefType[]
    tutteCategorie: ContainerRefType[]
    session: SessionType | undefined
    accessori: ContainerRefType[]
    metodiConsegna: DeliveryMethodType[]
    orderings: OrderingType[]
    paesi: LabelValueType[]
    province: LabelValueType[]
    constructor() {
        if (window.location.href.lastIndexOf(".woa") <= -1) {
            //console.warn("Verifica che la pagina sia un URL di WebObjects");
            this.endpointBase = window.location.protocol + "//" + window.location.host + "/Apps/WebObjects/Antoniazzi.woa";
        } else {
            this.endpointBase = window.location.href.substring(0, window.location.href.lastIndexOf(".woa") + 4);
        }
        if (window.sessionStorage.wosid) {
            this.wosid = window.sessionStorage.wosid;
        }
        this.paesi = [{ value: "IT", label: "Italia" }];
        this.province =
            [{ "label": "Agrigento", value: "AG" },
            { "label": "Alessandria", value: "AL" },
            { "label": "Ancona", value: "AN" },
            { "label": "Aosta", value: "AO" },
            { "label": "Arezzo", value: "AR" },
            { "label": "Ascoli Piceno", value: "AP" },
            { "label": "Asti", value: "AT" },
            { "label": "Avellino", value: "AV" },
            { "label": "Bari", value: "BA" },
            { "label": "Barletta-Andria-Trani", value: "BT" },
            { "label": "Belluno", value: "BL" },
            { "label": "Benevento", value: "BN" },
            { "label": "Bergamo", value: "BG" },
            { "label": "Biella", value: "BI" },
            { "label": "Bologna", value: "BO" },
            { "label": "Bolzano", value: "BZ" },
            { "label": "Brescia", value: "BS" },
            { "label": "Brindisi", value: "BR" },
            { "label": "Cagliari", value: "CA" },
            { "label": "Caltanissetta", value: "CL" },
            { "label": "Campobasso", value: "CB" },
            { "label": "Carbonia-Iglesias", value: "CI" },
            { "label": "Caserta", value: "CE" },
            { "label": "Catania", value: "CT" },
            { "label": "Catanzaro", value: "CZ" },
            { "label": "Chieti", value: "CH" },
            { "label": "Como", value: "CO" },
            { "label": "Cosenza", value: "CS" },
            { "label": "Cremona", value: "CR" },
            { "label": "Crotone", value: "KR" },
            { "label": "Cuneo", value: "CN" },
            { "label": "Enna", value: "EN" },
            { "label": "Fermo", value: "FM" },
            { "label": "Ferrara", value: "FE" },
            { "label": "Firenze", value: "FI" },
            { "label": "Foggia", value: "FG" },
            { "label": "Forlì-Cesena", value: "FC" },
            { "label": "Frosinone", value: "FR" },
            { "label": "Genova", value: "GE" },
            { "label": "Gorizia", value: "GO" },
            { "label": "Grosseto", value: "GR" },
            { "label": "Imperia", value: "IM" },
            { "label": "Isernia", value: "IS" },
            { "label": "La Spezia", value: "SP" },
            { "label": "L'Aquila", value: "AQ" },
            { "label": "Latina", value: "LT" },
            { "label": "Lecce", value: "LE" },
            { "label": "Lecco", value: "LC" },
            { "label": "Livorno", value: "LI" },
            { "label": "Lodi", value: "LO" },
            { "label": "Lucca", value: "LU" },
            { "label": "Macerata", value: "MC" },
            { "label": "Mantova", value: "MN" },
            { "label": "Massa-Carrara", value: "MS" },
            { "label": "Matera", value: "MT" },
            { "label": "Messina", value: "ME" },
            { "label": "Milano", value: "MI" },
            { "label": "Modena", value: "MO" },
            { "label": "Monza e della Brianza", value: "MB" },
            { "label": "Napoli", value: "NA" },
            { "label": "Novara", value: "NO" },
            { "label": "Nuoro", value: "NU" },
            { "label": "Olbia-Tempio", value: "OT" },
            { "label": "Oristano", value: "OR" },
            { "label": "Padova", value: "PD" },
            { "label": "Palermo", value: "PA" },
            { "label": "Parma", value: "PR" },
            { "label": "Pavia", value: "PV" },
            { "label": "Perugia", value: "PG" },
            { "label": "Pesaro e Urbino", value: "PU" },
            { "label": "Pescara", value: "PE" },
            { "label": "Piacenza", value: "PC" },
            { "label": "Pisa", value: "PI" },
            { "label": "Pistoia", value: "PT" },
            { "label": "Pordenone", value: "PN" },
            { "label": "Potenza", value: "PZ" },
            { "label": "Prato", value: "PO" },
            { "label": "Ragusa", value: "RG" },
            { "label": "Ravenna", value: "RA" },
            { "label": "Reggio Calabria", value: "RC" },
            { "label": "Reggio Emilia", value: "RE" },
            { "label": "Rieti", value: "RI" },
            { "label": "Rimini", value: "RN" },
            { "label": "Roma", value: "RM" },
            { "label": "Rovigo", value: "RO" },
            { "label": "Salerno", value: "SA" },
            { "label": "Medio Campidano", value: "VS" },
            { "label": "Sassari", value: "SS" },
            { "label": "Savona", value: "SV" },
            { "label": "Siena", value: "SI" },
            { "label": "Siracusa", value: "SR" },
            { "label": "Sondrio", value: "SO" },
            { "label": "Taranto", value: "TA" },
            { "label": "Teramo", value: "TE" },
            { "label": "Terni", value: "TR" },
            { "label": "Torino", value: "TO" },
            { "label": "Ogliastra", value: "OG" },
            { "label": "Trapani", value: "TP" },
            { "label": "Trento", value: "TN" },
            { "label": "Treviso", value: "TV" },
            { "label": "Trieste", value: "TS" },
            { "label": "Udine", value: "UD" },
            { "label": "Varese", value: "VA" },
            { "label": "Venezia", value: "VE" },
            { "label": "Verbano-Cusio-Ossola", value: "VB" },
            { "label": "Vercelli", value: "VC" },
            { "label": "Verona", value: "VR" },
            { "label": "Vibo Valentia", value: "VV" },
            { "label": "Vicenza", value: "VI" },
            { "label": "Viterbo", value: "VT" }];
        this.lang = "ita";
        Cookies.set("lang", this.lang);
        if (this.wosid) {
            if ("true" === window.sessionStorage.backend) {
                this.refreshAccessori();
            }

        }
        this.refreshCategorie();
        this.refreshMetodiConsegna();
        this.refreshOrderings();
        this.initStore();

    }

    async initData() {
        await this.refreshCategorie();
        await this.refreshMetodiConsegna();
        await this.refreshOrderings();
        await this.refreshCategorie();
        await this.initStore();
        await this.initPaypal();
    }

    async refreshMetodiConsegna() {
        const listUrl = new URL(this.endpointBase + "/wa/DataBackend/list");
        const acpayload: PayloadRequestType = { "entity": "DeliveryMethod" };
        const acopt: RequestInit = {
            method: "POST",
            body: JSON.stringify(acpayload)
        };
        fetch(listUrl, acopt).then(d => d.json()).then(data => {
            if (!data.error) {
                this.metodiConsegna = data.records;
            }
        });
    }
    async refreshOrderings() {
        const listUrl = new URL(this.endpointBase + "/wa/DataBackend/orderings");
        const acopt: RequestInit = {
            method: "POST"
        };
        fetch(listUrl, acopt).then(d => d.json()).then(data => {
            if (!data.error) {
                this.orderings = data.records;
            }
        });
    }
    async getJSON(u: URL) {
        const opt: RequestInit = {
            method: "GET",
        };
        const d = await fetch(u, opt);/*.then(d => d.json()).then(data => {
            if (!data.error) {
                this.categorie = data.records;
            }
        });*/
        if (d.status === 200) {
            const data = await d.json();
            return data as ResponseType;
        } else {
            throw "Errore";
        }
    }
    async refreshCategorie() {
        const payload: PayloadRequestType = { "entity": "Category", searchParams: { "all": false } };
        const u = new URL(this.endpointBase + "/wa/DataBackend/list");
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(payload)
        };
        fetch(u, opt).then(d => d.json()).then(data => {
            if (!data.error) {
                this.categorie = data.records;
            }
        });
        const allPayload: PayloadRequestType = { "entity": "Category", searchParams: { "all": true } };
        const uAll = new URL(this.endpointBase + "/wa/DataBackend/list");
        const optAll: RequestInit = {
            method: "POST",
            body: JSON.stringify(allPayload)
        };
        fetch(uAll, optAll).then(d => d.json()).then(data => {
            if (!data.error) {
                this.tutteCategorie = data.records;
            }
        });
    }
    async refreshAccessori() {
        const u = new URL(this.endpointBase + "/wa/DataBackend/list");
        /*u.searchParams.set("lang", "ita");
        u.searchParams.set("wosid", this.wosid);*/
        const acpayload: PayloadRequestType = { "entity": "Product", searchParams: { toRelate: true } };
        const acopt: RequestInit = {
            method: "POST",
            body: JSON.stringify(acpayload)
        };
        const resp = await fetch(u, acopt);
        const data = await resp.json();
        if (!data.error) {
            this.accessori = data.records;
        }

    }
    async upload(file: File, container: ContainerRefType) {
        const formData = new FormData();
        formData.append("wosid", this.wosid);
        if (container.id) //in questo caso l'immagine è una sostituzione
            formData.append("id", container.id + "");
        else if (container.idParentContainer) //in questo caso l'immagine è nuova
            formData.append("idParentContainer", container.idParentContainer + "");
        if (container.containerClass)
            formData.append("containerClass", container.containerClass);
        formData.append("zzz", file);
        const ops: RequestInit = {};
        ops.body = formData;
        ops.method = "POST";
        const response = await fetch(this.endpointBase + "/wa/DataBackend/upload", ops);
        const json = await response.json();
        return json;
    }
    async get(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/get");
        /*if (this.wosid)
            u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = [e];
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(pl)
        };

        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        return json as ResponseType;
    }
    async getAll(e: Entity[]) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/get");
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(e)
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        return json as ResponseType;
    }
    async list(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/list");
        /*if (this.wosid)
            u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = { ...e };
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(pl)
        };

        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async search(e: Entity, sp: any) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/list");
        if (this.wosid)
            u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);

        const pl: PayloadRequestType = { entity: e.entity, searchParams: sp };

        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(pl)
        };
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async delete(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/delete");
        /*u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = { ...e };
        const opt: RequestInit = {
            method: "DELETE",
            body: JSON.stringify(pl)
        };
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async put(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/put");
        /*u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = { ...e };
        const opt: RequestInit = {
            method: "PUT",
            body: JSON.stringify(pl)
        };
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async patch(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/patch");
        /*u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = { ...e };

        const opt: RequestInit = {
            method: "PATCH",
            body: JSON.stringify(pl)
        };
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async duplicate(e: Entity) {
        const u = new URL(this.endpointBase + "/wa/DataBackend/duplicate");
        /*u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("lang", this.lang);*/
        const pl = { ...e };
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(pl)
        };
        const resp = await fetch(u, opt);
        const json: ResponseType = await resp.json();
        return json as ResponseType;
    }
    async getContainer(idXTContainer?: number | string) {

        const url = new URL(window.location.href);
        const u = new URL(this.endpointBase + "/wa/get");

        const lang = url.searchParams.get("lang") || (window.localStorage.language || "ita");

        if (this.wosid)
            u.searchParams.set("wosid", this.wosid);

        if (idXTContainer === undefined) {
            if (window.location.href.lastIndexOf(".woa") > -1) {
                const id = url.searchParams.get("id");
                if (id) {
                    u.searchParams.set("id", id);
                }
                const name = url.searchParams.get("name");
                if (name) {
                    u.searchParams.set("name", name);
                }
                if (!id && !name) {
                    u.searchParams.set("id", "1");
                }

            } else {
                const name = window.location.pathname.substring(1);
                if (name.length > 0)
                    u.searchParams.set("name", name);
                else
                    u.searchParams.set("id", "1");
            }
        } else {
            u.searchParams.set("id", idXTContainer + "");
        }
        u.searchParams.set("lang", lang);

        const opt: RequestInit = {
            method: "GET",
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }

        const resp = await fetch(u, opt);
        const json = await resp.json();

        const r = json as ResponseType;
        if (!r.error) {
            return r.records[0] as ContainerType;
        } else {
            console.error("not found", u);
        }
        return undefined;
    }
    storeSessionId() {
        return window.localStorage.getItem("xtstoredsession");
    }
    async initStore() {
        const u = new URL(this.endpointBase + "/store/session");
        const opt: RequestInit = {
            method: "GET",
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
            const resp = await fetch(u, opt);
            const json = await resp.json();
            if (!json.error)
                this.session = json;
            else {
                this.session = undefined;
                window.localStorage.removeItem("xtstoredsession");
            }
        }
        else
            this.session = undefined;
    }

    async initPaypal() {
        const u = new URL(this.endpointBase + "/wa/ToolsAction/paypalKey");
        const opt: RequestInit = {
            method: "GET",
        };
        const v = await fetch(u, opt);
        window.localStorage.setItem("paypalKey", await v.text());
    }
    async newStore(payload?: any) {

        //console.log("newStore", payload);
        const u = new URL(this.endpointBase + "/store/session");
        const opt: RequestInit = {
            method: "PUT",
        };
        if (payload) {
            opt.body = JSON.stringify(payload)
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        //console.log("newStore response", json);
        if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }
        return json;
    }
    setSession(s: SessionType | undefined) {
        this.session = s;
    }
    async updateStore(payload: any) {
        const u = new URL(this.endpointBase + "/store/session");
        const opt: RequestInit = {
            method: "PATCH",
            body: JSON.stringify(payload)
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }
        return json;
    }
    async getSite() {
        const url = new URL(window.location.href);
        const u = new URL(this.endpointBase + "/wa/get");
        const lang = url.searchParams.get("lang") || (window.localStorage.language || "ita");
        if (this.wosid)
            u.searchParams.set("wosid", this.wosid);
        u.searchParams.set("name", "Antoniazzi");
        u.searchParams.set("lang", lang);
        const opt: RequestInit = {
            method: "GET",
        };
        const resp = await fetch(u, opt);
        const json = await resp.json();

        const r = json as ResponseType;
        if (!r.error) {
            return r.records[0] as ContainerType;
        }
        return undefined;
    }
    async paypalURL() {
        const u = new URL(this.endpointBase + "/wa/Orders/confirmLink");
        const opt: RequestInit = {
            method: "GET"
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }

        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        //console.log(json);
        return json;
    }
    async createOrder() {
        const u = new URL(this.endpointBase + "/wa/Orders/create");
        const opt: RequestInit = {
            method: "GET"
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        //console.log(json);
        return json;
    }
    async createXPayWeb() {
        const u = new URL(this.endpointBase + "/wa/Orders/createXPayWeb");
        const opt: RequestInit = {
            method: "GET"
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        //console.log(json);
        return json;
    }
    async createXPay() {
        const u = new URL(this.endpointBase + "/wa/Orders/createXPay");
        const opt: RequestInit = {
            method: "GET"
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        //console.log(json);
        return json;
    }
    async approveOrder(data: any) {
        const u = new URL(this.endpointBase + "/wa/Orders/approve");
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(data)
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        //console.log("approveOrder", data);
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        //console.log("approveOrder response", json);
        return json;
    }
    async captureOrder(data: any) {
        const u = new URL(this.endpointBase + "/wa/Orders/capture");
        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(data)
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        //console.log("captureOrder", data);
        const resp = await fetch(u, opt);
        const json = await resp.json();
        return json;
    }
    async updatePassword(ui: string, cc: string, p: string, cp: string, create: string | undefined | null, recaptcha: string) {
        const u = new URL(this.endpointBase + "/wa/ToolsAction/updatePassword");
        const fd = new FormData();
        fd.append("cc", cc);
        fd.append("uid", ui);
        fd.append("p", p);
        fd.append("cp", cp);
        if (create)
            fd.append("confirm", "true");
        fd.append("g-recaptcha-response", recaptcha);
        const opt: RequestInit = {
            method: "POST",
            body: fd
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        return json;
    }
    async startResetPassword(email: string, recaptcha: string) {
        const u = new URL(this.endpointBase + "/wa/ToolsAction/startResetPassword");
        const fd = new FormData();
        fd.append("e", email);
        fd.append("g-recaptcha-response", recaptcha);
        const opt: RequestInit = {
            method: "POST",
            body: fd
        };
        if (window.localStorage.getItem("xtstoredsession")) {
            const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
            //@ts-ignore
            opt.headers = headers;
        }
        const resp = await fetch(u, opt);
        const json = await resp.json();
        /*if (!json.error) {
            this.session = json;
            if (this.session) this.session.uuid = json.uuid;
            window.localStorage.setItem("xtstoredsession", json.uuid);
        }*/
        return json;
    }
}
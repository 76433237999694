
import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { ContainerType, ContainerRefType, PowerLayerType, AlertType } from "../Interfaces";
import { useFEContext } from "./FEContext";
import { Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import IconsLibrary, { Icon } from "../IconLib";
import { DataBackend } from "../DataBackend";
// import function to register Swiper custom elements
import { register } from 'swiper/swiper-element-bundle';
// register Swiper custom elements
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css'
import Multimedia from "./Subcomponents/Multimedia";
import classNames from "classnames";
const uk = <svg className="w-6 mr-2" id="uuid-6e6eb7c2-74e8-4691-ba7e-0b4650cee917" viewBox="0 0 15 10"><defs><clipPath id="uuid-186fa896-d03e-4825-b368-52ebe292c7b3"><rect x="0" width="15" height="10" fill="none" /></clipPath></defs><g clip-path="url(#uuid-186fa896-d03e-4825-b368-52ebe292c7b3)" fill="none"><rect x="0" y="0" width="15" height="10" fill="none" /><path d="m0,0v10h15V0H0Z" fill="#012169" /><polygon points="14.52 10.72 7.5 6.04 .48 10.72 -.48 9.28 5.94 5 -.48 .72 .48 -.72 7.5 3.96 14.52 -.72 15.48 .72 9.06 5 15.48 9.28 14.52 10.72" fill="#fff" /><polygon points="14.68 10.48 7.5 5.69 .32 10.48 -.32 9.52 6.46 5 -.32 .48 .32 -.48 7.5 4.31 14.68 -.48 15.32 .48 8.54 5 15.32 9.52 14.68 10.48" fill="#c8102e" /><polygon points="8.94 10 6.06 10 6.06 6.44 0 6.44 0 3.56 6.06 3.56 6.06 0 8.94 0 8.94 3.56 15 3.56 15 6.44 8.94 6.44 8.94 10" fill="#fff" /><polygon points="8.37 10 6.63 10 6.63 5.87 0 5.87 0 4.13 6.63 4.13 6.63 0 8.37 0 8.37 4.13 15 4.13 15 5.87 8.37 5.87 8.37 10" fill="#c8102e" /></g></svg>;
const ita = <svg id="a" className="w-6 mr-2" viewBox="0 0 15 10"><rect id="b" width="5" height="10" fill="#4c7958" /><rect id="c" x="5" width="5" height="10" fill="#fff" /><rect id="d" x="10" width="5" height="10" fill="#aa0d0d" /></svg>;
const logo = <svg className="w-full h-full" fill="currentColor" viewBox="0 0 153.14 16.74"><path d="m6.61,4.45h.21l2.24,6.4h-4.67l2.21-6.4ZM7.42,0L1.92,15.68H0v.68h5.17v-.68h-2.45l1.41-4.13h5.18l1.43,4.13h-2.42v.68h7.53v-.68h-1.95L8.27.03V0h-.85Z" /><path d="m28.51.34v.7h2.32v9.94h-.18L23.82.35v-.02h-6.19v.7h2.32v14.64h-2.32v.68h5.38v-.68h-2.32V1.42h.16l9.76,15.18v.02h.96V1.04h2.32V.34h-5.38Z" /><path d="m49.98.36h-13.86l-.22,5.1v.04h.79v-.03c.66-3.54,1.53-4.51,4.05-4.51h.7v14.72h-2.56v.68h8.32v-.68h-2.58V.96c.25-.02.5-.02.73-.02,2.46,0,3.44,1.1,4.05,4.53v.03h.8l-.22-5.11v-.03Z" /><path id="Tracciato_121" d="m60,0c-2.01,0-3.93.86-5.25,2.37-1.41,1.68-2.16,3.82-2.11,6.01-.05,2.18.7,4.31,2.1,5.99,2.56,2.9,6.98,3.18,9.88.62.22-.19.43-.4.62-.62,1.4-1.68,2.14-3.8,2.09-5.99.05-2.19-.69-4.33-2.1-6.01C63.92.86,62.01-.01,60,0m-3.88,6.4c0-4.06,1.12-5.72,3.86-5.72s3.86,1.65,3.86,5.72v3.94c0,4.06-1.12,5.72-3.86,5.72s-3.86-1.66-3.86-5.72v-3.94Z" /><path id="Tracciato_122" d="m80.43,1.04h2.32v9.94h-.18L75.74.35v-.02h-6.19v.7h2.32v14.64h-2.32v.68h5.38v-.68h-2.32V1.42h.15l9.76,15.18v.02h.96V1.04h2.32V.34h-5.38v.7Z" /><path id="Tracciato_123" d="m88.04,1.04h2.34v14.64h-2.34v.68h7.82v-.68h-2.31V1.04h2.31V.34h-7.82v.7Z" /><path id="Tracciato_124" d="m105.91.03V0h-.85l-5.5,15.68h-1.92v.67h5.17v-.67h-2.44l1.41-4.13h5.18l1.43,4.13h-2.42v.67h7.53v-.67h-1.94L105.91.03Zm.79,10.82h-4.67l2.22-6.4h.21l2.24,6.4Z" /><path id="Tracciato_125" d="m127.17,11.05c-.64,3.55-1.7,4.63-4.55,4.63h-3.78v-.12l9-14.38h0V.34h-11.52l-.32,4.35h.72v-.03c.63-2.71,1.75-3.62,4.42-3.62h3.09v.12l-8.96,14.38h0v.82h12.29l.34-5.34h-.72v.03Z" /><path id="Tracciato_126" d="m141.98,11.05c-.64,3.55-1.7,4.63-4.55,4.63h-3.78v-.12l9-14.38h0V.34h-11.52l-.32,4.35h.72v-.03c.63-2.71,1.75-3.63,4.41-3.63h3.09v.12l-8.96,14.38h0v.82h12.29l.34-5.34h-.72v.04Z" /><path id="Tracciato_127" d="m153.14,1.04V.34h-7.82v.7h2.34v14.64h-2.34v.68h7.82v-.68h-2.32V1.04h2.32Z" /></svg>;
const year = new Date().getFullYear();
export default function Wrapper() {
    const [sections, setSections] = useState<ContainerRefType[]>();
    const [topbar, setTopbar] = useState<ContainerRefType>();
    const [footerSections, setFooterSections] = useState<ContainerRefType[]>();
    const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
    const { showCart, showAlert, showPowerLayer, terminateSession, site, showMainMenuOverlay, cart, user, strings } = useFEContext();

    const [activeMenuItem, setActiveMenuItem] = useState<ContainerRefType>();
    const [activeFooterMenuItem, setActiveFooterMenuItem] = useState<ContainerRefType>();

    const [showMobileNav, _setShowMobileNav] = useState<boolean>(false);
    const setShowMobileNav = (v: boolean) => {
        showMainMenuOverlay(v);
        _setShowMobileNav(v);
    }
    useEffect(() => {
        register();
        if (site) {
            setSections(site.containers ? site.containers.filter(c => c.position === 'Center' && c.containerClass === "SECTION") : []);
            setFooterSections(site.containers ? site.containers.filter(c => c.position === 'Footer' && c.containerClass === "SECTION") : []);
            const topBars = site.containers ? site.containers.filter(c => c.position === 'Topbar' && c.containerClass === "CONTENT") : [];
            if (topBars.length > 0) {
                setTopbar(topBars[0]);
            }
        } else {
            setSections([]);
            setFooterSections([]);
        }
    }, [site]);


    const cartBadgeCount = cart && cart.rows ? cart.rows.length : undefined;

    const cartBadge = <>{cartBadgeCount ? <span className="rounded-full flex items-center  text-p-sm text-white bg-antoniazzi-900 text-center w-5 h-5 absolute justify-center -bottom-2 -right-2"><span>{cartBadgeCount}</span></span> : <></>}</>


    return (<>
        <IconsLibrary />
        <div className="sticky text-antoniazzi-900 z-20 top-0">
            {topbar ? <div className={"bg-antoniazzi-600 text-center text-white text py-2.5 lg:px-3 text-h5 lg:text-md-h5"}>
                {topbar?.title}
            </div> : <></>}
            <div className="hidden bg-white shadow-sm pointer-events-none lg:pointer-events-auto lg:flex lg:items-center px-11 py-7">
                <h1 className="grow-0 shrink-0 w-40 text-antoniazzi-900 mr-4" title="Antoniazzi"><a href="/">{logo}</a></h1>
                <div className="ml-auto flex items-center gap-x-10">
                    {sections?.map((s, ssk) => {
                        return <MenuItem key={ssk} activeMenuItem={activeMenuItem} onSelectMenuItem={(ref: ContainerRefType) => {
                            setActiveMenuItem(ref);
                        }} container={s} />;

                    })}
                    <div className="ml-9 flex items-center gap-x-5">
                        <a href={user?.confermato ? "/profilo" : "#login"} className="relative"><Icon name="omino-topbar" className="w-5 h-5" /></a>
                        <a href="#cart" className="relative"><Icon name="borsa-topbar" className="w-5 h-5" />{cartBadge}</a>
                        <a href="#search"><Icon name="lente-topbar" className="w-5 h-5" /></a>
                    </div>
                </div>
            </div>
            <div className="lg:hidden bg-white">
                <div className="flex p-4 text-antoniazzi-900">
                    <h1 className="uppercase font-bold w-[150px]" title="Antoniazzi"><a href="/">{logo}</a></h1>
                    <div className="ml-auto flex items-center gap-x-4">
                        <a href="#cart" className="relative"><Icon name="borsa-topbar" className="w-5 h-5" />{cartBadge}</a>
                        <a href="#search"><Icon name="lente-topbar" className="w-5 h-5" /></a>
                        <button onClick={() => { setShowMobileNav(!showMobileNav) }}><Icon name="bars" className="w-5 h-4" /></button>
                    </div>
                </div>
                <Transition
                    className="fixed shadow top-0 bottom-0 bg-white left-5 z-20 right-0"
                    enter="duration-150"
                    enterFrom="translate-x-full pointer-events-none"
                    enterTo="translate-x-0 pointer-events-auto"
                    leave=" duration-150"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full pointer-events-none" show={showMobileNav}>
                    <div className="w-full h-full overflow-hidden overflow-y-auto pt-12">
                        <button className="absolute top-0 right-0 w-12 h-12 p-3" onClick={() => { setShowMobileNav(false); setActiveMenuItem(undefined); }}><XMarkIcon className="w-6 h-6" /></button>
                        <div className="flex flex-col h-full justify-between divide-y divide-antoniazzi-900/50">
                            <div className="px-4 grow-1">
                                {sections?.map((s, ssk) => {
                                    return <MobileMenuItem activeMenuItem={activeMenuItem} onSelectMenuItem={(container: ContainerRefType | ContainerType | undefined) => {
                                        setActiveMenuItem(container);
                                    }} onClick={() => {
                                        setShowMobileNav(false);
                                        setActiveMenuItem(undefined);
                                    }} key={ssk} container={s} />

                                })}
                            </div>
                            <div className="px-4 py-5 grow-0">
                                <div className="text-h4 lg:text-md-h4 font-bold">{strings.get("Account")}</div>
                                <ul className="text-p lg:text-md-p pl-4 py-4 space-y-3">
                                    {!user?.confermato ? <li><a href="#login" onClick={() => {
                                        setShowMobileNav(false);
                                    }}>{strings.get("Accedi/Registrati")}</a></li> : <></>}
                                    {user?.confermato && !user.ospite ? <li><Link onClick={() => { setShowMobileNav(false); }} to="/profilo">{strings.get("Profilo")}</Link></li> : <></>}
                                    <li><a href="#cart" onClick={() => { setShowMobileNav(false); }}>{strings.get("Carrello")}</a></li>
                                    {user?.confermato ? <li><button onClick={() => {
                                        const al: AlertType = {
                                            active: true,
                                            confirm: true,
                                            onConfirm: () => { terminateSession(); showAlert({ active: false }); window.location.hash = ""; },
                                            severity: "warning",
                                            title: strings.get("Logout"),
                                            message: strings.get("Vuoi davvero uscire?")
                                        };
                                        showAlert(al);
                                    }}>{strings.get("Esci")}</button></li> : <></>}
                                </ul>

                            </div>
                            <div className="px-4 py-5 grow-0">
                                <div className="text-h4 lg:text-md-h4 font-bold">{strings.get("Lingua")}</div>
                                <button onClick={() => { setShowLanguageMenu(!showLanguageMenu) }} className="my-2 flex w-full justify-between items-center">
                                    <div className="flex items-center">
                                        {ita}
                                        <div>{strings.get("Italiano")}</div>
                                    </div>
                                    <Icon name="chevron" className={classNames(!showLanguageMenu ? "rotate-90" : "-rotate-90", "w-6 h-6")}></Icon>
                                </button>
                                {/*showLanguageMenu ? <>
                                    <button className="my-2 flex w-full justify-between items-center">
                                        <div className="flex items-center">
                                            {uk}
                                            <div>{strings.get("English")}</div>
                                        </div>
                                    </button>
                                </> : <></>*/}

                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
        <Outlet />
        <div className="bg-antoniazzi-900 text-white">

            <div className="lg:mt-11">
                <div className="lg:flex gap-x-16 lg:pb-11  divide-y divide-white/60 lg:divide-y-0 lg:px-11">
                    {footerSections?.map((ff, ffk) => {
                        return <FooterMenu container={ff} key={ffk} />
                    })}
                </div>
            </div>

            <div className=" border-b-white/60 border-b"></div>

            <div className="lg:grid lg:grid-cols-2 lg:pb-20">
                <div className="px-4 text-sm py-8 lg:py-0 lg:pt-8 lg:pr-0 lg:pl-11 border-b border-b-white/60 lg:border-b-0">
                    Copyright © {year} Antoniazzi s.r.l. <br />
                    P.IVA.01513660207 | Capitale Sociale 51.000,00€
                </div>
                <div className="px-4 text-sm py-8 lg:py-0 lg:text-right lg:pt-8 lg:pr-11 lg:pl-0">

                    designed by:  <a href="https://calamitastudio.com/" className="hover:underline" target="_blank" referrerPolicy="origin-when-cross-origin">calamitastudio.com</a><br />
                    powered by: <a href="https://xplants.it/xtro" className="hover:underline" target="_blank" referrerPolicy="origin-when-cross-origin"> xtro</a><br />

                </div>
            </div>
        </div>
    </>);
}
function MobileMenuItem(props: { container: ContainerRefType, activeMenuItem?: ContainerRefType, onSelectMenuItem: (container: ContainerRefType | ContainerType | undefined) => void, onClick: () => void }) {
    const { dataBackend } = useFEContext();
    const ref: ContainerRefType = props.container;
    const [container, setContainer] = useState<ContainerType>();
    useEffect(() => {
        if (ref) {
            dataBackend.getContainer(ref.id).then(c => setContainer(c));
        }
    }, [ref]);
    const submenus = container ? submenuItems(dataBackend, container) || [] : [];
    const hasSubmenu = submenus.length > 0;
    const expand = container && props.activeMenuItem ? props.activeMenuItem.id === container.id : false;

    return <>

        <NavLink onClick={(ev) => {
            if (hasSubmenu) {
                ev.preventDefault();
                if (props.activeMenuItem && container && props.activeMenuItem.id === container.id) {
                    props.onSelectMenuItem(undefined);

                } else {
                    props.onSelectMenuItem(container);
                }
                return false;
            } else {
                props.onSelectMenuItem(undefined);
                props.onClick();
            }
        }} className="flex items-center text-xl mb-4" to={ref.routeTo || "#"}>{ref.title} {hasSubmenu ? <>{!expand ? <Icon name="chevron" className="ml-auto w-6 h-6" /> : <Icon name="chevron" className="ml-auto rotate-90 w-6 h-6" />}</> : <></>}</NavLink>
        {expand && container ? <MobileSubmenu onClick={props.onClick} container={container} /> : <></>}

    </>
}
function MobileSubmenu(props: any) {
    const container: ContainerType = props.container;
    const [sections, setSections] = useState<ContainerRefType[]>();
    const { dataBackend } = useFEContext();

    useEffect(() => {
        setSections(submenuItems(dataBackend, container));
    }, [container]);
    return <div className="mb-4 space-y-3">
        {sections && sections.length > 0 ? <>
            {sections.map((cc, cck) => {
                return <NavLink key={cck} onClick={props.onClick} className="block pl-4" to={cc.routeTo || "#"}>{cc.title}</NavLink>
            })}
        </> : <></>}
    </div>
}
function MenuItem(props: any) {
    const { dataBackend, showMainMenuOverlay } = useFEContext();
    const ref: ContainerRefType = props.container;
    const [container, setContainer] = useState<ContainerType>();
    useEffect(() => {
        dataBackend.getContainer(ref.id).then(c => setContainer(c));
    }, [ref]);
    const _submenuItems = container ? submenuItems(dataBackend, container) || [] : [];
    const hasSubmenu = false;// _submenuItems.length > 0;
    const expand = container && props.activeMenuItem ? props.activeMenuItem.id === container.id : false;
    const onClick = () => {
        props.onSelectMenuItem(undefined);
        showMainMenuOverlay(false);
    }
    return <>

        <NavLink onClick={(ev) => {
            if (hasSubmenu) {
                ev.preventDefault();
                if (expand) {
                    props.onSelectMenuItem(undefined);
                    showMainMenuOverlay(false);
                }
                else {
                    props.onSelectMenuItem(container);
                    showMainMenuOverlay(true);
                }

                return false;
            } else {
                props.onSelectMenuItem(undefined);
            }
        }} className="block" to={ref.routeTo || "#"}>{ref.title}</NavLink>
        {hasSubmenu ? <Transition
            className="absolute top-14 bg-white left-0 z-20 right-0 duration-0"
            enterFrom="opacity-0 pointer-events-none"
            enterTo="opacity-100 pointer-events-auto"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 pointer-events-none"
            show={expand}><div className="grid grid-cols-4 text-xs max-w-4xl mx-auto gap-4 py-16"><Submenu onClick={onClick} container={container} /></div> </Transition> : <></>}

    </>
}

function submenuItems(dataBackend: DataBackend, container: ContainerType) {
    if (container) {
        if (container.name !== 'pasticceria') {
            const sects = container.containers ? container.containers.filter(c => c.position === 'Center' && c.containerClass === "SECTION") : [];
            return sects;
        } else {
            return dataBackend.categorie;
        }
    }
    return [];
}

function Submenu(props: any) {
    const container: ContainerType = props.container;
    const { dataBackend } = useFEContext();
    const [sections, setSections] = useState<ContainerRefType[]>();

    useEffect(() => {
        setSections(submenuItems(dataBackend, container));
    }, [container]);
    return <>
        {sections && sections.length > 0 ? <>
            {sections.map((cc, cck) => {
                return <NavLink key={cck} onClick={props.onClick} className="block" to={cc.routeTo || "#"}>{cc.title}</NavLink>
            })}
        </> : <></>}
    </>
}

function FooterSubmenu(props: any) {
    const ref: ContainerRefType = props.container;
    //const [container, setContainer] = useState<ContainerType>();

    const { dataBackend } = useFEContext();
    const [sections, setSections] = useState<ContainerRefType[]>();

    useEffect(() => {
        dataBackend.get(ref).then((d) => {
            if (!d.error) {
                const c = d.records[0];
                //setContainer(c);
                setSections(submenuItems(dataBackend, c));
            }
        })

    }, [ref]);
    return <>
        {sections && sections.length > 0 ? <>
            {sections.map((cc, cck) => {
                return <FooterSubmenuItem key={cck} cc={cc} />
            })}
        </> : <></>}
    </>
}

function FooterSubmenuItem(props: { cc: ContainerRefType }) {
    const cc = props.cc;
    return <>{!cc.externalUrl ? <NavLink className="block" to={cc.routeTo || "#"}>{cc.title}</NavLink> : <a className="block" href={cc.externalUrl}>{cc.title}</a>}</>
}

export function PowerlayerGallery(props: { images: ContainerRefType[], initial: number, visible?: boolean, onClose?: Function }) {
    const [sliderRef, instanceRef] = useKeenSlider({
        loop: false,
        initial: props.initial,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created: () => {
            setLoaded(true);
        }
    })
    const [currentSlide, setCurrentSlide] = useState<number>(props.initial);
    const [loaded, setLoaded] = useState<boolean>(false);
    const images = props.images;




    const onClose = () => { if (props.onClose) props.onClose() };

    return (<>
        <Transition
            className="fixed top-20 left-1/2 -translate-x-1/2 z-50"
            show={props.visible}
            enter="transition-opacity delay-75 duration-150"
            enterFrom="opacity-0 pointer-events-none"
            enterTo="opacity-100 pointer-events-auto"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 pointer-events-none"
        >
            <div className="w-[100vw]">
                <div className="mx-4 xl:max-w-5xl xl:mx-auto">
                    {images ? <div ref={sliderRef} className="keen-slider">
                        {images.map((cim, ckim) => {
                            return <div key={ckim} className="keen-slider__slide"><Multimedia loading="eager" className="block mx-auto lg:max-h-[75vh]" large containerRef={cim} /></div>;
                        })}
                    </div> : <></>}</div>
                {loaded && (<div className="mx-auto text-center text-white mt-8">
                    <button className="pr-2 cursor-pointer" onClick={(e) => {
                        if (currentSlide > 0)
                            setCurrentSlide(currentSlide - 1);
                        e.stopPropagation();
                        instanceRef.current?.prev()
                    }}
                        disabled={currentSlide === 0}
                    ><Icon name="chevron" className="w-6 h-6 rotate-180" /> </button>
                    <button className="pl-2 cursor-pointer" onClick={(e) => {
                        setCurrentSlide(currentSlide + 1);
                        e.stopPropagation();
                        instanceRef.current?.next()
                    }
                    } disabled={instanceRef.current && currentSlide === instanceRef.current.track.details.slides.length - 1}> <Icon name="chevron" className="w-6 h-6" /></button>
                </div>)
                }
            </div>
        </Transition>
        <Transition className="fixed  top-0 right-0 left-0 bottom-0 overflow-hidden z-40 bg-antoniazzi-900/70 backdrop-blur-lg"
            show={props.visible}
            enter="transition-all duration-75"
            enterFrom="opacity-0 pointer-events-none"
            enterTo="opacity-100 pointer-events-auto"
            leave="transition-scale delay-150 duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 pointer-events-none" onClick={onClose}>
            <div className="xl:max-w-5xl mx-auto"><button className="w-6 h-6 mt-10 block mx-auto xl:ml-auto xl:mr-2 text-white/80" onClick={onClose}><XMarkIcon className="w-full h-full" /></button></div>
        </Transition>
    </>
    );

}

function FooterMenu(props: { container: ContainerType }) {
    const ff = props.container;
    const [opened, setOpened] = useState<boolean>(false);
    return <div className="grow px-4 lg:px-0 py-6 lg:py-0">
        <button onClick={() => setOpened(!opened)} className={classNames("flex font-bold w-full justify-between items-center", opened ? "mb-4" : "")}>{ff.title} <Icon name="chevron" className={classNames(opened ? " rotate-90" : "", "w-6 h-6")} /></button>
        {opened ? <div className="text-sm flex flex-col pl-4 gap-y-2 lg:pl-0 lg:gap-y-4"><FooterSubmenu container={ff} /></div> : <></>}
    </div>
}
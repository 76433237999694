import React from "react";
import { Container, ContainerRef, PowerLayerType } from "../../Interfaces";
import Multimedia from "../Subcomponents/Multimedia";
import { useFEContext } from "../FEContext";
import { PowerlayerGallery } from "../Wrapper";

export default function MosaicRow(props: { container: Container }) {
    const variant = props.container.config.variant || "a";
    let images: ContainerRef[] = [];
    if (props.container) {
        images = props.container.containers?.filter(cc => cc.containerClass === "MULTIMEDIA_CONTENT" && cc.position === 'Center') || [];
    }


    const { setInitialGallerySlide, setShowGallery, galleryImages } = useFEContext();

    const onClick = (img: ContainerRef) => {
        if (galleryImages) {
            let initialSlide = 0;
            for (let index = 0; index < galleryImages.length; index++) {
                const element = galleryImages[index];
                if (element.id === img.id) {
                    initialSlide = index;
                    break;
                }

            }
            setInitialGallerySlide(initialSlide)
            setShowGallery(true);
        }
    }

    return <div className="mosaic-row max-w-5xl mx-auto">
        {"a" === variant ?
            <div className="md:grid md:gap-2 md:grid-cols-2">
                {images.length > 0 ? <button className="aspect-w-1 aspect-h-1 w-full mb-2 md:mb-0 block" onClick={() => { onClick(images[0]) }}><Multimedia className="object-cover object-center" containerRef={images[0]} /></button> : <></>}
                <div className="grid gap-2 grid-cols-2">
                    {images.length > 1 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[1]) }}><Multimedia className="object-cover object-center" containerRef={images[1]} /></button> : <></>}
                    {images.length > 2 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[2]) }}><Multimedia className="object-cover object-center" containerRef={images[2]} /></button> : <></>}
                    {images.length > 3 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[3]) }}><Multimedia className="object-cover object-center" containerRef={images[3]} /></button> : <></>}
                    {images.length > 4 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[4]) }}><Multimedia className="object-cover object-center" containerRef={images[4]} /></button> : <></>}
                </div>
            </div>
            : <></>}
        {"b" === variant ?
            <div className="md:grid md:gap-2 md:grid-cols-2">
                {images.length > 0 ? <button className="aspect-w-2 aspect-h-1 w-full mb-2 md:mb-0 block" onClick={() => { onClick(images[0]) }}><Multimedia className="object-cover object-center" containerRef={images[0]} /></button> : <></>}
                {images.length > 1 ? <button className="aspect-w-2 aspect-h-1 w-full block" onClick={() => { onClick(images[1]) }}><Multimedia className="object-cover object-center" containerRef={images[1]} /></button> : <></>}
            </div>
            : <></>}
        {"c" === variant ?
            <div className="grid gap-2 grid-cols-2 md:grid-cols-4">
                {images.length > 0 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[0]) }}><Multimedia className="object-cover object-center" containerRef={images[0]} /></button> : <></>}
                {images.length > 1 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[1]) }}><Multimedia className="object-cover object-center" containerRef={images[1]} /></button> : <></>}
                {images.length > 2 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[2]) }}><Multimedia className="object-cover object-center" containerRef={images[2]} /></button> : <></>}
                {images.length > 3 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[3]) }}><Multimedia className="object-cover object-center" containerRef={images[3]} /></button> : <></>}
            </div>
            : <></>}
        {"d" === variant ?
            <div className="md:grid md:gap-2 md:grid-cols-2">
                {images.length > 0 ? <button className="aspect-w-2 aspect-h-1 w-full mb-2 md:mb-0 block" onClick={() => { onClick(images[0]) }}><Multimedia className="object-cover object-center" containerRef={images[0]} /></button> : <></>}

                <div className="grid gap-2 grid-cols-2">
                    {images.length > 1 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[1]) }}><Multimedia className="object-cover object-center" containerRef={images[1]} /></button> : <></>}
                    {images.length > 2 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[2]) }}><Multimedia className="object-cover object-center" containerRef={images[2]} /></button> : <></>}
                </div>
            </div>
            : <></>}

        {"e" === variant ?
            <div className="md:grid md:gap-2 md:grid-cols-2">
                <div className="grid gap-2 grid-cols-2 mb-2 md:mb-0">
                    {images.length > 0 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[0]) }}><Multimedia className="object-cover object-center" containerRef={images[0]} /></button> : <></>}
                    {images.length > 1 ? <button className="aspect-w-1 aspect-h-1 w-full block" onClick={() => { onClick(images[1]) }}><Multimedia className="object-cover object-center" containerRef={images[1]} /></button> : <></>}
                </div>
                {images.length > 2 ? <button className="aspect-w-2 aspect-h-1 w-full block" onClick={() => { onClick(images[2]) }}><Multimedia className="object-cover object-center" containerRef={images[2]} /></button> : <></>}
            </div>
            : <></>}
    </div>;
}
import React, { useEffect, useRef, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Pagination } from "swiper/modules";


export default function MainSlider(props: any) {
    const swiperElRef = useRef(null);
    const container: ContainerType = props.container;
    const articles: ContainerRefType[] = container && container.containers ? container.containers.filter(cc => cc.containerClass == "CONTENT" && cc.position === "Center") : [];
    const images: ContainerRefType[] = container && container.containers ? container.containers.filter(cc => cc.containerClass == "MULTIMEDIA_CONTENT" && cc.position === "Center") : [];
    useEffect(() => {
        const swiperEl = swiperElRef.current;
        const params = {
            modules: [Pagination],
        };
        //@ts-ignore
        Object.assign(swiperEl, params);
        //@ts-ignore
        swiperEl.initialize();

    }, []);
    //@ts-ignore
    return <section data-container-id={props.container.id} className="main-slider max-w-container"><swiper-container init="false" ref={swiperElRef} pagination="true" class="main-slider-s-c">{images.map((aa, aak) => { return <swiper-slide key={aak}><MainSliderMultimedia containerRef={aa} /></swiper-slide> })} {articles.map((aa, aak) => { return <swiper-slide key={aak}><MainSliderContent containerRef={aa} /></swiper-slide> })} </swiper-container></section>;
}
function MainSliderMultimedia(props: any) {
    const ref: ContainerRefType = props.containerRef;
    const { dataBackend } = useFEContext();
    const [imageDesktop, setImageDesktop] = useState<ContainerType>();
    const [image, setImage] = useState<ContainerType>();
    useEffect(() => {
        setImage(undefined);
        setImageDesktop(undefined);
        dataBackend.get(ref).then((d) => {
            if (!d.error) {
                const cont: ContainerType = d.records[0];
                setImage(cont);
                if (cont.containers) {
                    dataBackend.getAll(cont.containers).then((sd) => {
                        if (!sd.error) {
                            const records: ContainerType[] = sd.records;
                            const images = records.filter(x => x.containerClass === "MULTIMEDIA_CONTENT" && x.position === "Center");
                            if (images.length > 0) {
                                setImageDesktop(images[0]);
                            }
                        }
                    })
                }
            }
        });
    }, [ref])
    return <>
        {!image ? <picture className="block aspect-w-2 aspect-h-3 md:aspect-w-13 md:aspect-h-5 bg-antoniazzi-900/20 animate-pulse"></picture> : <></>}
        {image && !image.videoSrcs ? <picture className="block aspect-w-2 aspect-h-3 md:aspect-w-13 md:aspect-h-5">
            {imageDesktop ? <img src={imageDesktop.imageSrcs?.large} className="hidden lg:block object-cover object-center" /> : <></>}
            {image ? <img src={image.imageSrcs?.large} alt={image?.title} className={classNames("object-cover object-center", imageDesktop ? "lg:hidden" : "")} /> : <></>}
        </picture> : <></>}
        {image && image.videoSrcs ? <div className="block aspect-w-2 aspect-h-3 md:aspect-w-13 md:aspect-h-5">
            {imageDesktop ? <video autoPlay loop playsInline muted className="hidden lg:block object-cover object-center"><source src={imageDesktop.videoSrcs?.mp4} type="video/mp4"></source></video> : <></>}
            {image ? <video autoPlay loop playsInline muted className={classNames(imageDesktop ? "lg:hidden" : "", "object-cover object-center")}><source src={image.videoSrcs?.mp4} type="video/mp4"></source></video> : <></>}
        </div> : <></>}
    </>;
}
function MainSliderContent(props: any) {
    const ref: ContainerRefType = props.containerRef;
    const { dataBackend } = useFEContext();
    const [container, setContainer] = useState<ContainerType>();
    const [imageDesktop, setImageDesktop] = useState<ContainerType>();
    const [image, setImage] = useState<ContainerType>();
    useEffect(() => {
        setImage(undefined);
        setImageDesktop(undefined);
        setContainer(undefined);
        dataBackend.get(ref).then((d) => {
            if (!d.error) {
                const cont: ContainerType = d.records[0];
                setContainer(cont);
                if (cont.containers) {
                    dataBackend.getAll(cont.containers).then((sd) => {
                        if (!sd.error) {
                            const records: ContainerType[] = sd.records;
                            const images = records.filter(x => x.containerClass === "MULTIMEDIA_CONTENT" && x.position === "Center");
                            if (images.length > 0) {
                                setImage(images[0]);
                                const img = images[0];
                                const desktopImages = img.containers?.filter(x => x.containerClass === "MULTIMEDIA_CONTENT");
                                if (desktopImages && desktopImages.length > 0) {
                                    setImageDesktop(desktopImages[0]);
                                }
                            }
                        }
                    })
                }
            }
        });
    }, [ref])
    return <>
        {container ? <picture className="block aspect-w-2 aspect-h-3 md:aspect-w-13 md:aspect-h-5">
            {imageDesktop ? <source srcSet={imageDesktop.imageSrcs?.large} media="(min-width: 768px)" /> : <></>}
            {image ? <img src={image.imageSrcs?.large} alt={container?.title} className="block object-cover object-center" /> : <></>}
        </picture> : <></>}
    </>;
}
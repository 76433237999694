import React, { useEffect, useState } from "react";
import { MenuAccount } from "./ViewProfilo";
import { OrderType, PowerLayerType, formatDateTime, formatPrice } from "../Interfaces";
import { useFEContext } from "./FEContext";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../IconLib";
import LoginRegistrationPanel from "./Subcomponents/LoginRegistrationPanel";

export default function ViewOrdini() {
    const { dataBackend, user, strings } = useFEContext();
    const [elencoOrdini, setElencoOrdini] = useState<OrderType[]>([]);
    const nav = useNavigate();
    useEffect(() => {
        if (user) {
            dataBackend.list({ entity: "Order" }).then((resp) => {
                if (!resp.error) {
                    setElencoOrdini(resp.records);
                }
            });
        }
    }, [user])
    return <div className="bg-white text-antoniazzi-900 min-h-[65vh]">
        {user?.confermato ? <>
            <MenuAccount />
            <div className="px-4 lg:px-0 max-w-5xl mx-auto py-6">
                <div className="text-h1 md:text-md-h1 mb-4">{strings.get("Ciao") + " " + (user?.ospite ? strings.get("ospite") : user?.nome)}</div>
                <h3 className="text-h3 md:text-md-h3 pb-4 border-b border-antoniazzi-900">{strings.get("Ordini")}</h3>
                {!elencoOrdini || elencoOrdini.length === 0 ? <p className="text-p md:text-md-p py-6">{strings.get("Non hai ancora effettuato ordini...")}</p> : <></>}

                {elencoOrdini.map((o, k) => {
                    return <Link to={"/ordini/" + o.identificativo} key={k} className="grid grid-cols-2 lg:grid-cols-4 py-6 relative gap-x-16 gap-y-6 text-p md:text-md-p border-b border-antoniazzi-900">

                        <OrderHead order={o} />

                        <Icon name="chevron" className="absolute top-1/2 right-0 -translate-y-1/2 w-6 h-6" />


                    </Link>
                })}</div>
        </> : <>
            <AvvisoAreaRiservata />
        </>}

    </div>
}

export function AvvisoAreaRiservata() {
    const { strings, showPowerLayer } = useFEContext();
    return <div className="px-4 lg:px-0 max-w-5xl mx-auto py-6">
        <h3 className="text-h1 md:text-md-h1 mb-4">{strings.get("Attenzione")}</h3>
        <div className="text-p md:text-md-p">
            <p>{strings.get("Per accedere a questa sezione devi aver effettuato l'accesso")} <br /><a href="#login" className="underline hover:no-underline">{strings.get("Clicca qui per accedere")}</a></p></div>
    </div>;
}

export function OrderHead(props: { order: OrderType }) {
    const { strings } = useFEContext();
    const o = props.order;
    return <>
        <div>
            <div className="font-bold">{strings.get("Ordine")}</div>
            {o.id} {formatDateTime(o.date)}</div>
        <div>
            <div className="font-bold">{strings.get("Stato dell’ordine") + ":"}</div>
            {o.statoLabel}</div>
        <div>
            <div className="font-bold">{strings.get("Totale")}</div>
            {formatPrice(o.total)}</div>
        <div>
            <div className="font-bold">{strings.get("Metodo di pagamento")}</div>
            {o.pagamento}
            {o.statoPagamentoLabel?" - " + o.statoPagamentoLabel:""}</div>
    </>;
}
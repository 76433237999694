// Entità principale della pasticceria

import { ReactElement } from "react";
import { DataBackend } from "./DataBackend"
import dayjs from "dayjs";

const eurForm = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' })
export function formatPrice(snum?: any) {
    console.log("formatPrice",snum);
    if (snum) {
        const num = parseFloat(snum);
        console.log("num",num);
        const re = eurForm.format(num);
        console.log("re",re);
        return re;
    }
    else return "";
}

const twoDescsForm = new Intl.NumberFormat('it-IT', { maximumFractionDigits: 2 })
export function formatAmount(snum?: any) {
    if (snum) {
        const num = parseFloat(snum);
        return twoDescsForm.format(num);
    }
    else return "";
}

export function formatDate(snum?: any) {
    if (snum) {
        const num = dayjs(snum);
        return num.format("DD/MM/YYYY")
    }
    else return "";
}
export function formatDateTime(snum?: any) {
    if (snum) {
        const num = dayjs(snum);
        return num.format("DD/MM/YYYY HH:mm")
    }
    else return "";
}

export function productPriceDisplay(prod?: ProductRefType | ProductType): number | undefined {
    const pd = prod && prod.priceDisplay ? parseFloat(prod.priceDisplay) : -1;
    const ps = prod && prod.priceStandard ? parseFloat(prod.priceStandard) : -1;
    if (pd > ps) return pd;
    return undefined;
}

export function productPriceStandard(prod?: ProductRefType | ProductType): number {
    return prod && prod.priceStandard ? parseFloat(prod.priceStandard) : 0;
}

export interface BEContextType {
    showAlert: (a: AlertType) => void,
    showToast: (a: AlertType) => void,
    dataBackend: DataBackend,
    showLoadingOverlay: Function
}

export interface FEContextType {
    cart?: CartType
    setCart: (u: CartType) => Promise<CartType>
    user?: UserType
    setUser: (u: UserType) => void
    updateSession: (u: SessionType) => void
    refreshSession: () => void
    terminateSession: () => void
    strings: LocalizerType
    container: ContainerType | undefined,
    setGalleryImages: Function,
    galleryImages: ContainerRefType[] | undefined,
    site: ContainerType | undefined
    showAlert: (pl: AlertType) => void,
    showCart: () => void,
    hideCart: () => void,
    showPowerLayer: (pl: PowerLayerType) => void,
    setShowGallery: Function,
    setInitialGallerySlide: Function,
    showToast: Function,
    dataBackend: DataBackend,
    showLoadingOverlay: Function
    showMainMenuOverlay: Function
}

export interface LocalizerType {
    get: (a: string) => string
}

export interface Entity {
    id?: number | string,
    entity?: "Order" | "Product" | "Address" | "Container" | "Image" | "Category" | "Accessory" | "DeliveryMethod" | "PaymentMethod" | "XTDiscountCoupon",
    ref?: boolean
}

export interface ContainerRefType extends Entity {
    routeTo?: string,
    address?: AddressType
    highlight?: boolean,
    className?: string
    externalUrl?: string,
    title?: string,
    parentContainerTitle?: string
    idParentContainer?: number | string,
    published?: boolean,
    datePublished?: string,
    dateExpiration?: string,
    order?: number,
    containerClass?: "BLOCK" | "CONTENT" | "MULTIMEDIA_CONTENT" | "PRODUCT" | "RESELLER" | "SECTION" | "CATEGORY"
    parentContainerClass?: "BLOCK" | "CONTENT" | "MULTIMEDIA_CONTENT" | "PRODUCT" | "RESELLER" | "SECTION" | "CATEGORY"
    position?: string
    directAction?: string
    name?: string,
    config?: any
    imageSrcs?: {
        large?: string
        medium?: string
        small?: string
    }
    videoSrcs?: {
        mp4?: string
        poster?: string
    }
}
export interface ProductRefType extends ContainerRefType {
    quantityInStock?: string;
    priceStandard?: string
    priceDisplay?: string
    discount?: string
    noQuantityLimit?: boolean;
    productorCode?: string;
    toRelate?: boolean;
    promo?: boolean;
    freeShipping?: boolean;
    sell?: boolean;
    featured?: boolean;
    highlight?: boolean;
}
export interface ImageType extends ContainerRefType {
    src?: string,
    imageSrcs?: {
        large?: string
        medium?: string
        small?: string
    }
}
export interface AccessoryType extends Entity {
    product?: ProductRefType,
    accessory?: ProductType,
    quantitySuggested?: number,
    customConfig?: any
}
export interface AccessoryConfigType {
    allowCustomQuantity: boolean
    quantitySuggested: number
    allowCustomStandardPrice: boolean
    customStandardPrice: number
    forAllSizes: boolean
    sizes: ProductRefType[]
}
export interface SelectedAccessoryType {
    idProduct?: any
    accessory?: AccessoryType,
    description?: string,
    quantity?: number
    age?: number
    price?: number,
    customText?: string
}

export interface PersonalizzazioneType {
    ricorrenza?: string
    picture?: File,
    pictureId?: string,
    accessories: SelectedAccessoryType[],
    total?: number
}

export interface ContainerType extends ContainerRefType {
    subtitle?: string,
    embed?: boolean
    showTitle?: boolean
    showContentIntro?: boolean
    container?: ContainerRefType,
    introduction?: string,
    description?: string,
    containers?: ContainerRefType[],
    behaviour?: string
    path?: ContainerRefType[]
}

export interface ProductType extends ContainerType {
    productorCode?: string;
    newProduct?: boolean;
    toRelate?: boolean;
    promo?: boolean;
    freeShipping?: boolean;
    sell?: boolean;
    featured?: boolean;
    highlight?: boolean;
    categories?: ContainerRefType[];
    contents?: ContainerRefType[];
    relatedProducts?: ProductRefType[];
    accessories?: AccessoryType[];
    deliveryMethods?: DeliveryMethodType[];
    sizes?: ProductRefType[];
    images?: ImageType[];
    noQuantityLimit?: boolean;
    quantityInStock?: any
    priceStandard?: any
    discount?: any
    priceDisplay?: any
}
export interface DeliveryMethodType extends Entity {
    title?: string
    notes?: string
    valid?: boolean
    enabled?: boolean
    config?: any
}
export interface PayloadRequestType {
    entity?: "Order" | "Product" | "Address" | "Container" | "Image" | "Category" | "Accessory" | "DeliveryMethod" | "PaymentMethod"
    searchParams?: any
}

export interface AlertType {
    active: boolean,
    confirm?: boolean
    severity?: "warning" | "success" | "error",
    message?: string,
    title?: string,
    onClick?: any,
    onConfirm?: any,
    onCancel?: any
}

export interface PowerLayerType {
    active: boolean,
    onClose?: () => void,
    content?: ReactElement,
}

export interface CartItemType {
    deliveryMethods?: DeliveryMethodType[]
    config?: any
    customization?: boolean
    idProduct?: number | string
    quantity: number,
    priceStandard?: any
    priceDisplay?: any
    description?: string
    code?: string
    subitems?: CartItemType[]
}
export interface DiscountCouponType {
    id: number
    code?: string
    valid?: boolean
    available?: boolean
    value?: number
}
export interface CartType {
    rows?: CartItemType[]
    consegna?: {
        idPos?: number | string,
        giornoConsegna?: string,
        fasciaOraria?: string
    }
    regalo?: boolean
    indirizzoDestinazione?: AddressType,
    deliveryMethod?: DeliveryMethodType,
    discountCoupon?: DiscountCouponType,
    totalProducts?: string
    totalDelivery?: string,
    readyForCheckout?: boolean
}
export interface AddressType {
    presso?: string
    nominativo?: string
    paese?: string,
    indirizzo?: string
    cap?: string
    citta?: string
    provincia?: string
}
export interface UserType {
    nome?: string
    ospite?: boolean,
    confermato?: boolean
    codiceFiscale?: string,
    partitaIva?: string,
    codiceDestinatario?: string,
    pec?: string,
    cognome?: string
    telefono?: string
    email?: string
    azienda?: boolean,
    ragioneSociale?: string,
    altriIndirizzi: AddressType[]
    indirizzoPrincipale: AddressType
    spedizioneDiversa?: boolean
    privacy?: boolean
    newsletter?: boolean

}
export interface FasciaOrariaType {
    orarioApertura?: string
    orarioChiusura?: string
}
export interface DataParticolareType {
    chiusura: boolean,
    mese?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    giornoSettimana?: number
    giornoDelMese?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31
    anno?: number
    orari?: FasciaOrariaType[]
}

export interface SessionType {
    uuid: string,
    dataScadenza?: string
    user: UserType
    cart: CartType,
    error?: boolean,
    message?: string
}

export interface OrderType {
    id: number
    extras?: any
    transactionId?: string
    date?: string
    dateDelivery?: string
    customer?: UserType
    address?: AddressType
    codiceFiscale?: string
    partitaIva?: string
    ragioneSociale?: string
    paese?: string
    email?: string
    nome?: string
    note?: string
    identificativo?: string
    telefono?: string
    provincia?: string
    indirizzo?: string
    pagamento?: string
    consegna?: string
    stato?: string
    statoPagamento?: string
    statoLabel?: string
    statoPagamentoLabel?: string
    cap?: string
    cognome?: string
    citta?: string
    totalDelivery?: number
    total?: number
    lines?: OrderRowType[]
    /*
        json.put("telefono", record.thePhone());
        json.put("provincia", record.theState());
        json.put("indirizzo", record.theStreet());
        json.put("cap", record.theZipCode());
        json.put("cognome", record.theSurname());
        json.put("citta", record.theTown());
        json.put("totalDelivery", record.theDeliveryExpenses());
        json.put("total", record.theTotalWithTaxes());
        */
}

export interface OrderRowType {
    id: number
    productorCode?: string,
    idContainer?: number
    idProduct?: number
    description?: string
    quantity?: number
    price?: number
    total?: number
    childrenLines?: OrderRowType[]
    parentLine?: OrderRowType,
    options?: any
}

export interface XTDiscountCouponType extends Entity {
    flagDeleted?: number
    flagDiscountKind?: 0 | 10
    flagFreeDelivery?: boolean
    theMasterCoupon?: XTDiscountCouponType
    idXTDiscountCoupon?: number
    theChildrenCoupons?: XTDiscountCouponType[]
    theDescription?: string,
    theDiscountCode?: string,
    theDiscountValue?: number
    theMinPrice?: number
    theUsageCount?: number
    theUsageLimit?: number
    theValidPeriodEnd?: string,
    theValidPeriodStart?: string,
    dateLastUsage?: string,
    idOwner?: number
    dateCreation?: string,
    idXTUser?: number
}
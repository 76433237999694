import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../../IconLib";
import { Transition } from "@headlessui/react";

export default function Articolo(props: { container: ContainerType }) {

    const [expanded, setExpanded] = useState<boolean>(false);

    return <>
        <section className="py-5 border-b border-antoniazzi-900" data-container-id={props.container.id}>
            <button onClick={(ev) => { setExpanded(!expanded) }} className="w-full block text-left relative">
                {props.container.showTitle ? <div >
                    {props.container.title ? <h3 className="text-h4 font-bold lg:text-md-h4">{props.container.title}</h3> : <></>}
                    {props.container.subtitle ? <h4 className="text-h5 lg:text-md-h5">{props.container.subtitle}</h4> : <></>}
                </div> : <></>}
                <Icon name="chevron" className={classNames("absolute top-1/2 -translate-y-1/2 right-0 w-5 h-5 transition-transform", expanded ? "rotate-90" : "")} />
            </button>
            <Transition show={expanded} className="mt-4">
                {props.container.showContentIntro && props.container.introduction ?
                    <div className={classNames("introduction", "text-h3 lg:text-md-h4")} dangerouslySetInnerHTML={{ __html: props.container.introduction || "" }} /> : <></>}
                {props.container.description ?
                    <div className={classNames("description", "text-p lg:text-md-p")} dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}
            </Transition>

        </section>
    </>;
}


export function AccordionsArticles(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const secondary = props.container.config.secondary || false;
    const dark = props.container.config.dark || false;
    const articlesReact =
        articles.map((ba, bak) => {
            return <Articolo key={bak} container={ba} dark={dark} secondary={secondary} />
        })

    return (
        <section className="px-4 pt-8 xl:px-0 max-w-5xl lg:mx-auto pb-12 text-antoniazzi-900">
            <h3 className="mb-7 text-h1 lg:text-md-h2">{props.container.title}</h3>
            {articlesReact}
        </section>
    );
}
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFEContext } from "../FEContext";

export default function SearchPanel() {
    const { strings, showPowerLayer } = useFEContext();
    const [q, setQ] = useState<string>("");
    //const [searchParams, setSearchParams] = useSearchParams();
    const nav = useNavigate();
    useEffect(() => {
        setQ(new URLSearchParams(window.location.search).get("search") || "");
    }, [])
    return <>
        <div className="mx-4 pt-8 pb-20 text-antoniazzi-900">
            <h3 className="text-h2 md:text-md-h2 mb-10">{strings.get("Cerca")}</h3>
            <form action="/shop/ricerca">
                <input required className="placeholder:text-antoniazzi-900 px-0 focus:outline-antoniazzi-600 block mb-5 shrink grow border-0 border-b w-full" name="search" type="text" placeholder={strings.get("Cosa stai cercando?")} value={q} onChange={(ev) => { setQ(ev.target.value) }} />


                <button className="btn border block w-full border-antoniazzi-900 hover:text-white hover:bg-antoniazzi-900 grow-0 shrink-0" onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (q) {
                        const searchParams = new URLSearchParams();
                        searchParams.set("search", q);
                        nav("/shop/ricerca?" + searchParams.toString());
                        showPowerLayer({ active: false });
                    }
                    /*setSearchParams(searchParams);*/
                }}>{strings.get("Inizia")}</button>
            </form>
        </div>
    </>;
}
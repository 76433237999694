import React, { MouseEventHandler, PropsWithChildren, ReactComponentElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useFEContext } from "../FEContext";
import { ContainerType, ContainerRefType, ImageType } from "../../Interfaces";
import { Icon } from "../../IconLib";
export interface CTAType {
    linkTo?: string,
    href?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    label?: string,
    target?: string,
    title?: string
    position?: "absolute" | "inline"
    visibile: boolean
}
export interface MultimediaType {
    src?: string,
    srcDesktop?: string,
    alt?: string,
    className?: string
    wrap?: boolean,
    component?: React.ReactNode,
    videoDesktop?: {
        mp4?: string
    },
    videoMobile?: {
        mp4?: string
    }
}

export interface HighlightPropsType extends PropsWithChildren {
    title?: string,
    subtitle?: string,
    description?: string,
    small?: boolean,
    medium?: boolean,
    cta?: CTAType
    image?: MultimediaType
    inset?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "none"
    className?: string
    lightBackground?: boolean
}

function ctaFromContainer(container: ContainerType): CTAType {
    const cta: CTAType = { label: container.config ? container.config.ctaLabel : "Scopri di più", visibile: true };
    if (container.routeTo) {
        cta.linkTo = container.routeTo;
    } else if (container.externalUrl) {
        cta.href = container.externalUrl;
    }
    if (container.config) {
        cta.position = container.config.ctaPosition;
    }
    if (container.config && container.config.ctaVisible !== undefined) {
        cta.visibile = container.config.ctaVisible;
    }
    return cta;
}

export function HighlightContainer(props: { container: ContainerType }) {
    const container = props.container;
    const { dataBackend } = useFEContext();
    if (!container) return <></>;
    const inset = container.config ? container.config.inset : undefined;
    const [imageMobile, setImageMobile] = useState<ContainerType>();
    const [imageDesktop, setImageDesktop] = useState<ContainerRefType>();
    const [article, setArticle] = useState<ContainerType>();
    const [blocks, setBlocks] = useState<ContainerType[]>();
    useEffect(() => {
        if (container) {
            const images = container.containers?.filter(c => c.containerClass === 'MULTIMEDIA_CONTENT');
            if (images && images?.length > 0) {
                dataBackend.getContainer(images[0].id).then((cont) => {
                    if (cont?.videoSrcs) {
                        //console.log("VIDEO!", cont);
                    }
                    setImageMobile(cont);
                    if (cont?.containers) {
                        const desktopImages = cont.containers.filter(c => c.containerClass === 'MULTIMEDIA_CONTENT');
                        if (desktopImages && desktopImages.length > 0) {
                            setImageDesktop(desktopImages[0]);
                        }
                    }
                }).catch(e => console.error(e));
            }
            const articles = container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (articles && articles?.length > 0) {
                dataBackend.getContainer(articles[0].id).then((cont) => {
                    setArticle(cont);
                }).catch(e => console.error(e));
            }
            const blocks = container.containers?.filter(c => c.containerClass === 'BLOCK' && c.position === 'Center');
            if (blocks && blocks?.length > 0) {
                dataBackend.getAll(blocks).then((cont) => {
                    if (!cont.error) {
                        setBlocks(cont.records);
                    }
                }).catch(e => console.error(e));
            }
        }
    }, [props.container]);
    let image: MultimediaType | undefined = undefined, cta: CTAType | undefined = undefined;
    if (imageMobile) {
        image = { src: imageMobile.imageSrcs?.small, srcDesktop: imageDesktop ? imageDesktop.imageSrcs?.medium : imageMobile.imageSrcs?.medium, alt: imageMobile.title };
        image.wrap = container.config ? container.config.square || false : false;
        if (imageMobile.videoSrcs) {
            image.videoMobile = imageMobile.videoSrcs;
            //console.log("VIDEO!", image);
        }
        if (imageDesktop && imageDesktop.videoSrcs) {
            image.videoDesktop = imageDesktop.videoSrcs;
        }
    }
    if (container && (container.externalUrl || container.routeTo)) {
        cta = ctaFromContainer(container)
    }
    if (blocks && blocks.length > 0) {
        const ctaBlock = blocks[0];
        cta = ctaFromContainer(ctaBlock)
    }

    return <>
        {!container.config.tipo ? <Highlight description={article?.description} small={container.config.small} title={container.title} lightBackground={container.config.lightBackground} subtitle={container.subtitle} inset={inset} cta={cta} image={image} /> : <></>}
        {container.config.tipo === "Realizzazioni" ? <HighlightRealizzazioni title={container.title} lightBackground={container.config.lightBackground} subtitle={container.subtitle} inset={inset} cta={cta} image={image} /> : <></>}
        {container.config.tipo === "Cake" ? <HighlightCake title={container.title} lightBackground={container.config.lightBackground} subtitle={container.subtitle} inset={inset} cta={cta} image={image} /> : <></>}
        {container.config.tipo === "Punto vendita" ? <HighlightPuntoVendita description={article?.description} small={container.config.small} title={container.title} lightBackground={container.config.lightBackground} subtitle={container.subtitle} inset={inset} cta={cta} image={image} /> : <></>}
    </>;

}

function CTAElement(props: { cta?: CTAType, className?: string }) {
    const cta = props.cta;
    if (cta) {
        const ctaLabel = cta && cta.visibile ? cta.label || "Scopri di più" : "";
        const item = cta && cta.visibile ? <div className="text-p lg:text-md-p flex items-center underline hover:no-underline"><span>{ctaLabel}</span> <Icon name="chevron" className="ml-3 w-4 h-4" /></div> : <></>

        return <div className={classNames(cta.visibile ? (!cta.position || cta.position === 'absolute' ? "absolute z-10 bottom-4 right-4" : "") : "absolute left-0 right-0 bottom-0 top-0 z-10", props.className)}>{item}</div>

    }
    return <></>;
}

function CTAButton(props: { cta?: CTAType, className?: string }) {
    const cta = props.cta;
    if (cta) {
        if (cta.linkTo) {
            return <Link className="absolute left-0 right-0 bottom-0 top-0 z-10" to={cta.linkTo}></Link>
        }
        if (cta.href) {
            return <a className="absolute left-0 right-0 bottom-0 top-0 z-10" href={cta.href} ></a>
        }
        if (cta.onClick) {
            return <button className="absolute left-0 right-0 bottom-0 top-0 z-10 block" onClick={cta.onClick} ></button>
        }
    }
    return <></>;
}

function CTARealizzazioniElement(props: { cta?: CTAType, className?: string }) {
    const cta = props.cta;
    if (cta) {
        const ctaLabel = cta && cta.visibile ? cta.label || "Scopri di più" : "";
        const item = cta && cta.visibile ? <div className={classNames("text-p lg:text-md-p flex items-center underline hover:no-underline")}><span>{ctaLabel}</span> <Icon name="chevron" className="ml-3 w-4 h-4" /></div> : <></>
        return <div className={classNames("absolute right-0 bottom-0 z-10", props.className)}>{item}</div>
    }
    return <></>;
}

function Media(props: { image: MultimediaType }) {

    //console.log("Media", props.image);

    return <>
        {!props.image.videoMobile ?
            <picture className="block">
                {props.image.srcDesktop ? <source srcSet={props.image.srcDesktop} media="(min-width: 1024px)" /> : <></>}
                <img loading="lazy" className="block object-cover object-center w-full" src={props.image.src} alt={props.image.alt} />
            </picture> : <>
                {props.image.videoDesktop ? <video autoPlay muted playsInline loop className="hidden lg:block"><source type="video/mp4" src={props.image.videoDesktop.mp4}></source></video> : <></>}
                {props.image.videoMobile ? <video autoPlay muted playsInline loop className={classNames("block", props.image.videoDesktop?.mp4 ? "lg:hidden" : "")}><source type="video/mp4" src={props.image.videoMobile.mp4}></source></video> : <></>}
            </>}
    </>;
}

export default function Highlight(props: HighlightPropsType) {

    const hasDescription = props.description && props.description.length > 0;
    const hasInset = props.inset !== undefined;
    const cta = <CTAElement cta={props.cta} className={classNames(props.lightBackground ? "text-antoniazzi-900" : "text-white", hasDescription ? "lg:hidden" : "")} />;
    let ctaLg = <></>;
    const ctaPropsLg: CTAType | undefined = props.cta ? { ...props.cta } : undefined;
    if (hasDescription && ctaPropsLg) {
        ctaPropsLg.position = "inline";
        ctaLg = <CTAElement cta={ctaPropsLg} className="text-antoniazzi-900" />;
    }
    const ctaAbsolute = props.cta && (!props.cta.position || props.cta.position === 'absolute');
    const leftInsetPosition = props.inset !== undefined && props.inset.indexOf("left") > -1;
    const rightInsetPosition = props.inset !== undefined && props.inset.indexOf("right") > -1;
    const bottomInsetPosition = props.inset !== undefined && props.inset.indexOf("bottom") > -1;

    const keepRightSpace = hasInset && bottomInsetPosition && leftInsetPosition && props.cta && props.cta?.visibile && ctaAbsolute;
    const image = props.image ? (props.image.component || <>{props.image.wrap ? <div className={classNames(props.image.className ? props.image.className : "aspect-w-1 aspect-h-1")}><Media image={props.image} /></div> : <Media image={props.image} />}</>) :
        <div className={classNames(!props.lightBackground ? "bg-antoniazzi-900" : "bg-antoniazzi-300/50", "aspect-w-3 aspect-h-2")}></div>;
    return (
        <section className={classNames("highlight relative", hasDescription ? "pt-1 lg:pt-14 lg:grid items-center lg:grid-cols-2 lg:gap-x-6 max-w-5xl mx-auto" : "")}>
            <div className={classNames("relative", props.className)}>
                <div className={classNames(
                    hasInset ? (props.lightBackground ? "text-antoniazzi-900" : "text-white") + " absolute z-10" : "pt-8 px-4 pb-4",
                    bottomInsetPosition ? "bottom-4" : "top-7", bottomInsetPosition && props.small ? "lg:bottom-9" : "",
                    keepRightSpace ? "right-36" : "",
                    leftInsetPosition ? "left-4 right-4" + (!props.small ? " lg:left-10" : " lg:left-5") : "",
                    rightInsetPosition ? "right-4 left-4 text-right" + (!props.small ? " lg:right-10" : " lg:right-5") : "",
                )}>
                    {props.title ? <h3 className={classNames(props.small ? "text-p lg:text-md-h4 mb-1 lg:mb-[0.625rem]" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-h1 lg:text-md-h2" : "", hasDescription ? "lg:hidden mb-7" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                    {props.subtitle ? <h4 className={classNames(props.small ? "text-p-sm lg:text-md-h5" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-p lg:text-md-p" : "", hasDescription ? "lg:hidden" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                    {!ctaAbsolute && props.cta?.visibile ? <div className="mt-3">{cta}</div> : <></>}
                </div>
                {ctaAbsolute ? <>{cta}</> : <></>}
                {image}
            </div>
            {hasDescription ? <div className="hidden lg:block">
                {props.title ? <h3 className={classNames(props.small ? "text-p lg:text-md-h4" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-h1 lg:text-md-h2" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                {props.subtitle ? <h4 className={classNames(props.small ? "text-p-sm lg:text-md-h5" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-p lg:text-md-p" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                <div className="mt-6 mb-8 lg:mb-12 lg:mt-8 text-p lg:text-md-p" dangerouslySetInnerHTML={{ __html: props.description || "" }} />
                <>{ctaLg}</>
            </div> : <></>}
            <CTAButton cta={props.cta} />
        </section>);
}

export  function HighlightPuntoVendita(props: HighlightPropsType) {

    const hasDescription = props.description && props.description.length > 0;
    const hasInset = props.inset !== undefined;
    const cta = <CTAElement cta={props.cta} className={classNames(props.lightBackground ? "text-antoniazzi-900" : "text-white", hasDescription ? "lg:hidden" : "")} />;
    let ctaLg = <></>;
    const ctaPropsLg: CTAType | undefined = props.cta ? { ...props.cta } : undefined;
    if (hasDescription && ctaPropsLg) {
        ctaPropsLg.position = "inline";
        ctaLg = <CTAElement cta={ctaPropsLg} className="text-antoniazzi-900" />;
    }
    const ctaAbsolute = props.cta && (!props.cta.position || props.cta.position === 'absolute');
    const leftInsetPosition = props.inset !== undefined && props.inset.indexOf("left") > -1;
    const rightInsetPosition = props.inset !== undefined && props.inset.indexOf("right") > -1;
    const bottomInsetPosition = props.inset !== undefined && props.inset.indexOf("bottom") > -1;

    const keepRightSpace = hasInset && bottomInsetPosition && leftInsetPosition && props.cta && props.cta?.visibile && ctaAbsolute;
    const image = props.image ? (props.image.component || <>{props.image.wrap ? <div className={classNames(props.image.className ? props.image.className : "aspect-w-1 aspect-h-1")}><Media image={props.image} /></div> : <Media image={props.image} />}</>) :
        <div className={classNames(!props.lightBackground ? "bg-antoniazzi-900" : "bg-antoniazzi-300/50", "aspect-w-3 aspect-h-2")}></div>;
    return (
        <section className={classNames("highlight relative", hasDescription ? "pt-1 lg:pt-14 lg:grid items-center lg:grid-cols-2 lg:gap-x-6 max-w-5xl mx-auto" : "")}>
            <div className={classNames("relative", props.className)}>
                <div className={classNames(
                    hasInset ? (props.lightBackground ? "text-antoniazzi-900" : "text-white") + " absolute z-10" : "pt-8 px-4 pb-4",
                    bottomInsetPosition ? "bottom-4" : "top-7", bottomInsetPosition && props.small ? "lg:bottom-9" : "",
                    keepRightSpace ? "right-36" : "",
                    leftInsetPosition ? "left-4 right-4" + (!props.small ? " lg:left-10" : " lg:left-5") : "",
                    rightInsetPosition ? "right-4 left-4 text-right" + (!props.small ? " lg:right-10" : " lg:right-5") : "",
                )}>
                    {props.title ? <h3 className={classNames(props.small ? "text-p lg:text-[20px] lg:leading-[23px] mb-1 lg:mb-[9px]" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-h1 lg:text-md-h2" : "", hasDescription ? "lg:hidden mb-7" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                    {props.subtitle ? <h4 className={classNames(props.small ? "text-p-sm lg:text-md-h5" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-p lg:text-md-p" : "", hasDescription ? "lg:hidden" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                    {!ctaAbsolute && props.cta?.visibile ? <div className="mt-3">{cta}</div> : <></>}
                </div>
                {ctaAbsolute ? <>{cta}</> : <></>}
                {image}
            </div>
            {hasDescription ? <div className="hidden lg:block">
                {props.title ? <h3 className={classNames(props.small ? "text-p lg:text-md-h4" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-h1 lg:text-md-h2" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                {props.subtitle ? <h4 className={classNames(props.small ? "text-p-sm lg:text-md-h5" : "", props.medium ? "text-p lg:text-md-p" : "", !props.small && !props.medium ? "text-p lg:text-md-p" : "", ctaAbsolute ? "overflow-hidden whitespace-nowrap text-ellipsis" : "")} dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                <div className="mt-6 mb-8 lg:mb-12 lg:mt-8 text-p lg:text-md-p" dangerouslySetInnerHTML={{ __html: props.description || "" }} />
                <>{ctaLg}</>
            </div> : <></>}
            <CTAButton cta={props.cta} />
        </section>);
}


function HighlightRealizzazioni(props: HighlightPropsType) {

    const hasDescription = props.description && props.description.length > 0;
    const cta = <CTARealizzazioniElement cta={props.cta} className={classNames(props.lightBackground ? "text-antoniazzi-900" : "text-white", hasDescription ? "lg:hidden" : "")} />;

    const ctaAbsolute = true;
    const image = props.image ? (
        props.image.component || <>{props.image.wrap ?
            <div className={classNames(props.image.className ? props.image.className : "aspect-w-1 aspect-h-1")}>
                <picture className="block">
                    {props.image.srcDesktop ? <source srcSet={props.image.srcDesktop} media="(min-width: 1024px)" /> : <></>}
                    <img loading="lazy" className="block object-cover object-center w-full" src={props.image.src} alt={props.image.alt} />
                </picture>
            </div> :
            <picture className="block">{props.image.srcDesktop ? <source srcSet={props.image.srcDesktop} media="(min-width: 1024px)" /> : <></>}
                <img className={classNames(props.image.className ? props.image.className : "block w-full")} src={props.image.src} alt={props.image.alt} />
            </picture>
        }</>) :
        <div className={classNames(!props.lightBackground ? "bg-antoniazzi-900" : "bg-antoniazzi-300/50", "aspect-w-3 aspect-h-2")}></div>;
    return (
        <section className="highlight relative">

            <div className="absolute top-4 left-4 right-4 bottom-4 lg:right-10 lg:left-10">
                <div className={classNames("absolute z-10 bottom-0 left-0", props.lightBackground ? "" : "text-white")}>
                    {props.title ? <h3 className="text-p lg:text-md-h4 mb-1 lg:mb-[9px] overflow-hidden whitespace-nowrap text-ellipsis" dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                    {props.subtitle ? <h4 className="text-p-sm lg:text-md-h5 overflow-hidden whitespace-nowrap text-ellipsis" dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                </div>
                {cta}
            </div>
            {image}
            <CTAButton cta={props.cta}></CTAButton>
        </section>);
}

function HighlightCake(props: HighlightPropsType) {
    const image = props.image ? (
        props.image.component || <>{props.image.wrap ?
            <div className={classNames(props.image.className ? props.image.className : "aspect-w-1 aspect-h-1")}>
                <picture className="block">
                    {props.image.srcDesktop ? <source srcSet={props.image.srcDesktop} media="(min-width: 1024px)" /> : <></>}
                    <img loading="lazy" className="block object-cover object-center w-full" src={props.image.src} alt={props.image.alt} />
                </picture>
            </div> :
            <picture className="block">{props.image.srcDesktop ? <source srcSet={props.image.srcDesktop} media="(min-width: 1024px)" /> : <></>}
                <img className={classNames(props.image.className ? props.image.className : "block w-full")} src={props.image.src} alt={props.image.alt} />
            </picture>
        }</>) :
        <div className={classNames(!props.lightBackground ? "bg-antoniazzi-900" : "bg-antoniazzi-300/50", "aspect-w-3 aspect-h-2")}></div>;
    return (
        <section className="highlight relative">

            <div className="absolute top-6 left-6 right-6 bottom-6">
                <div className={classNames("absolute z-10 bottom-0 left-0", props.lightBackground ? "" : "text-white")}>
                    {props.title ? <h3 className="text-p lg:text-md-p mb-1 overflow-hidden whitespace-nowrap text-ellipsis" dangerouslySetInnerHTML={{ __html: props.title || "" }} /> : <></>}
                    {props.subtitle ? <h4 className="text-p-sm lg:text-md-p-sm lg:mt-[0.625rem] overflow-hidden whitespace-nowrap text-ellipsis" dangerouslySetInnerHTML={{ __html: props.subtitle || "" }} /> : <></>}
                </div>
            </div>
            {image}
            <CTAButton cta={props.cta}></CTAButton>
        </section>);
}

export function HighlightsWrapper(props: { container: ContainerType }) {
    const container = props.container;

    const { dataBackend } = useFEContext();
    const [blocks, setBlocks] = useState<ContainerType[]>([]);
    useEffect(() => {
        const _blocks = container.containers?.filter(c => c.containerClass === 'BLOCK' && c.position === 'Center');
        if (_blocks) {
            dataBackend.getAll(_blocks).then(c => {
                if (!c.error)
                    setBlocks(c.records);
            }).catch(() => { });
        }
    }, [props.container])
    const hasCols = container.config.columns !== undefined;
    return <section data-container-id={props.container.id} className={classNames(
        "max-w-container highlights-wrapper pt-10 lg:pt-14",
        blocks.length > 1 || hasCols ? "lg:grid" : "",
        (!hasCols && blocks.length === 2) || container.config.columns === 2 || container.config.columns === "2" ? "lg:grid-cols-2" : "",
        (!hasCols && blocks.length === 3) || container.config.columns === 3 || container.config.columns === "3" ? "lg:grid-cols-3" : "",
        (!hasCols && blocks.length > 3) || container.config.columns === 4 || container.config.columns === "4" ? "lg:grid-cols-4" : "",
        container.config.columns === 5 ? "lg:grid-cols-5" : "",
        container.config.columns === 6 ? "lg:grid-cols-6" : ""
    )
    }>{blocks.map((bc, bck) => {
        return <HighlightContainer container={bc} key={bck} />;
    })}
    </section>
}
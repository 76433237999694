import { useEffect, useState } from "react";
import { Container, ContainerRef } from "../../Interfaces";
import React from "react";
import Multimedia from "../Subcomponents/Multimedia";
import { useFEContext } from "../FEContext";

export default function LightGallery(props: { containerRef?: ContainerRef, container?: Container }) {
    const [container, setContainer] = useState<Container>();
    const [items, setItems] = useState<Container[]>([]);
    const { dataBackend } = useFEContext();
    const loadItems = (cont: Container) => {
        let _items: Container[] | undefined = [];
        _items = cont.containers?.filter(cc => cc.containerClass === "CONTENT");
        if (!_items || _items.length === 0) {
            _items = cont.containers?.filter(cc => cc.containerClass === "MULTIMEDIA_CONTENT");
        }
        setItems(_items || []);
    }
    useEffect(() => {
        setItems([]);
        if (props.container) {
            setContainer(props.container);
            loadItems(props.container)
        }
    }, [props.container])
    useEffect(() => {
        setItems([]);
        if (props.containerRef) {
            dataBackend.getContainer(props.containerRef.id).then(c => {
                if (c) {
                    setContainer(c);
                    loadItems(c)
                }
            })
        }

    }, [props.containerRef]);
    return <section data-container-id={container?.id} className="light-gallery"><div className="grid gap-2 grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto">
        {items.map((cr, crk) => {
            return <div className="aspect-w-1 aspect-h-1"><Multimedia large key={crk} containerRef={cr} className="absolute top-0 left-0 w-full h-full object-cover object-center" /></div>;
        })}
    </div></section>
}
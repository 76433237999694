import React, { createRoot } from "react-dom/client";
import ViewBlocks from "./ViewBlocks";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import { FEProvider } from "./FEContext";
import { DataBackend } from "../DataBackend";
import Wrapper from "./Wrapper";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ViewLogout from "./ViewLogout";
import ViewProfilo from "./ViewProfilo";
import ViewResetPassword from "./ViewResetPassword";
import ViewStartResetPassword from "./ViewStartResetPassword";
//import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ViewOrdini from "./ViewOrdini";
import ViewOrdineDettaglio, { ViewPagamento } from "./ViewOrdineDettaglio";
const dataBackend = new DataBackend();
/* sandbox AfphAWH_YEgxEDnQtzcNUsgZcPJ56vawkmDpmwXaqH9SbpP39uC2yTFnrxn4nxx8pgemJ7BzQdKgJbhB
    xp production AUxXBUUI_uybxbuuWZNJ-pHseOHftR6TOPE9w9t4bAxVOcFsLIJP82DEyy11EqS5VU4B7zKGyfs8dO3w         */
dataBackend.initData().then(() => {
    const rootEl = document.getElementById("root");
    /*const clientId = window.localStorage.getItem("paypalKey") || "";*/
    /*<PayPalScriptProvider options={{ clientId: clientId, currency: "EUR" }}></PayPalScriptProvider>*/

    if (rootEl) {
        const root = createRoot(rootEl);
        root.render(
            <GoogleReCaptchaProvider
                reCaptchaKey="6LerMcwoAAAAAA86ljrcCiL93fup027NBJ2ajTyQ"
                language="it"
                scriptProps={{
                    async: true, // optional, default to false,
                    defer: true, // optional, default to false
                }}
                container={{ // optional to render inside custom element
                    parameters: {
                        theme: 'dark', // optional, default undefined
                    }
                }}
            >
                <BrowserRouter>
                    <FEProvider dataBackend={dataBackend}>
                        <Routes>
                            <Route path="/" element={<Wrapper />}>
                                <Route index element={<ViewBlocks />} />
                                <Route path="logout" element={<ViewLogout />} />
                                <Route path="profilo" element={<ViewProfilo />} />
                                <Route path="reset-password" element={<ViewResetPassword />} />
                                <Route path="recupero-password" element={<ViewStartResetPassword />} />
                                <Route path="*" element={<ViewBlocks />} />
                                <Route path="ordini">
                                    <Route index element={<ViewOrdini />} />
                                    <Route path=":idOrdine" element={<ViewOrdineDettaglio />} />
                                    <Route path="cancella" element={<ViewOrdineDettaglio />} />
                                    <Route path="conferma" element={<ViewOrdineDettaglio />} />
                                    <Route path="pagamento">
                                        <Route path="ko" element={<ViewPagamento ok={false} />} />
                                        <Route path="ok" element={<ViewPagamento ok={true} />} />
                                    </Route>
                                </Route>

                            </Route>
                        </Routes>
                    </FEProvider>
                </BrowserRouter>
            </GoogleReCaptchaProvider>

        );
    }
})

import React, { useEffect, useState } from "react";
import { useFEContext } from "../FEContext";
import { AddressType, CartType } from "../../Interfaces";
import classNames from "classnames";
export default function CartEditDestination(props: { checkout?: boolean }) {

    const { cart, setCart, strings, dataBackend } = useFEContext();
    const [localCart, setLocalCart] = useState<CartType>();
    useEffect(() => {
        setLocalCart(cart)
    }, [cart])
    const updateProvincia = (ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.provincia = ev.target.value; setLocalCart(u) };

    const confermaDestinazione = () => {
        if (localCart) {
            setCart(localCart).then(cc => {
                if (cc.readyForCheckout)
                    window.location.hash = "checkout";
                else
                    window.location.hash = "cart";
            });
        }

    }

    return <div className="text-antoniazzi-900 pt-8 px-4">
        <h3 className="text-h1 md:text-md-h1 mb-4">{strings.get("Indirizzo di consegna")}</h3>
        <p className="text-p md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>

        <input type="text" className="form-control" required value={localCart?.indirizzoDestinazione?.nominativo} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.nominativo = ev.target.value; setLocalCart(u) }} placeholder={strings.get("Nome referente") + "*"} />
        <input type="text" className="form-control" required value={localCart?.indirizzoDestinazione?.presso} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.presso = ev.target.value; setLocalCart(u) }} placeholder={strings.get("C/O presso") + "*"} />
        <select className="form-control" required value={localCart?.indirizzoDestinazione?.paese} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.paese = ev.target.value; setLocalCart(u) }} placeholder={strings.get("Paese") + "*"}>
            <option value="">{strings.get("Paese...")}</option>
            {dataBackend.paesi.map((val, valk) => {
                return <option key={valk} value={val.value}>{val.label}</option>;
            })}</select>
        <input type="text" className="form-control" required value={localCart?.indirizzoDestinazione?.indirizzo} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.indirizzo = ev.target.value; setLocalCart(u) }} placeholder={strings.get("Indirizzo (Via e numero civico)") + "*"} />
        <input type="text" className="form-control" required value={localCart?.indirizzoDestinazione?.cap} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.cap = ev.target.value; setLocalCart(u) }} placeholder={strings.get("Codice postale") + "*"} />
        <input type="text" className="form-control" required value={localCart?.indirizzoDestinazione?.citta} onChange={(ev) => { const u = { ...localCart }; if (u?.indirizzoDestinazione) u.indirizzoDestinazione.citta = ev.target.value; setLocalCart(u) }} placeholder={strings.get("Città") + "*"} />
        {localCart?.indirizzoDestinazione?.paese === 'IT' ? <select className="form-control" onChange={updateProvincia} value={localCart?.indirizzoDestinazione?.provincia}><option value="">{strings.get("Provincia...")}</option> {dataBackend.province.map((val, valk) => {
            return <option key={valk} value={val.value}>{val.label}</option>
        })}</select> : <input type="text" className="form-control !border-b" required value={localCart?.indirizzoDestinazione?.provincia} onChange={updateProvincia} placeholder={strings.get("Provincia") + "*"} />}


        <button className="flex px-3 text-left text-p md:text-md-p py-4 items-start" onClick={() => { const u = { ...localCart }; u.regalo = !u.regalo; setLocalCart(u) }}>
            <div className={classNames("btn-checkbox mr-2", !localCart?.regalo ? "" : "btn-checkbox-checked")} />
            <div>{strings.get("L'ordine è un regalo")}</div>
        </button>


        <div className="pb-10">
            <button onClick={confermaDestinazione} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Conferma e prosegui")}</button>
        </div>

    </div>
}
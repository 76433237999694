import React, { PropsWithChildren } from "react";
import { ContainerRef } from "../../Interfaces";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
export interface BreadcrumbsPropsType extends PropsWithChildren {
    items?: ContainerRef[]
}
export default function Breadcrumbs(props: BreadcrumbsPropsType) {
    const items: ContainerRef[] = props.items || [];
    const prevContainer = items.length > 1 ? items[items?.length - 2] : undefined;
    const onlyOneItems = items.length === 1;
    return <>
        {items && items.length > 0 ?
            <div className="flex px-4 md:px-0 max-w-5xl mx-auto text-antoniazzi-900 text-xs items-center py-5 gap-x-4 breadcrumbs">
                <Link className={classNames(onlyOneItems ? "pointer-events-none opacity-60" : "", "border border-antoniazzi-900 rounded-full aspect-square p-0.5")} to={prevContainer?.routeTo || "#"}><ChevronLeftIcon className="w-3 h3" /></Link>
                {items.map((cc, cck) => { return <div key={cck} className="flex items-center">{cck > 0 ? <ChevronRightIcon className="w-3 h3 mr-4" /> : <></>} <Link key={cck} className={classNames(cck + 1 === items.length ? "underline cursor-default pointer-events-none" : "")} to={cc.routeTo || "#"}>{cc.title}</Link></div>; })}
            </div> :
            <></>
        }
    </>;
}
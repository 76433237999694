import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Multimedia from "../Subcomponents/Multimedia";
import { Icon } from "../../IconLib";

function Articolo(props: { index: number, container: ContainerType, secondary?: boolean, dark?: boolean, head?: boolean, titleHead?: boolean }) {

    let imagesList: ContainerRefType[] = [];

    let defaultImage: ContainerRefType | undefined = undefined;
    useEffect(() => {

    }, [props.container]);


    if (props.container) {
        if (props.container.containers) {
            const _images = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT");
            _images?.forEach(element => {
                if (element.position === "Center") defaultImage = element;
                if (element.position === "List") imagesList.push(element);
            });
        }
    }

    const wide = props.container.config ? props.container.config.wide : false;

    return <>
        <section data-container-id={props.container.id} className={classNames(
            "mx-auto",
            wide ? "max-w-7xl" : "max-w-5xl",
            "body-article",
            props.container.className
        )}>
            <div className={classNames(props.dark ? "order-1" : "", "relative md:order-2")}>
                {props.container.showTitle ? <div className="px-4 xl:px-0 mb-5 md:mb-12">
                    {props.container.title ? <h3 className="text-h2 md:text-md-h2">{props.container.title}</h3> : <></>}
                    {props.container.subtitle ? <h4 className="subtitle text-[.81rem] md:text-base">{props.container.subtitle}</h4> : <></>}
                </div> : <></>}
            </div>
            {defaultImage ? <div> <Multimedia loading="lazy" containerRef={defaultImage} className="block w-full mx-4 lg:mx-0" medium /> </div> : <></>}
            {imagesList.length > 0 ? <div className="grid grid-cols-2 mx-4 gap-y-4 lg:gap-y-10 lg:flex lg:justify-center lg:flex-wrap lg:mx-0">
                {imagesList.map((i, k) => {
                    return <Multimedia loading="lazy" key={k} containerRef={i} className="block lg:max-w-[20%] grow-0 shrink-0" medium />
                })}
            </div> : <></>}

        </section>
    </>;
}


export default function Referenze(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const secondary = props.container.config.secondary || false;
    const dark = props.container.config.dark || false;
    const head = props.container.config.head || false;
    const titleHead = props.container.config.titleHead || false;
    const keepDistances = props.container.config.keepDistances || "";
    const padding = props.container.config.padding || "";
    const paddingTop = props.container.config.paddingTop || "";
    const centered = props.container.config.centered || "";
    const articlesReact =
        articles.map((ba, bak) => {
            return <Articolo dark={dark} index={bak} container={ba} key={bak} head={head} titleHead={titleHead} />
        })

    return (
        <section className={classNames(
            secondary ? "bg-antoniazzi-300/50 text-antoniazzi-900 block-articles-secondary pb-8 md:py-14" : "",
            dark ? "bg-dark text-white md:bg-white md:text-antoniazzi-900 block-articles-dark" : "",
            !secondary && !dark ? "bg-white text-antoniazzi-900" : "",
            padding ? "py-8 md:py-14" : "",
            paddingTop ? "pt-9 md:pt-14" : "",
            "block-articles",
            keepDistances ? "flex flex-col gap-y-8 md:gap-y-14 py-8 md:py-14" : "",
            centered ? "mx-auto max-w-5xl lg:px-8 lg:text-center" : ""
        )}>
            {articlesReact}
        </section>
    );
}
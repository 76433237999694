import React, { useEffect, useState } from "react";
import { ContainerType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import { Link } from "react-router-dom";
import Multimedia from "../Subcomponents/Multimedia";
import { Icon } from "../../IconLib";

function StrutturaArticle(props: { container: ContainerType }) {
    let image, ctas;
    if (props.container) {
        if (props.container.containers) {
            const _images = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT");
            if (_images) {
                image = _images[0];
            }
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }
    return <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:mt-20 bg-antoniazzi-900 text-white lg:bg-white lg:text-antoniazzi-900">
        <Multimedia loading="lazy" containerRef={image} large className="block order-1 w-full lg:w-[567px] mb-7 lg:mt-0 lg:order-2" />
        <div className="order-2 lg:order-1 relative">
            <div className="px-4 lg:px-0 lg:pr-6">
                <h1 className="pb-7 lg:pb-16 lg:pt-14 text-h2 lg:text-md-h2">{props.container.title}</h1>
                <div className="text-p lg:text-md-p pb-11 lg:pb-0" dangerouslySetInnerHTML={{ __html: props.container.description || "" }}></div>
                {ctas && ctas.length > 0 ? <div className="absolute bottom-10 hidden lg:block">{ctas.map((cta, ctak) => {
                    return <div key={ctak} >
                        {cta.routeTo ? <Link className="underline hover:no-underline flex items-center text-p lg:text-md-p" to={cta.routeTo || "/"} >
                            {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                        </Link> : <></>}
                        {cta.externalUrl ? <a className="underline hover:no-underline flex items-center text-p lg:text-md-p" href={cta.externalUrl || "/"}>
                            {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                        </a> : <></>}</div>
                })}</div>

                    : <></>}
            </div>
        </div>
    </div>
}


export function IntroStruttura(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const articlesReact =
        articles.map((ba, bak) => {
            return <StrutturaArticle container={ba} key={bak} />
        })

    return (
        <section className="struttura-article">
            {articlesReact}
        </section>
    );
}
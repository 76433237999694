import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Articolo(props: { container: ContainerType, secondary?: boolean, dark?: boolean }) {

    let ctas: ContainerRefType[] | undefined = undefined;
    if (props.container) {
        if (props.container.containers) {
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }
    const wide = props.container.config ? props.container.config.wide : false;
    const hasBlocksCTA = ctas && ctas.length > 0;
    const darkBtn = !props.secondary && !props.dark;


    return <>
        <section data-container-id={props.container.id} className={classNames(
            "mx-auto",
            wide ? "max-w-7xl" : "max-w-5xl",
            "boxed-article",
            props.container.className
        )}>


            <div>


                {props.container.showTitle ? <div className="px-4 xl:px-0 mb-5 lg:mb-7">
                    {props.container.title ? <h3 className={classNames("title text-h2 lg:text-md-h3")}>{props.container.title}</h3> : <></>}
                    {props.container.subtitle ? <h4 className="subtitle text-antoniazzi-600 text-h5 lg:text-md-h5">{props.container.subtitle}</h4> : <></>}
                </div> : <></>}

                <div className={classNames("px-4 xl:px-0", hasBlocksCTA ? "lg:grid lg:grid-cols-2 lg:gap-x-6" : "")}>

                    <div>
                        {props.container.showContentIntro && props.container.introduction ?
                            <div className={classNames("introduction", "text-h3 lg:text-md-h4")} dangerouslySetInnerHTML={{ __html: props.container.introduction || "" }} /> : <></>}
                        {props.container.description ?
                            <div className={classNames("description", "text-p lg:text-md-p")} dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}

                    </div>

                    {ctas && ctas.length > 0 ? <div className="mt-6 lg:mt-0 flex flex-col gap-y-1">{ctas.map((cta, ctak) => {

                        let externalUrl = cta.externalUrl;
                        if (externalUrl && externalUrl.indexOf("#candidatura") > -1) {
                            externalUrl = externalUrl + "_" + props.container.id;
                        }
                        return <div key={ctak}>
                            {cta.routeTo ?
                                <Link to={cta.routeTo || "/"} className={classNames("btn btn-outline-primary rounded-sm w-full block text-center text-p lg:text-md-p", darkBtn ? "text-white bg-antoniazzi-900 hover:bg-antoniazzi-600" : "", props.dark ? "!border-white !text-white hover:bg-white hover:!text-antoniazzi-900" : "")}>
                                    {cta.title}
                                </Link> : <></>}
                            {cta.externalUrl ?
                                <a href={externalUrl || "/"} className={classNames("btn btn-outline-primary rounded-sm w-full block text-center text-p lg:text-md-p", darkBtn ? "text-white bg-antoniazzi-900 hover:bg-antoniazzi-600" : "", props.dark ? "!border-white !text-white hover:bg-white hover:!text-antoniazzi-900" : "")}>
                                    {cta.title}
                                </a> : <></>}</div>
                    })}</div>

                        : <></>}
                </div>
            </div>

        </section>
    </>;
}


export function BoxedArticles(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const secondary = props.container.config.secondary || false;
    const dark = props.container.config.dark || false;
    const articlesReact =
        articles.map((ba, bak) => {
            return <div className={classNames("py-8 lg:py-11",
                secondary ? "bg-antoniazzi-300/50 text-antoniazzi-900" : "",
                dark ? "bg-dark text-white" : "",
                !secondary && !dark ? "bg-white text-antoniazzi-900" : ""
            )}><Articolo key={bak} container={ba} dark={dark} secondary={secondary} /></div>
        })

    return (
        <section className={classNames(
            "flex flex-col gap-y-1 bg-white",
            "boxed-articles",
        )}>
            {articlesReact}
        </section>
    );
}
import React, { useEffect, useState } from "react";
import { AccessoryConfigType, AccessoryType, AlertType, ContainerRefType, ContainerType, PersonalizzazioneType, PowerLayerType, ProductRefType, ProductType, SelectedAccessoryType, formatPrice } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Icon } from "../../IconLib";
import { humanFileSize } from "./Candidatura";
import { AddedToCartPowerLayer } from "../ViewProdotto";

const ricorrenze = ["Nessuna ricorrenza", "Compleanno", "Laurea", "Anniversario", "Cresima", "Comunione", "Battesimo"];

export default function Personalizzazione(props: { prodotto: ProductType, size: ProductRefType }) {
    const { strings, cart, setCart, showPowerLayer, dataBackend } = useFEContext();
    const [datas, setDatas] = useState<PersonalizzazioneType>({ accessories: [] });


    const _add = (bdatas: PersonalizzazioneType) => {
        const prodotto = props.prodotto;
        const selectedSize = props.size;

        const ca = cart ? { ...cart } : {};
        if (!ca.rows) {
            ca.rows = [];
        }
        if (ca?.deliveryMethod) {
            const dm = prodotto.deliveryMethods?.filter(dm => dm.id === ca.deliveryMethod?.id);
            if (dm && dm.length > 0) {
                if (!dm[0].valid) {
                    ca.deliveryMethod = undefined;
                }
            }
        }
        const prod = selectedSize || (prodotto.sizes && prodotto.sizes.length > 0 ? prodotto.sizes[0] : prodotto);
        delete bdatas.picture;

        const acc = [...bdatas.accessories];
        acc.forEach((element, ind) => {
            if (element.accessory) {
                element.idProduct = element.accessory.accessory?.id;
                delete element.accessory;
            }
            bdatas.accessories[ind] = element;
        });

        const desc = prod.id !== prodotto.id ? prodotto.title + " " + prod.title : prodotto.title + " " + strings.get("personalizzato");
        ca.rows.push({
            deliveryMethods: prodotto.deliveryMethods,
            priceStandard: datas.total,
            priceDisplay: datas.total,
            customization: true,
            config: bdatas,
            idProduct: prod.id,
            quantity: 1,
            description: desc || "senza nome"
        });

        const al: PowerLayerType = {
            active: true, content: <AddedToCartPowerLayer />
        };

        showPowerLayer(al);
        setCart(ca);
        setDatas({ accessories: [] });
    }

    const addToCart = () => {
        let currentTot = parseFloat(props.size.priceStandard || "0");
        for (let index = 0; index < datas.accessories.length; index++) {
            const element = datas.accessories[index];
            currentTot = currentTot + ((element.price || 0) * (element.quantity || 0));
        }
        datas.total = currentTot;
        if (datas.picture) {
            const confermaUrl = dataBackend.endpointBase + "/wa/ToolsAction/storeCustomOrderPicture";
            let opts: any = {};
            const formData = new FormData();
            formData.append("upload", datas.picture);
            opts = {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                body: formData
            }
            fetch(confermaUrl, opts).then(conf => conf.json()).then(jsonConf => {
                if (!jsonConf.error) {
                    const bdatas = { ...datas };
                    bdatas.pictureId = jsonConf.pictureId;
                    _add(bdatas);
                }
            })
        } else {
            _add(datas);
        }



    };
    return <>
        {!datas.ricorrenza ? <SceltaRicorrenza datas={datas} onChange={(d) => { setDatas(d); }} /> : <></>}
        {datas.ricorrenza ?
            <>
                <SceltaAccessori datas={datas} prodotto={props.prodotto} size={props.size} onChange={(d) => { setDatas(d); }} />
                <div className="text-antoniazzi-900 px-4 pt-6 pb-16 bg-antoniazzi-300/50">
                    <PartialTotal datas={datas} prodotto={props.prodotto} size={props.size} />
                    <button onClick={addToCart} className="btn rounded-sm block w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Aggiungi al carrello")}</button>
                </div> </> : <></>}
    </>;
}
const maxFileSize = 2 * 1024 * 1024;

function SceltaRicorrenza(props: { datas: PersonalizzazioneType, onChange: (datas: PersonalizzazioneType) => void }) {
    const { strings } = useFEContext();
    return <div className="text-antoniazzi-900 px-4 pb-16">
        <h3 className="text-h2 md:text-md-h2 mt-10 mb-4">{strings.get("Festeggi un’occasione speciale?")}</h3>
        <div>
            {ricorrenze.map((ricorrenza, kr) => {
                const selected = ricorrenza === props.datas.ricorrenza;
                return <button key={kr} className="px-4 items-center flex" onClick={() => {
                    const d = { ...props.datas };
                    d.ricorrenza = ricorrenza;
                    props.onChange(d);
                }}>
                    <div className={classNames("btn-radio mr-2", !selected ? "" : "btn-radio-checked")}></div> {strings.get(ricorrenza)}
                </button>;
            })}
        </div>
    </div>
}
function PartialTotal(props: { prodotto: ProductType, size: ProductRefType, datas: PersonalizzazioneType }) {
    const { strings } = useFEContext();
    let currentTot = parseFloat(props.size.priceStandard || "0");
    for (let index = 0; index < props.datas.accessories.length; index++) {
        const element = props.datas.accessories[index];
        currentTot = currentTot + ((element.price || 0) * (element.quantity || 0));
    }
    return <>
        <h5 className="text-h4 lg:text-md-h4 mb-6">{strings.get("Riepilogo")}</h5>
        <div className="flex mb-1 text-p lg:text-md-p">
            <div className="w-6 mr-10 grow-0 shrink-0">1</div>

            <div className="">{(props.prodotto.id != props.size.id ? props.size.parentContainerTitle + " " : "") + props.size.title}</div> <div className="ml-auto">{formatPrice(props.size.priceStandard)}</div></div>
        {props.datas.accessories.map((d, k) => {
            const rowTot = (d.price || 0) * (d.quantity || 0);
            return <div key={k} className="flex mb-1 text-p lg:text-md-p">
                <div className="w-6 mr-10 grow-0 shrink-0"> {d.quantity}</div> <div>{d.accessory?.accessory?.title}</div>
                <div className="ml-auto"> {formatPrice(rowTot)}</div>
            </div>;
        })}

        <div className="mt-9 border-t border-antoniazzi-900 pt-5">

            <div className="text-h4 lg:text-md-h4 mb-6 flex">
                <div>{strings.get("Subtotale")}</div>
                <div className="ml-auto"> {formatPrice(currentTot)}</div>
            </div>
        </div>
    </>
}
/*
<option value="">Semplice</option>
<option value="Scritta">Scritta</option>
<option value="Fotografia">Fotografia</option>
<option value="Candela">Candela generica</option>
<option value="Candela cifra">Candela cifra</option>*/
function SceltaAccessori(props: { prodotto: ProductType, size: ProductRefType, datas: PersonalizzazioneType, onChange: (datas: PersonalizzazioneType) => void }) {
    const { strings, showAlert } = useFEContext();

    const candeline: AccessoryType[] = [];
    const scritte: AccessoryType[] = [];
    const fotografie: AccessoryType[] = [];
    const altriAccessori: AccessoryType[] = [];
    if (props.prodotto.accessories) {
        for (let index = 0; index < props.prodotto.accessories.length; index++) {
            const accessorio = props.prodotto.accessories[index];
            const customConfig: AccessoryConfigType = accessorio.customConfig as AccessoryConfigType;
            let validSize = customConfig.forAllSizes;
            if (!validSize) {
                if (customConfig.sizes) {
                    for (let is = 0; is < customConfig.sizes.length; is++) {
                        const pro = customConfig.sizes[is];
                        if (pro === props.size.id) {
                            validSize = true;
                            break;
                        }
                    }
                } else {
                    validSize = true;
                }
            }
            if (validSize) {
                if (accessorio.accessory && accessorio.accessory.config) {
                    const type: string = accessorio.accessory.config.accessoryType || "";
                    if (type.indexOf('Candela') > -1) {
                        candeline.push(accessorio);
                    }
                    else if (type === 'Scritta') {
                        scritte.push(accessorio);
                    }
                    else if (type === 'Fotografia') {
                        fotografie.push(accessorio);
                    }
                    else if (!type) {
                        altriAccessori.push(accessorio);
                    }
                }
            }
        }
    }
    let hasAccessoryFotografia = false;
    let hasAccessoryScritta = false;
    if (props.datas.accessories) {
        for (let index = 0; index < props.datas.accessories.length; index++) {
            const element = props.datas.accessories[index];
            if (element.accessory?.accessory?.config.accessoryType === 'Fotografia') {
                hasAccessoryFotografia = true;
            }
            if (element.accessory?.accessory?.config.accessoryType === 'Scritta') {
                hasAccessoryScritta = true;
            }
            if (hasAccessoryScritta && hasAccessoryFotografia) {
                break;
            }

        }
    }
    const hasCandeline = candeline.length > 0;
    const hasScritte = scritte.length > 0;
    const hasFotografie = fotografie.length > 0;

    const deleteAllScritte = () => {
        const data = { ...props.datas };
        const d2 = { ...props.datas }
        for (let drin = 0; drin < d2.accessories.length; drin++) {
            const element = d2.accessories[drin];
            if (element.accessory?.accessory?.config.accessoryType === 'Scritta') {
                data.accessories.splice(drin, 1);
            }
        }
        return data;
    }


    const deleteAllFotografia = () => {
        const data = { ...props.datas };
        const d2 = { ...props.datas }
        for (let drin = 0; drin < d2.accessories.length; drin++) {
            const element = d2.accessories[drin];
            if (element.accessory?.accessory?.config.accessoryType === 'Fotografia') {
                data.accessories.splice(drin, 1);
            }
        }
        return data;
    }
    return <div className="text-antoniazzi-900 px-4 pb-16">
        <h3 className="text-h2 md:text-md-h2 mt-10 mb-4">{strings.get("Torta di " + props.datas.ricorrenza)}</h3>
        {hasCandeline ? <>
            <h4 className="text-h3 lg:text-md-h3 mb-5">{strings.get("Candeline")}</h4>
            {candeline.map((cand, candk) => {
                let accessoryRowIndex: number | undefined = undefined;
                for (let index = 0; index < props.datas.accessories.length; index++) {
                    const element = props.datas.accessories[index];
                    if (element.accessory?.id === cand.id) {
                        accessoryRowIndex = index;
                        break;
                    }
                }
                const val = accessoryRowIndex !== undefined ? props.datas.accessories[accessoryRowIndex] : undefined;
                return <SceltaCandelina value={val} onChange={(selection) => {
                    const data = { ...props.datas };
                    if (selection) {
                        if (accessoryRowIndex !== undefined) {
                            data.accessories[accessoryRowIndex] = selection;
                        } else {
                            data.accessories.push(selection);
                        }
                    } else if (accessoryRowIndex !== undefined) {
                        data.accessories.splice(accessoryRowIndex, 1);
                    }
                    props.onChange(data);
                }} accessorio={cand} key={candk} />
            })}
        </> : <></>}
        {hasScritte ? <>

            <h4 className="text-h3 lg:text-md-h3 mb-5 pt-7 border-t border-antoniazzi-900">{strings.get("Scritte")}</h4>

            {scritte.map((acc, candk) => {
                let accessoryRowIndex: number | undefined = undefined;
                for (let index = 0; index < props.datas.accessories.length; index++) {
                    const element = props.datas.accessories[index];
                    if (element.accessory?.id === acc.id) {
                        accessoryRowIndex = index;
                        break;
                    }
                }
                const val = accessoryRowIndex !== undefined ? props.datas.accessories[accessoryRowIndex] : undefined;
                return <> <SceltaScritta value={val} onChange={(selection) => {
                    const data = deleteAllScritte();
                    if (selection) {
                        data.accessories.push(selection);
                    }
                    props.onChange(data);
                }} accessorio={acc} key={candk} />

                </>
            })}

            <div className="flex items-center mb-7">
                <div className="mr-5"><AntoRadio checked={!hasAccessoryScritta} onChange={() => {
                    props.onChange(deleteAllScritte());
                }} /></div>
                <h5 className="text-h4 md:text-md-h4 mb-1">{strings.get("Nessuna")}</h5>
            </div>

        </> : <></>}
        {hasFotografie ? <>

            <h4 className="text-h3 lg:text-md-h3 mb-5 pt-7 border-t border-antoniazzi-900">{strings.get("Foto")}</h4>

            {fotografie.map((acc, candk) => {
                let accessoryRowIndex: number | undefined = undefined;
                for (let index = 0; index < props.datas.accessories.length; index++) {
                    const element = props.datas.accessories[index];
                    if (element.accessory?.id === acc.id) {
                        accessoryRowIndex = index;
                        break;
                    }
                }
                const val = accessoryRowIndex !== undefined ? props.datas.accessories[accessoryRowIndex] : undefined;
                return <> <SceltaFotografia value={val} onChange={(selection) => {
                    const data = deleteAllFotografia();
                    if (selection) {
                        data.accessories.push(selection);
                    }
                    props.onChange(data);
                }} accessorio={acc} key={candk} />

                </>
            })}

            <div className="flex items-center mb-7">
                <div className="mr-5"><AntoRadio checked={!hasAccessoryFotografia} onChange={() => {
                    props.onChange(deleteAllFotografia());
                }} /></div>
                <h5 className="text-h4 md:text-md-h4 mb-1">{strings.get("Nessuna")}</h5>
            </div>

            {hasAccessoryFotografia ? <div className="mt-10">

                {!props.datas.picture ? <button className="btn cursor-pointer text-center block relative mt-5 rounded-sm w-full border border-antoniazzi-900 bg-white text-antoniazzi-900 hover:bg-antoniazzi-900 hover:text-white">{strings.get("Scegli e carica la tua foto (max. 2 MB)")}
                    <input onChange={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        if (ev.target.files && ev.target.files.length === 1) {
                            const f = ev.target.files[0];
                            const d = { ...props.datas };
                            if (f.size <= maxFileSize) {
                                d.picture = (ev.target.files[0])
                                props.onChange(d);
                            }
                            else {
                                const al: AlertType = { active: true, message: strings.get("Il documento selezionato è troppo grande"), severity: "warning", title: strings.get("Attenzione") }
                                showAlert(al);
                            }
                        }
                    }} type="file" className="absolute opacity-0 top-0 left-0 bottom-0 right-0"

                        accept=".jpeg,.png,image/jpeg,image/png"
                    />
                </button> : <button onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const d = { ...props.datas };
                    delete d.picture;
                    props.onChange(d);

                }} className="btn cursor-pointer flex items-center text-center justify-between relative mt-5 rounded-sm w-full border border-antoniazzi-900 bg-white text-antoniazzi-900 hover:bg-antoniazzi-900 hover:text-white">{humanFileSize(props.datas.picture.size) + " " + props.datas.picture.name + " "} <span className="mr-2 rounded-full p-0.5 bg-bordeaux text-white"><Icon name="minus" className="w-3 h-3" /></span>

                </button>}

            </div> : <></>}

        </> : <></>}


    </div>
}


function SceltaCandelina(props: { accessorio: AccessoryType, value?: SelectedAccessoryType, onChange?: (data?: SelectedAccessoryType) => void }) {
    const { strings } = useFEContext();
    let isSelected = props.value !== undefined;
    const [value, setValue] = useState<SelectedAccessoryType>();
    const update = (v?: SelectedAccessoryType) => {
        setValue(v);
        if (props.onChange) {
            props.onChange(v)
        }
    }
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const accessorio = props.accessorio;
    const conf = accessorio.customConfig as AccessoryConfigType;
    const newValue: () => SelectedAccessoryType = function () {
        let price = 0;
        if (conf.allowCustomStandardPrice) {
            price = conf.customStandardPrice;
        } else {
            price = accessorio.accessory ? parseFloat(accessorio.accessory.priceStandard || "0") : 0;
        }
        const v: SelectedAccessoryType = { accessory: props.accessorio, price: price, quantity: conf.quantitySuggested || 1 }
        if (!conf.allowCustomQuantity) {
            v.quantity = conf.quantitySuggested;
        }
        v.description = props.accessorio.accessory?.config.accessoryType + " " + props.accessorio.accessory?.title;
        return v;
    }
    let ima: string | undefined = undefined;


    if (props.accessorio.accessory && props.accessorio.accessory.images && props.accessorio.accessory.images.length > 0) {
        const image = props.accessorio.accessory.images[0];
        if (image.imageSrcs)
            ima = image.imageSrcs.small;
    }

    const candelaCifra = accessorio.accessory?.config.accessoryType === 'Candela cifra';

    return <div className="flex items-center mb-7">
        <div className="mr-5"> <AntoCheckbox checked={isSelected} onChange={(checked) => {
            let v = value;
            if (!isSelected) {
                if (!v) {
                    v = newValue();
                }
            } else {
                v = undefined;
            }
            update(v);
        }}></AntoCheckbox></div>
        {ima ? <img className="w-[91px] grow-0 shrink-0 block mr-12" src={ima} /> : <div className="w-[91px] grow-0 shrink-0 h-[91px] block bg-antoniazzi-600 mr-12"></div>}
        <div>
            <h5 className="text-h4 md:text-md-h4 mb-1 font-bold">{props.accessorio.accessory?.title}</h5>
            <div className="text-h5 md:text-md-h5 mb-2"> {candelaCifra ? strings.get("Età") : strings.get("Quantità")}</div>
            {!candelaCifra ? <input disabled={!conf.allowCustomQuantity} className="text-p lg:text-md-p px-2.5 py-3" value={props.value?.quantity || ""} onChange={(ev) => {
                let v = value;
                if (!v) {
                    v = newValue();
                }
                if (v) v.quantity = ev.target.valueAsNumber;
                update(v);
            }} type="number" inputMode="numeric" step="0" min="1" max="101" /> : <></>}
            {candelaCifra ? <input className="text-p lg:text-md-p border-antoniazzi-900 px-2.5 py-3" value={props.value?.age || ""} onChange={(ev) => {
                let v = value;
                if (!v) {
                    v = newValue();
                }
                if (v) {
                    v.age = ev.target.valueAsNumber;
                    if (ev.target.valueAsNumber >= 0 && ev.target.valueAsNumber < 10) {
                        v.quantity = 1;
                    }
                    if (ev.target.valueAsNumber >= 10 && ev.target.valueAsNumber < 100) {
                        v.quantity = 2;
                    }
                    if (ev.target.valueAsNumber >= 100) {
                        v.quantity = 3;
                    }
                    v.description = props.accessorio.accessory?.config.accessoryType + " " + props.accessorio.accessory?.title + " (" + ev.target.value + ")";
                }
                update(v);
            }} type="number" inputMode="numeric" step="0" min="0" max="120" /> : <></>}
        </div>
        <div className="ml-auto text-h4 md:text-md-h4 self-start">{conf.allowCustomStandardPrice ? formatPrice(conf.customStandardPrice) : formatPrice(accessorio.accessory?.priceStandard)}</div>
    </div>
}

function SceltaScritta(props: { accessorio: AccessoryType, value?: SelectedAccessoryType, onChange?: (data?: SelectedAccessoryType) => void }) {
    const { strings } = useFEContext();
    let isSelected = props.value !== undefined;
    const [value, setValue] = useState<SelectedAccessoryType>();
    const update = (v?: SelectedAccessoryType) => {
        setValue(v);
        if (props.onChange) {
            props.onChange(v)
        }
    }
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const accessorio = props.accessorio;
    const confAccessorio = accessorio.accessory?.config;
    const conf = accessorio.customConfig as AccessoryConfigType;
    const newValue: () => SelectedAccessoryType = function () {
        let price = 0;
        if (conf.allowCustomStandardPrice) {
            price = conf.customStandardPrice;
        } else {
            price = accessorio.accessory ? parseFloat(accessorio.accessory.priceStandard || "0") : 0;
        }
        const v: SelectedAccessoryType = { accessory: props.accessorio, price: price, quantity: conf.quantitySuggested || 1 }
        if (!conf.allowCustomQuantity) {
            v.quantity = conf.quantitySuggested;
        }
        v.description = props.accessorio.accessory?.config.accessoryType + " " + props.accessorio.accessory?.title;
        if (!v.quantity) v.quantity = 1;
        return v;
    }


    const selectValues = confAccessorio.accessoryValues ? confAccessorio.accessoryValues.split("\n") : [];

    return <div className=" mb-7">
        <div className="flex items-center mb-4">
            <div className="mr-5"><AntoRadio checked={isSelected} onChange={(sel) => {
                let v = value;
                if (!isSelected) {
                    if (!v) {
                        v = newValue();
                    }
                } else {
                    v = undefined;
                }
                update(v);
            }} /></div>
            <h5 className="text-h4 md:text-md-h4 mb-1">{props.accessorio.accessory?.title}</h5>
        </div>
        {isSelected ? <>
            {selectValues && selectValues.length > 0 ? <>

                <select className="form-control-alt !p-4 !text-p !lg:text-md-p" onChange={(ev) => {
                    let v = value;
                    if (!isSelected) {
                        if (!v) {
                            v = newValue();
                        }
                    }
                    if (v) {
                        v.customText = ev.target.value;
                        v.description = props.accessorio.accessory?.config.accessoryType + " " + props.accessorio.accessory?.title + " '" + v.customText + "'";
                    }
                    update(v);
                }} value={value?.customText}>
                    <option value="">{strings.get("Seleziona")}</option>
                    {selectValues.map((scritta, scrittaind) => {
                        return <option key={scrittaind} value={scritta}>{scritta}</option>
                    })}
                </select>
            </> : <>
                <input placeholder={strings.get("Inserire testo (max 50 caratteri)")} type="text" value={value?.customText} onChange={(ev) => {
                    let v = value;
                    if (!isSelected) {
                        if (!v) {
                            v = newValue();
                        }
                    }
                    if (v) {
                        v.customText = ev.target.value;
                        v.description = props.accessorio.accessory?.config.accessoryType + " " + props.accessorio.accessory?.title + " '" + v.customText + "'";
                    }
                    update(v);
                }} className="form-control-alt !p-4 !text-p !lg:text-md-p" />
            </>}
        </> : <></>}

    </div>;
}

function SceltaFotografia(props: { accessorio: AccessoryType, value?: SelectedAccessoryType, onChange?: (data?: SelectedAccessoryType) => void }) {
    let isSelected = props.value !== undefined;
    const [value, setValue] = useState<SelectedAccessoryType>();
    const update = (v?: SelectedAccessoryType) => {
        setValue(v);
        if (props.onChange) {
            props.onChange(v)
        }
    }
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const accessorio = props.accessorio;
    const conf = accessorio.customConfig as AccessoryConfigType;
    let price = 0;
    if (conf.allowCustomStandardPrice) {
        price = conf.customStandardPrice;
    } else {
        price = accessorio.accessory ? parseFloat(accessorio.accessory.priceStandard || "0") : 0;
    }
    const newValue: () => SelectedAccessoryType = function () {
        const v: SelectedAccessoryType = { accessory: props.accessorio, price: price, quantity: conf.quantitySuggested || 1 }
        if (!conf.allowCustomQuantity) {
            v.quantity = conf.quantitySuggested;
        }
        if (!v.quantity) v.quantity = 1;
        v.description = props.accessorio.accessory?.title;
        return v;
    }
    const onSelect = () => {
        let v = value;
        if (!isSelected) {
            if (!v) {
                v = newValue();
            }
        } else {
            v = undefined;
        }
        update(v);
    };
    return <button onClick={onSelect} className="flex items-center w-full text-h4 md:text-md-h4 mb-7">
        <div className="mr-5"><AntoRadio checked={isSelected} onChange={onSelect} /></div>
        <h5 className="mb-1">{props.accessorio.accessory?.title}</h5>
        <div className="ml-auto">{formatPrice(price)}</div>
    </button>;
}

function AntoCheckbox(props: { checked: boolean, onChange: (boo: boolean) => void, children?: any }) {
    return <button onClick={() => {
        props.onChange(!props.checked);
    }}><div className={classNames("btn-checkbox", props.checked ? "btn-checkbox-checked" : "")}></div>{props.children}</button>;
}
function AntoRadio(props: { checked: boolean, onChange: (boo: boolean) => void, children?: any }) {
    return <button onClick={() => {
        if (props.onChange) props.onChange(!props.checked);
    }}><div className={classNames("btn-radio", props.checked ? "btn-radio-checked" : "")}></div>{props.children}</button>;
}
import React, { useEffect, useState } from "react";
import { ContainerRefType, ContainerType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";

export default function Multimedia(props: { loading?: "lazy" | "eager", containerRef?: ContainerRefType, container?: ContainerType, className?: string, small?: boolean, medium?: boolean, large?: boolean }) {
    const [container, setContainer] = useState<ContainerType>();
    const [desktopContainer, setDesktopContainer] = useState<ContainerRefType>();
    const { dataBackend } = useFEContext();
    const searchDesktopContainer = (ctn: ContainerType) => {
        if (ctn) {
            const _images = ctn.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT");
            if (_images && _images?.length > 0) {
                setDesktopContainer(_images[0]);
            }
        }
    }
    useEffect(() => {
        if (props.container) {
            setContainer(props.container);
            searchDesktopContainer(props.container);
        }
    }, [props.container]);
    useEffect(() => {
        if (props.containerRef) {
            dataBackend.get(props.containerRef).then(r => {
                if (!r.error) {
                    setContainer(r.records[0]);
                    searchDesktopContainer(r.records[0]);
                }
            })
        }
    }, [props.containerRef]);
    let src = container?.imageSrcs?.small;
    let videoSrcs = container?.videoSrcs;
    if (props.medium) src = container?.imageSrcs?.medium;
    if (props.large) src = container?.imageSrcs?.large;
    let desktopSrc: string | undefined = undefined;
    let desktopVideoSrcs: any | undefined = undefined;
    if (desktopContainer) {
        desktopSrc = desktopContainer?.imageSrcs?.small;
        if (props.medium) desktopSrc = desktopContainer?.imageSrcs?.medium;
        if (props.large) desktopSrc = desktopContainer?.imageSrcs?.large;
        if (desktopContainer.videoSrcs) desktopVideoSrcs = desktopContainer.videoSrcs;
    }
    const hasDesktopAlernative = desktopContainer !== undefined;

    const isImage = !container?.embed && !container?.videoSrcs;

    return <>
        {videoSrcs ? <>
            <video autoPlay muted loop playsInline className={classNames(desktopVideoSrcs ? "lg:hidden" : "")}><source src={videoSrcs.mp4} type="video/mp4" /></video>
            {desktopVideoSrcs ? <video autoPlay muted loop playsInline className={classNames(desktopVideoSrcs ? "hidden lg:block" : "")}><source src={desktopVideoSrcs.mp4} type="video/mp4" /></video> : <></>}
        </>

            : <></>}



        {container?.embed ? <div className={classNames(props.className, container.className)} dangerouslySetInnerHTML={{ __html: container.description || "" }} /> : <></>}
        {isImage ? <>
            <img src={src} alt={container?.title} loading={props.loading ? props.loading : "eager"} className={classNames(props.className, hasDesktopAlernative ? "lg:hidden" : "")} />
            {desktopSrc ? <img src={desktopSrc} alt={container?.title} loading={props.loading ? props.loading : "eager"} className={classNames(props.className, hasDesktopAlernative ? "hidden lg:block" : "")} /> : <></>}
        </> : <></>}
    </>;
}
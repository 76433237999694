import React, { useEffect, useState } from "react";
import { ContainerType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import { Link } from "react-router-dom";
import { Icon } from "../../IconLib";
export default function NavSiblings(props: { container?: ContainerType }) {
    const [container, setContainer] = useState<ContainerType>();
    const [next, setNext] = useState<ContainerType>();
    const [prev, setPrev] = useState<ContainerType>();

    const { dataBackend } = useFEContext();
    useEffect(() => {
        setPrev(undefined)
        setNext(undefined)
        if (props.container && props.container.container) {
            dataBackend.getContainer(props.container.container.id).then(c => {
                setContainer(c);
                if (c && c.container) {
                    dataBackend.getContainer(c.container.id).then(c2 => {
                        if (c2) {
                            let _items: ContainerType[] | undefined = [];
                            _items = c2.containers?.filter(cc => cc.containerClass === c.containerClass && cc.position === c.position);
                            if (_items) {
                                let indexofC = 0;
                                for (let index = 0; index < _items?.length; index++) {
                                    const element = _items[index];
                                    if (element.id === c.id) {
                                        indexofC = index;
                                        break;
                                    }
                                }
                                if (indexofC > 0 && _items.length > 0)
                                    setPrev(_items[indexofC - 1]);
                                if (indexofC < _items.length)
                                    setNext(_items[indexofC + 1]);
                            }
                        }
                    })
                }
            })
        }
    }, [props.container])
    return <>{container ? <section data-container-id={container.id} className="nav-siblings flex items-center justify-between max-w-5xl mx-auto py-8">
        {prev ? <Link className="inline-flex items-center underline text-antoniazzi-600" title={prev.title} to={prev?.routeTo || "/"}><Icon name="chevron" className="w-5 h-5 rotate-180" /> Precedente</Link> : <div></div>}
        {next ? <Link className="inline-flex items-center underline text-antoniazzi-600" title={next.title} to={next?.routeTo || "/"}>Successivo <Icon name="chevron" className="w-5 h-5" /> </Link> : <div></div>}
    </section> : <></>}</>;
}
import React, { useCallback, useEffect, useState } from "react";
import { useFEContext } from "../FEContext";
import { Link, useNavigate } from "react-router-dom";
import { AlertType, SessionType, UserType } from "../../Interfaces";
import classNames from "classnames";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function LoginRegistrationPanel(props: { destinationAfterLogin?: string }) {
    const { user, updateSession, showPowerLayer, showAlert, strings, dataBackend, cart } = useFEContext();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");

    const nav = useNavigate();
    const [newUser, setNewUser] = useState<UserType>({
        indirizzoPrincipale: {},
        altriIndirizzi: []
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const login = (ev?: any) => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (username && password && username.length > 4 && password.length > 8) {
            const opts = {
                method: "POST",
                body: JSON.stringify({ email: username, password: password, recaptcha: recaptchaToken })
            }
            if (window.localStorage.getItem("xtstoredsession")) {
                const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
                //@ts-ignore
                opts.headers = headers;
            }
            fetch(dataBackend?.endpointBase + "/store/login", opts).then((resp) => resp.json()).then((payload) => {
                if (!payload.error) {
                    updateSession(payload);
                    showPowerLayer({ active: false });
                    if (cart) {
                        window.location.hash = "checkout";
                    }
                    if (props.destinationAfterLogin) {
                        nav(props.destinationAfterLogin);
                    }
                } else {
                    const al: AlertType = { active: true, message: payload.message, title: strings.get("Errore accesso"), severity: "error" }
                    showAlert(al);
                }


            }).catch(err => console.error(err));
        } else {
            const al: AlertType = { active: true, message: strings.get("Non hai digitato correttamente username e password"), title: strings.get("Errore accesso"), severity: "error" }
            showAlert(al);
        }
    };

    const validateData = () => {
        const al: AlertType = { active: true, title: strings.get("Errore di compilazione"), severity: "warning" };
        if (!newUser.nome) {
            al.message = strings.get("Nome obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.cognome) {
            al.message = strings.get("Cognome obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.codiceFiscale) {
            al.message = strings.get("Codice fiscale obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.telefono) {
            al.message = strings.get("Devi indicare il telefono");
            showAlert(al);
            return false;
        }
        if (!newUser.email) {
            al.message = strings.get("Indirizzo obbligatorio email");
            showAlert(al);
            return false;
        }
        if (!newUser.indirizzoPrincipale.paese) {
            al.message = strings.get("Paese obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.indirizzoPrincipale.indirizzo) {
            al.message = strings.get("Indirizzo obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.indirizzoPrincipale.cap) {
            al.message = strings.get("Cap obbligatorio");
            showAlert(al);
            return false;
        }
        if (!newUser.indirizzoPrincipale.citta) {
            al.message = strings.get("Città obbligatoria");
            showAlert(al);
            return false;
        }
        if (!newUser.indirizzoPrincipale.provincia) {
            al.message = strings.get("Provincia obbligatoria");
            showAlert(al);
            return false;
        }
        if (!newUser.privacy) {
            al.message = strings.get("Accettare le condizioni di privacy");
            showAlert(al);
            return false;
        }
        if (newUser.azienda) {
            if (!newUser.ragioneSociale) {
                al.message = strings.get("Ragione sociale obbligatoria");
                showAlert(al);
                return false;
            }
            if (newUser.indirizzoPrincipale.paese === 'it' || newUser.indirizzoPrincipale.paese === 'it') {
                if (!newUser.codiceDestinatario && !newUser.pec) {
                    al.message = strings.get("PEC o Codice Destinatario per le fatture elettroniche obbligatorio");
                    showAlert(al);
                    return false;
                }
            }
        }
        if (newUser.spedizioneDiversa) {
            const indirizzo = newUser.altriIndirizzi[0];
            if (!indirizzo.presso) {
                al.message = strings.get("Devi dare indicazione sulla destinazione");
                showAlert(al);
                return false;
            }
            if (!indirizzo.paese) {
                al.message = strings.get("Paese obbligatorio della destinazione");
                showAlert(al);
                return false;
            }
            if (!indirizzo.indirizzo) {
                al.message = strings.get("Indirizzo obbligatorio della destinazione");
                showAlert(al);
                return false;
            }
            if (!indirizzo.cap) {
                al.message = strings.get("Cap obbligatorio della destinazione");
                showAlert(al);
                return false;
            }
            if (!indirizzo.citta) {
                al.message = strings.get("Città obbligatoria della destinazione");
                showAlert(al);
                return false;
            }
            if (!indirizzo.provincia) {
                al.message = strings.get("Provincia obbligatoria della destinazione");
                showAlert(al);
                return false;
            }

        }
        return true;
    }

    const processPayload = (payload: any) => {
        if (payload.error) {
            const al: AlertType = { active: true, message: payload.message || strings.get("Si è verificato un errore, prova più tardi"), title: strings.get("Errore registrazione"), severity: "error" }
            showAlert(al);
            return;
        }
        const s: SessionType = payload as SessionType;
        //console.log(payload);
        if (s.user) {
            if (!s.user.confermato && !s.user.ospite) {
                const al: AlertType = { active: true, message: payload.message || strings.get("Registrazione confermata"), title: strings.get("Conferma registrazione"), severity: "success" }
                showAlert(al);
                if (payload.routeTo) {
                    nav(payload.routeTo);
                    showPowerLayer({ active: false })
                } else {
                    showPowerLayer({ active: false })
                }

            }
            else if (s.user.confermato && s.user.ospite) {
                const al: AlertType = { active: true, message: payload.message || strings.get("Registrazione confermata"), title: strings.get("Conferma dati"), severity: "success" }
                showAlert(al);
                showPowerLayer({ active: false })
            }
        }
        updateSession(payload);
    }

    const continuaComeOspite = async () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (validateData()) {
            const pl: any = { ...newUser };
            pl.recaptcha = recaptchaToken;
            const opts = {
                method: "POST",
                body: JSON.stringify(pl)
            }
            if (window.localStorage.getItem("xtstoredsession")) {
                const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
                //@ts-ignore
                opts.headers = headers;
            }
            const resp = await fetch(dataBackend?.endpointBase + "/store/newGuest", opts);
            const payload = await resp.json();
            processPayload(payload);
        }

    }
    const creaAccount = async () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (validateData()) {
            const pl: any = { ...newUser };
            pl.recaptcha = recaptchaToken;
            const opts = {
                method: "POST",
                body: JSON.stringify(pl)
            }
            if (window.localStorage.getItem("xtstoredsession")) {
                const headers = { "x-xtstoredsession": window.localStorage.getItem("xtstoredsession") };
                //@ts-ignore
                opts.headers = headers;
            }
            const resp = await fetch(dataBackend?.endpointBase + "/store/newUser", opts);
            const payload = await resp.json();
            processPayload(payload);

        }

    }
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);
    const updateProvincia = (ev) => { const u: UserType = newUser ? { ...newUser } : { altriIndirizzi: [], indirizzoPrincipale: {} }; u.indirizzoPrincipale.provincia = ev.target.value; setNewUser(u) };


    const hasUser = user && (user.ospite || user.email);

    return (
        <div className="px-4 py-8 text-antoniazzi-900">
            {!hasUser ?
                <>



                    <h3 className="text-h1 md:text-md-h1 mb-6">{strings.get("Sei già registrato? Accedi")}</h3>
                    <div className="flex flex-col gap-y-4">
                        <input placeholder="Email" type="text" className="form-control-alt" value={username} onChange={(ev) => { setUsername(ev.target.value) }} />
                        <input placeholder="Password" type="password" className="form-control-alt" value={password} onChange={(ev) => { setPassword(ev.target.value) }} />
                        <Link onClick={() => { showPowerLayer({ active: false }) }} className="w-full block text-center text-antoniazzi-600 underline hover:no-underline hover:text-antoniazzi-900" to="/recupero-password">{strings.get("Password dimenticata?")}</Link>
                        <button onClick={login} className="btn  w-full bg-antoniazzi-900 hover:bg-antoniazzi-600 rounded-sm text-white block">{strings.get("Accedi")}</button>
                    </div>
                    <h3 className="text-h1 md:text-md-h1 mt-10 mb-4">{strings.get("Non hai un account? Registrati")}</h3>
                    <p className="text-p md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>
                    <div className="flex py-4 items-center">
                        <div className="mr-4">{strings.get("Sei un'azienda?")}</div>
                        <button className="px-4 items-center flex" onClick={() => {
                            const u = { ...newUser };
                            u.azienda = true;
                            setNewUser(u);
                        }}>
                            <div className={classNames("btn-radio mr-2", !newUser.azienda ? "" : "btn-radio-checked")}></div> {strings.get("Si")}</button>
                        <button className="px-4 items-center flex" onClick={() => {
                            const u = { ...newUser };
                            u.azienda = false;
                            setNewUser(u);
                        }}>
                            <div className={classNames("btn-radio mr-2", newUser.azienda ? "" : "btn-radio-checked")}></div> {strings.get("No")}</button>
                    </div>
                    <input type="text" className="form-control" required value={newUser.nome} onChange={(ev) => { const u = { ...newUser }; u.nome = ev.target.value; setNewUser(u) }} placeholder={!newUser.azienda ? strings.get("Nome") : strings.get("Nome referente") + "*"} />
                    <input type="text" className="form-control" required value={newUser.cognome} onChange={(ev) => { const u = { ...newUser }; u.cognome = ev.target.value; setNewUser(u) }} placeholder={!newUser.azienda ? strings.get("Cognome") : strings.get("Cognome referente") + "*"} />
                    {newUser.azienda ? <input type="text" className="form-control" required value={newUser.ragioneSociale} onChange={(ev) => { const u = { ...newUser }; u.ragioneSociale = ev.target.value; setNewUser(u) }} placeholder={strings.get("Ragione sociale") + "*"} /> : <></>}
                    {newUser.azienda ? <input type="text" className="form-control" required value={newUser.partitaIva} onChange={(ev) => { const u = { ...newUser }; u.partitaIva = ev.target.value; setNewUser(u) }} placeholder={strings.get("Partita IVA") + "*"} /> : <></>}
                    <input type="text" className="form-control" required value={newUser.codiceFiscale} onChange={(ev) => { const u = { ...newUser }; u.codiceFiscale = ev.target.value; setNewUser(u) }} placeholder={strings.get("Codice fiscale") + "*"} />
                    {newUser.azienda ? <>
                        <input type="text" className="form-control" value={newUser.codiceDestinatario} onChange={(ev) => { const u = { ...newUser }; u.codiceDestinatario = ev.target.value; setNewUser(u) }} placeholder={strings.get("Codice SDI")} />
                        <input type="text" className="form-control" value={newUser.pec} onChange={(ev) => { const u = { ...newUser }; u.pec = ev.target.value; setNewUser(u) }} placeholder={strings.get("PEC")} />
                    </> : <></>}
                    <input type="text" className="form-control" required value={newUser.telefono} onChange={(ev) => { const u = { ...newUser }; u.telefono = ev.target.value; setNewUser(u) }} placeholder={strings.get("Telefono") + "*"} />
                    <input type="text" className="form-control" required value={newUser.email} onChange={(ev) => { const u = { ...newUser }; u.email = ev.target.value; setNewUser(u) }} placeholder={strings.get("Email") + "*"} />
                    <select className="form-control" required value={newUser.indirizzoPrincipale.paese} onChange={(ev) => { const u: UserType = { ...newUser }; u.indirizzoPrincipale.paese = ev.target.value; setNewUser(u) }} placeholder={strings.get("Paese") + "*"}>
                        <option value="">{strings.get("Paese...")}</option>
                        {dataBackend.paesi.map((val, valk) => {
                            return <option key={valk} value={val.value}>{val.label}</option>;
                        })}</select>
                    <input type="text" className="form-control" required value={newUser.indirizzoPrincipale.indirizzo} onChange={(ev) => { const u: UserType = { ...newUser }; u.indirizzoPrincipale.indirizzo = ev.target.value; setNewUser(u) }} placeholder={strings.get("Indirizzo (Via e numero civico)") + "*"} />
                    <input type="text" className="form-control" required value={newUser.indirizzoPrincipale.cap} onChange={(ev) => { const u: UserType = { ...newUser }; u.indirizzoPrincipale.cap = ev.target.value; setNewUser(u) }} placeholder={strings.get("Codice postale") + "*"} />
                    <input type="text" className="form-control" required value={newUser.indirizzoPrincipale.citta} onChange={(ev) => { const u: UserType = { ...newUser }; u.indirizzoPrincipale.citta = ev.target.value; setNewUser(u) }} placeholder={strings.get("Città") + "*"} />
                    {newUser.indirizzoPrincipale.paese === 'IT' ? <select className="form-control" onChange={updateProvincia} value={newUser.indirizzoPrincipale.provincia}><option value="">{strings.get("Provincia...")}</option> {dataBackend.province.map((val, valk) => {
                        return <option key={valk} value={val.value}>{val.label}</option>
                    })}</select> : <input type="text" className="form-control !border-b" required value={newUser.indirizzoPrincipale.provincia} onChange={updateProvincia} placeholder={strings.get("Provincia") + "*"} />}

                    <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={() => {
                        const u = { ...newUser }; u.spedizioneDiversa = !u.spedizioneDiversa;
                        if (!u.spedizioneDiversa) {
                            u.altriIndirizzi = [];
                        } else {
                            u.altriIndirizzi.push({});
                        }
                        setNewUser(u)
                    }}>
                        <div className={classNames("btn-checkbox mr-2", newUser.spedizioneDiversa ? "" : "btn-checkbox-checked")} /> {strings.get("Coincide con l'indirizzo di spedizione")}
                    </button>

                    {newUser.spedizioneDiversa ? <>

                        {newUser.altriIndirizzi.map((ai, aik) => {
                            const updateCurrentProvincia = (ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].provincia = ev.target.value; setNewUser(u) };

                            return <div key={aik}>
                                <input type="text" className="form-control" required value={ai.presso} onChange={(ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].presso = ev.target.value; setNewUser(u) }} placeholder={strings.get("C/O presso") + "*"} />
                                <select className="form-control" required value={ai.paese} onChange={(ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].paese = ev.target.value; setNewUser(u) }} placeholder={strings.get("Paese") + "*"}>
                                    <option value="">{strings.get("Paese...")}</option>
                                    {dataBackend.paesi.map((val, valk) => {
                                        return <option key={valk} value={val.value}>{val.label}</option>;
                                    })}</select>                                <input type="text" className="form-control" required value={ai.indirizzo} onChange={(ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].indirizzo = ev.target.value; setNewUser(u) }} placeholder={strings.get("Indirizzo (Via e numero civico)") + "*"} />
                                <input type="text" className="form-control" required value={ai.cap} onChange={(ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].cap = ev.target.value; setNewUser(u) }} placeholder={strings.get("Codice postale") + "*"} />
                                <input type="text" className="form-control" required value={ai.citta} onChange={(ev) => { const u: UserType = { ...newUser }; u.altriIndirizzi[aik].citta = ev.target.value; setNewUser(u) }} placeholder={strings.get("Città") + "*"} />
                                {ai.paese === 'IT' ? <select className="form-control" onChange={updateCurrentProvincia} value={ai.provincia}><option value="">{strings.get("Provincia...")}</option>{dataBackend.province.map((val, valk) => {
                                    return <option key={valk} value={val.value}>{val.label}</option>
                                })}</select> : <input type="text" className="form-control" required value={ai.provincia} onChange={updateCurrentProvincia} placeholder={strings.get("Provincia") + "*"} />}
                            </div>
                        })}

                    </> : <></>}
                    <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...newUser }; u.privacy = !u.privacy; setNewUser(u) }}>
                        <div className={classNames("btn-checkbox mr-2", !newUser.privacy ? "" : "btn-checkbox-checked")} />
                        <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
                    </button>
                    <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
                    <div className="flex gap-4 flex-col md:flex-row">
                        <button className="btn block w-full bg-antoniazzi-900 hover:bg-antoniazzi-600 rounded-sm text-white" onClick={creaAccount}>{strings.get("Crea account")}</button>
                        {/*<button className="btn block w-full bg-antoniazzi-900 hover:bg-antoniazzi-600 rounded-sm text-white" onClick={continuaComeOspite}>{strings.get("Continua come ospite")}</button>*/}
                    </div>

                </> :
                <><Link onClick={() => { showPowerLayer({ active: false }) }} to="/profilo">{strings.get("Vai la profilo")}</Link></>}
        </div>
    );
}
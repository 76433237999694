import React, { useCallback, useEffect, useState } from "react";
import { AlertInline, useFEContext } from "../FEContext";
import classNames from "classnames";
import { AlertType } from "../../Interfaces";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import { Calendar } from "./POSCalendar";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Icon } from "../../IconLib";
dayjs.extend(customParseFormat)
interface DataPreventivoType {
    referente?: string
    email?: string
    telefono?: string
    fasciaContatto?: string
    tipologiaEvento?: "Wedding" | "Evento Aziendale" | "Evento Privato"
    dataMatrimonio?: string,
    nomeSposi?: string
    locationCerimonia?: string
    location?: string
    numeroOspiti?: string
    orarioEvento?: string
    serviziFoodRichiesti?: string[]
    intrattenimentoDopoPasto?: string[]
    weddingPlanner?: string
    stileEvento?: string
    serviziGrafica?: string[]
    budgetStimato?: string
    svolgimentoEvento?: string
    motivoEvento?: string
    note?: string,
    nomeAzienda?: string
    recaptcha?: string
    privacy?: boolean

}

const initData: DataPreventivoType = {
    privacy: false,
    referente: "",
    email: "",
    telefono: "",
    fasciaContatto: "",
    dataMatrimonio: "",
    nomeSposi: "",
    locationCerimonia: "",
    location: "",
    numeroOspiti: "",
    orarioEvento: "",
    weddingPlanner: "",
    stileEvento: "",
    budgetStimato: "",
    svolgimentoEvento: "",
    motivoEvento: "",
    note: "",
    nomeAzienda: "",
    recaptcha: ""
}
const tipoEventi = ["Wedding", "Evento Aziendale", "Evento Privato"];
const serviziFoodWedding = ["Pranzo", "Cena"];
const serviziFoodEventi = ["Pranzo", "Cena", "Aperitivo", "Coffee break"];
const intrattenimentoDopoCena = ["Open Bar", "Food"];
const serviziGrafica = ["Partecipazioni", "Menù", "Segnaposto", "Tableau Mariage"];
const budgetsMatrimonio = ["< 200 €"];
const budgetsEvento = ["< 200 €"];
export default function Preventivo() {
    const [currentAlert, setCurrentAlert] = useState<AlertType>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const { strings, user, dataBackend, showAlert } = useFEContext();
    const [data, setData] = useState<DataPreventivoType>({});
    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const dd = { ...data };
            dd[name] = ev.target.value;
            setData(dd);
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const inviaRichiestaPreventivo = (ev) => {
        setRefreshReCaptcha(new Date().getTime() + "");
        ev.preventDefault();
        ev.stopPropagation();
        const al: AlertType = { active: true };
        al.onCancel = () => { setCurrentAlert(undefined) };
        if (!data.privacy)
            al.message = strings.get("Accettare le condizioni di privacy")
        if (!data.tipologiaEvento)
            al.message = strings.get("Tipologia di evento obbligatorio")
        else if (!data.referente)
            al.message = strings.get("Referente obbligatorio")
        else if (!data.email)
            al.message = strings.get("Email obbligatoria")
        else if (!data.telefono)
            al.message = strings.get("Telefono obbligatorio")
        else if (!data.fasciaContatto)
            al.message = strings.get("Fascia oraria per essere ricontattati obbligatoria")
        else if (!data.numeroOspiti)
            al.message = strings.get("Numero ospiti obbligatorio")
        else if (!recaptchaToken)
            al.message = strings.get("Recaptcha obbligatorio")
        else if (!data.budgetStimato)
            al.message = strings.get("Budget stimato obbligatorio")
        if (!al.message) {
            const dd = { ...data };
            dd.recaptcha = recaptchaToken;
            fetch(dataBackend.endpointBase + "/wa/ToolsAction/preventivo", {
                method: "POST",
                body: JSON.stringify(dd)
            }).then(d => d.json()).then((r: any) => {
                if (r.error) {
                    al.severity = 'error';
                } else {
                    al.severity = 'success';
                    setData(initData);
                }
                al.message = r.message || strings.get("Si è verificato un errore")
                setCurrentAlert(al);
            })
        } else {
            al.severity = "error";
            setCurrentAlert(al);
        }
        return false;
    }
    useEffect(() => {
        if (user?.confermato) {
            const d = { ...data };
            if (user.email)
                d.email = user?.email;
            if (user.nome && user.cognome)
                d.referente = user?.nome + " " + user?.cognome;
            if (user.nome && user.telefono)
                d.telefono = user?.telefono;
            setData(d);
        }
    }, [user])
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);
    return <>
        <div className="text-antoniazzi-900 px-4 pb-16">
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
            <h3 className="text-h1 md:text-md-h1 mt-10 mb-4">{strings.get("Richiesta di Preventivo")}</h3>
            <p className="text-p-sm md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>
            <form onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                <div className="form-floating">
                    <input onChange={onChange} value={data.referente} placeholder="a" name="referente" type="text" required className="form-floating-control" />
                    <label htmlFor="referente">{strings.get("Nome referente") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.email} name="email" type="email" required className="form-floating-control" placeholder={strings.get("Email") + "*"} />
                    <label htmlFor="email">{strings.get("Email") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.telefono} name="telefono" type="tel" required className="form-floating-control" placeholder={strings.get("Telefono") + "*"} />
                    <label htmlFor="telefono">{strings.get("Telefono") + "*"}</label>
                </div>
                <div className="form-floating">
                    <select required onChange={onChange} value={data.fasciaContatto} name="fasciaContatto" className="form-floating-control">
                        <option value="">Seleziona...</option>
                        <option value="dalle 9:00 alle 12:00">{strings.get("dalle 9:00 alle 12:00")}</option>
                        <option value="dalle 12:00 alle 16:00">{strings.get("dalle 12:00 alle 16:00")}</option>
                        <option value="dalle 16:00 alle 19:00">{strings.get("dalle 16:00 alle 19:00")}</option>
                    </select>
                    <label htmlFor="fasciaContatto">{strings.get("Fascia oraria per essere ricontattati") + "*"}</label>
                </div>

                <div className="pt-2 border-t border-antoniazzi-900">
                    <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Tipologia di evento") + "*"}</div>
                    <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                        {tipoEventi.map((tp, tpl) => {
                            return <button className="flex items-center" onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                const dd = { ...data };
                                //@ts-ignore
                                dd.tipologiaEvento = tp;
                                setData(dd);
                                return false;
                            }} key={tpl}>
                                <div className={classNames("btn-radio mr-3", tp === data.tipologiaEvento ? "btn-radio-checked" : "")}></div> {strings.get(tp)}
                            </button>
                        })}</div>
                </div>
                {data.tipologiaEvento === 'Wedding' ? <Wedding data={data} onChange={(d) => { setData(d) }} /> : <></>}
                {data.tipologiaEvento === 'Evento Aziendale' || data.tipologiaEvento === 'Evento Privato' ? <EventoPrivatoAziendale data={data} onChange={(d) => { setData(d) }} /> : <></>}

                <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...data }; u.privacy = !u.privacy; setData(u) }}>
                    <div className={classNames("btn-checkbox mr-2", !data.privacy ? "" : "btn-checkbox-checked")} />
                    <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
                </button>
                {currentAlert ? <AlertInline alert={currentAlert} /> : <></>}
                <button onClick={inviaRichiestaPreventivo} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Invia la richiesta")}</button>
            </form>
        </div>
    </>;
}

export function PreventivoSemplice() {
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const { strings, user, dataBackend, showAlert } = useFEContext();
    const [currentAlert, setCurrentAlert] = useState<AlertType>();
    const [data, setData] = useState<DataPreventivoType>({});
    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const dd = { ...data };
            dd[name] = ev.target.value;
            setData(dd);
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const inviaRichiestaPreventivo = (ev) => {
        setRefreshReCaptcha(new Date().getTime() + "");
        ev.preventDefault();
        ev.stopPropagation();
        const al: AlertType = { active: true };
        al.onCancel = () => { setCurrentAlert(undefined) };
        if (!data.privacy)
            al.message = strings.get("Accettare le condizioni di privacy")
        if (!data.referente)
            al.message = strings.get("Referente obbligatorio")
        else if (!data.email)
            al.message = strings.get("Email obbligatoria")
        else if (!data.telefono)
            al.message = strings.get("Telefono obbligatorio")
        else if (!data.fasciaContatto)
            al.message = strings.get("Fascia oraria per essere ricontattati obbligatoria")
        else if (!recaptchaToken)
            al.message = strings.get("Recaptcha obbligatorio")
        if (!al.message) {
            const dd = { ...data };
            dd.recaptcha = recaptchaToken;
            fetch(dataBackend.endpointBase + "/wa/ToolsAction/preventivo", {
                method: "POST",
                body: JSON.stringify(dd)
            }).then(d => d.json()).then((r: any) => {
                if (r.error) {
                    al.severity = 'error';
                } else {
                    al.severity = 'success';
                    setData(initData);
                }
                al.message = r.message || strings.get("Si è verificato un errore")
                //showAlert(al);
                setCurrentAlert(al);
            })
        } else {
            al.severity = "error";
            setCurrentAlert(al);
            //showAlert(al);
        }
        return false;
    }
    useEffect(() => {
        if (user?.confermato) {
            const d = { ...data };
            if (user.email)
                d.email = user?.email;
            if (user.nome && user.cognome)
                d.referente = user?.nome + " " + user?.cognome;
            if (user.nome && user.telefono)
                d.telefono = user?.telefono;
            setData(d);
        }
    }, [user])
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);
    return <>
        <div className="text-antoniazzi-900 px-4 pb-16">
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
            <h3 className="text-h1 md:text-md-h1 mt-10 mb-4">{strings.get("Richiesta di Preventivo")}</h3>
            <p className="text-p-sm md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>
            <form onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                <div className="form-floating">
                    <input onChange={onChange} value={data.referente} placeholder="a" name="referente" type="text" required className="form-floating-control" />
                    <label htmlFor="referente">{strings.get("Nome referente") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.email} name="email" type="email" required className="form-floating-control" placeholder={strings.get("Email") + "*"} />
                    <label htmlFor="email">{strings.get("Email") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.telefono} name="telefono" type="tel" required className="form-floating-control" placeholder={strings.get("Telefono") + "*"} />
                    <label htmlFor="telefono">{strings.get("Telefono") + "*"}</label>
                </div>
                <div className="form-floating">
                    <select required  onChange={onChange} value={data.fasciaContatto} name="fasciaContatto" className="form-floating-control">
                        <option value="">Seleziona...</option>
                        <option value="dalle 9:00 alle 12:00">{strings.get("dalle 9:00 alle 12:00")}</option>
                        <option value="dalle 12:00 alle 16:00">{strings.get("dalle 12:00 alle 16:00")}</option>
                        <option value="dalle 16:00 alle 19:00">{strings.get("dalle 16:00 alle 19:00")}</option>
                    </select>
                    <label htmlFor="fasciaContatto">{strings.get("Fascia oraria per essere ricontattati") + "*"}</label>
                </div>
                <div className="pt-2">
                    <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Richiesta")}</div>
                    <textarea className="mt-2 block border-antoniazzi-900 w-full" name="note" value={data.note} onChange={onChange} />
                </div>

                <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...data }; u.privacy = !u.privacy; setData(u) }}>
                    <div className={classNames("btn-checkbox mr-2", !data.privacy ? "" : "btn-checkbox-checked")} />
                    <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
                </button>
                {currentAlert ? <AlertInline alert={currentAlert} /> : <></>}
                <button onClick={inviaRichiestaPreventivo} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Invia la richiesta")}</button>
            </form>
        </div>
    </>;
}

export function RichiestaInformazioni() {
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const { strings, user, dataBackend, showAlert } = useFEContext();
    const [currentAlert, setCurrentAlert] = useState<AlertType>();
    const [data, setData] = useState<DataPreventivoType>({});
    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const dd = { ...data };
            dd[name] = ev.target.value;
            setData(dd);
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const invia = (ev) => {
        setRefreshReCaptcha(new Date().getTime() + "");
        ev.preventDefault();
        ev.stopPropagation();
        const al: AlertType = { active: true };
        al.onCancel = () => { setCurrentAlert(undefined) };
        if (!data.privacy)
            al.message = strings.get("Accettare le condizioni di privacy")
        if (!data.referente)
            al.message = strings.get("Referente obbligatorio")
        else if (!data.email)
            al.message = strings.get("Email obbligatoria")
        else if (!data.telefono)
            al.message = strings.get("Telefono obbligatorio")
        else if (!data.fasciaContatto)
            al.message = strings.get("Fascia oraria per essere ricontattati obbligatoria")
        else if (!recaptchaToken)
            al.message = strings.get("Recaptcha obbligatorio")
        if (!al.message) {
            const dd = { ...data };
            dd.recaptcha = recaptchaToken;
            fetch(dataBackend.endpointBase + "/wa/ToolsAction/informazioni", {
                method: "POST",
                body: JSON.stringify(dd)
            }).then(d => d.json()).then((r: any) => {
                if (r.error) {
                    al.severity = 'error';
                } else {
                    al.severity = 'success';
                    setData(initData);
                }
                al.message = r.message || strings.get("Si è verificato un errore")
                //showAlert(al);
                setCurrentAlert(al);
            })
        } else {
            al.severity = "error";
            setCurrentAlert(al);
            //showAlert(al);
        }
        return false;
    }
    useEffect(() => {
        if (user?.confermato) {
            const d = { ...data };
            if (user.email)
                d.email = user?.email;
            if (user.nome && user.cognome)
                d.referente = user?.nome + " " + user?.cognome;
            if (user.nome && user.telefono)
                d.telefono = user?.telefono;
            setData(d);
        }
    }, [user])
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);
    return <>
        <div className="text-antoniazzi-900 px-4 pb-16">
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
            <h3 className="text-h1 md:text-md-h1 mt-10 mb-4">{strings.get("Richiesta informazioni")}</h3>
            <p className="text-p-sm md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>
            <form onSubmit={(ev) => { ev.preventDefault(); return false; }}>
                <div className="form-floating">
                    <input onChange={onChange} value={data.referente} placeholder="a" name="referente" type="text" required className="form-floating-control" />
                    <label htmlFor="referente">{strings.get("Nome referente") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.email} name="email" type="email" required className="form-floating-control" placeholder={strings.get("Email") + "*"} />
                    <label htmlFor="email">{strings.get("Email") + "*"}</label>
                </div>
                <div className="form-floating">
                    <input onChange={onChange} value={data.telefono} name="telefono" type="tel" required className="form-floating-control" placeholder={strings.get("Telefono") + "*"} />
                    <label htmlFor="telefono">{strings.get("Telefono") + "*"}</label>
                </div>
                <div className="form-floating">
                    <select required onChange={onChange} value={data.fasciaContatto} name="fasciaContatto" className="form-floating-control">
                        <option value="">Seleziona...</option>
                        <option value="dalle 9:00 alle 12:00">{strings.get("dalle 9:00 alle 12:00")}</option>
                        <option value="dalle 12:00 alle 16:00">{strings.get("dalle 12:00 alle 16:00")}</option>
                        <option value="dalle 16:00 alle 19:00">{strings.get("dalle 16:00 alle 19:00")}</option>
                    </select>
                    <label htmlFor="fasciaContatto">{strings.get("Fascia oraria per essere ricontattati") + "*"}</label>
                </div>
                <div className="pt-2">
                    <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Richiesta")}</div>
                    <textarea className="mt-2 block border-antoniazzi-900 w-full" name="note" value={data.note} onChange={onChange} />
                </div>

                <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...data }; u.privacy = !u.privacy; setData(u) }}>
                    <div className={classNames("btn-checkbox mr-2", !data.privacy ? "" : "btn-checkbox-checked")} />
                    <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
                </button>
                {currentAlert ? <AlertInline alert={currentAlert} /> : <></>}
                <button onClick={invia} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Invia la richiesta")}</button>
            </form>
        </div>
    </>;
}
function Wedding(props: { onChange: (d: DataPreventivoType) => void, data: DataPreventivoType }) {
    const { strings } = useFEContext();
    const [showCalendar, setShowCalendar] = useState(false);

    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const d = { ...props.data };
            d[name] = ev.target.value;
            props.onChange(d);
        }
    }
    const dataMatrimonio = props.data.dataMatrimonio && props.data.dataMatrimonio.length > 0 ? dayjs(props.data.dataMatrimonio, "DD/MM/YYYY") : dayjs();


    return <>
        <div className="form-floating relative">
            <a className="absolute top-0 left-0 right-0 bottom-0 flex items-center" onClick={() => setShowCalendar(!showCalendar)}><Icon name='chevron' className={classNames('ml-auto w-6 h-6 mr-2', showCalendar ? "rotate-90" : "")} /></a>
            <input readOnly name="dataMatrimonio" value={props.data.dataMatrimonio} type="text" required className="form-floating-control" placeholder={strings.get("Data Matrimonio") + "*"} />
            <label htmlFor="dataMatrimonio">{strings.get("Data Matrimonio") + "*"}</label>
        </div>
        {showCalendar && <Calendar value={dataMatrimonio} onChange={(d) => {
            props.onChange({ ...props.data, dataMatrimonio: d.format("DD/MM/YYYY") });

            setShowCalendar(false);
        }} />}
        <div className="form-floating">
            <input onChange={onChange} name="nomeSposi" value={props.data.nomeSposi} type="text" required className="form-floating-control" placeholder={strings.get("Nome e cognome degli Sposi") + "*"} />
            <label htmlFor="nomeSposi">{strings.get("Nome e cognome degli Sposi") + "*"}</label>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="locationCerimonia" value={props.data.locationCerimonia} type="text" required className="form-floating-control" placeholder={strings.get("Location cerimonia") + "*"} />
            <label htmlFor="locationCerimonia">{strings.get("Location cerimonia") + "*"}</label>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="location" value={props.data.location} type="text" required className="form-floating-control" placeholder={strings.get("Location ricevimento") + "*"} />
            <label htmlFor="location">{strings.get("Location ricevimento") + "*"}</label>
        </div>
        <div className="grid grid-cols-2">
            <div className="form-floating">
                <input onChange={onChange} name="numeroOspiti" value={props.data.numeroOspiti} type="text" required className="form-floating-control" placeholder={strings.get("Numero ospiti") + "*"} />
                <label htmlFor="location">{strings.get("Numero ospiti") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} name="orarioEvento" value={props.data.orarioEvento} type="text" required className="form-floating-control" placeholder={strings.get("Orario evento") + "*"} />
                <label htmlFor="location">{strings.get("Orario evento") + "*"}</label>
            </div>
        </div>

        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Servizi food richiesti") + "*"}</div>
            <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                {serviziFoodWedding.map((tp, tpl) => {
                    const sr = props.data.serviziFoodRichiesti || [];
                    const selected = sr.includes(tp);
                    return <button className="flex items-center" onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const dd = { ...props.data };
                        const sr = props.data.serviziFoodRichiesti ? [...props.data.serviziFoodRichiesti] : [];
                        if (!selected) {
                            sr.push(tp);
                        }
                        else {
                            sr.splice(sr.indexOf(tp), 1);
                        }
                        dd.serviziFoodRichiesti = sr;
                        props.onChange(dd);
                        return false;
                    }} key={tpl}>
                        <div className={classNames("btn-checkbox mr-3", selected ? "btn-checkbox-checked" : "")}></div> {strings.get(tp)}
                    </button>
                })}</div>
        </div>
        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Intrattenimento dopo cena") + "*"}</div>
            <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                {intrattenimentoDopoCena.map((tp, tpl) => {
                    const sr = props.data.intrattenimentoDopoPasto || [];
                    const selected = sr.includes(tp);
                    return <button className="flex items-center" onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const dd = { ...props.data };
                        const sr = props.data.intrattenimentoDopoPasto ? [...props.data.intrattenimentoDopoPasto] : [];
                        if (!selected) {
                            sr.push(tp);
                        }
                        else {
                            sr.splice(sr.indexOf(tp), 1);
                        }
                        dd.intrattenimentoDopoPasto = sr;
                        props.onChange(dd);
                        return false;
                    }} key={tpl}>
                        <div className={classNames("btn-checkbox mr-3", selected ? "btn-checkbox-checked" : "")}></div> {strings.get(tp)}
                    </button>
                })}</div>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="weddingPlanner" value={props.data.weddingPlanner} type="text" required className="form-floating-control" placeholder={strings.get("Wedding planner (Inserire nominativo)")} />
            <label htmlFor="location">{strings.get("Wedding planner (Inserire nominativo)")}</label>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="stileEvento" value={props.data.stileEvento} type="text" required className="form-floating-control" placeholder={strings.get("Mood/ stile evento")} />
            <label htmlFor="location">{strings.get("Mood/ stile evento")}</label>
        </div>

        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Richiesta del servizio di grafica") + "*"}</div>
            <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                {serviziGrafica.map((tp, tpl) => {
                    const sr = props.data.serviziGrafica || [];
                    const selected = sr.includes(tp);
                    return <button className="flex items-center" onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const dd = { ...props.data };
                        const sr = props.data.serviziGrafica ? [...props.data.serviziGrafica] : [];
                        if (!selected) {
                            sr.push(tp);
                        }
                        else {
                            sr.splice(sr.indexOf(tp), 1);
                        }
                        dd.serviziGrafica = sr;
                        props.onChange(dd);
                        return false;
                    }} key={tpl}>
                        <div className={classNames("btn-checkbox mr-3", selected ? "btn-checkbox-checked" : "")}></div> {strings.get(tp)}
                    </button>
                })}</div>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="budgetStimato" value={props.data.budgetStimato} type="text" required className="form-floating-control" placeholder={strings.get("Budget stimato")} />
            <label htmlFor="location">{strings.get("Budget stimato*")}</label>
        </div>
        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Note")}</div>
            <textarea className="mt-2 block border-antoniazzi-900 w-full" name="note" value={props.data.note} onChange={onChange} />
        </div>
    </>;
}
function EventoPrivatoAziendale(props: { onChange: (d: DataPreventivoType) => void, data: DataPreventivoType }) {

    const { strings } = useFEContext();
    const [showCalendar, setShowCalendar] = useState(false);

    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const d = { ...props.data };
            d[name] = ev.target.value;
            props.onChange(d);
        }
    }
    const dateEvento = props.data.dataMatrimonio && props.data.dataMatrimonio.length > 0 ? dayjs(props.data.dataMatrimonio, "DD/MM/YYYY") : dayjs();

    return <>
        {props.data.tipologiaEvento === 'Evento Aziendale' && <div className="form-floating">
            <input onChange={onChange} name="nomeAzienda" value={props.data.nomeAzienda} type="text" required className="form-floating-control" placeholder={strings.get("Nome azienda") + "*"} />
            <label htmlFor="nomeAzienda">{strings.get("Nome azienda") + "*"}</label>
        </div>}
        <div className="form-floating">
            <input onChange={onChange} name="motivoEvento" value={props.data.motivoEvento} type="text" required className="form-floating-control" placeholder={strings.get("Motivo dell’evento") + "*"} />
            <label htmlFor="motivoEvento">{strings.get("Motivo dell’evento") + "*"}</label>
        </div>
        <div className="grid grid-cols-2">
            <div className="form-floating">
                <input onChange={onChange} name="numeroOspiti" value={props.data.numeroOspiti} type="text" required className="form-floating-control" placeholder={strings.get("Numero ospiti") + "*"} />
                <label htmlFor="numeroOspiti">{strings.get("Numero ospiti") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} name="orarioEvento" value={props.data.orarioEvento} type="text" required className="form-floating-control" placeholder={strings.get("Orario evento") + "*"} />
                <label htmlFor="orarioEvento">{strings.get("Orario evento") + "*"}</label>
            </div>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="location" value={props.data.location} type="text" required className="form-floating-control" placeholder={strings.get("Location") + "*"} />
            <label htmlFor="location">{strings.get("Location") + "*"}</label>
        </div>
        <div className="form-floating relative">
            <a className="absolute top-0 left-0 right-0 bottom-0 flex items-center" onClick={() => setShowCalendar(!showCalendar)}><Icon name='chevron' className={classNames('ml-auto w-6 h-6', showCalendar ? "rotate-90" : "")} /></a>
            <input readOnly name="dataEventp" value={props.data.dataMatrimonio} type="text" required className="form-floating-control" placeholder={strings.get("Data") + "*"} />
            <label htmlFor="dataMatrimonio">{strings.get("Data") + "*"}</label>
        </div>
        {showCalendar && <Calendar value={dateEvento} onChange={(d) => {
            props.onChange({ ...props.data, dataMatrimonio: d.format("DD/MM/YYYY") });
            setShowCalendar(false);
        }} />}
        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Servizi richiesti") + "*"}</div>
            <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                {serviziFoodEventi.map((tp, tpl) => {
                    const sr = props.data.serviziFoodRichiesti || [];
                    const selected = sr.includes(tp);
                    return <button className="flex items-center" onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const dd = { ...props.data };
                        const sr = props.data.serviziFoodRichiesti ? [...props.data.serviziFoodRichiesti] : [];
                        if (!selected) {
                            sr.push(tp);
                        }
                        else {
                            sr.splice(sr.indexOf(tp), 1);
                        }
                        dd.serviziFoodRichiesti = sr;
                        props.onChange(dd);
                        return false;
                    }} key={tpl}>
                        <div className={classNames("btn-checkbox mr-3", selected ? "btn-checkbox-checked" : "")}></div> {strings.get(tp)}
                    </button>
                })}</div>
        </div>

        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Intrattenimento dopo cena") + "*"}</div>
            <div className="px-3 pb-4 pt-2 grid grid-cols-2 gap-2 text-p">
                {intrattenimentoDopoCena.map((tp, tpl) => {
                    const sr = props.data.intrattenimentoDopoPasto || [];
                    const selected = sr.includes(tp);
                    return <button className="flex items-center" onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        const dd = { ...props.data };
                        const sr = props.data.intrattenimentoDopoPasto ? [...props.data.intrattenimentoDopoPasto] : [];
                        if (!selected) {
                            sr.push(tp);
                        }
                        else {
                            sr.splice(sr.indexOf(tp), 1);
                        }
                        dd.intrattenimentoDopoPasto = sr;
                        props.onChange(dd);
                        return false;
                    }} key={tpl}>
                        <div className={classNames("btn-checkbox mr-3", selected ? "btn-checkbox-checked" : "")}></div> {strings.get(tp)}
                    </button>
                })}
            </div>
        </div>

        <div className="form-floating">
            <input onChange={onChange} name="stileEvento" value={props.data.stileEvento} type="text" required className="form-floating-control" placeholder={strings.get("Mood/ stile evento")} />
            <label htmlFor="location">{strings.get("Mood/ stile evento")}</label>
        </div>
        <div className="form-floating">
            <input onChange={onChange} name="budgetStimato" value={props.data.budgetStimato} type="text" required className="form-floating-control" placeholder={strings.get("Budget stimato")} />
            <label htmlFor="location">{strings.get("Budget stimato*")}</label>
        </div>
        <div className="pt-2 border-t border-antoniazzi-900">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Come si svolge l’evento")}</div>
            <textarea className="mt-2 block border-antoniazzi-900 w-full" name="svolgimentoEvento" value={props.data.svolgimentoEvento} onChange={onChange} />
        </div>
        <div className="pt-2">
            <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Note")}</div>
            <textarea className="mt-2 block border-antoniazzi-900 w-full" name="note" value={props.data.note} onChange={onChange} />
        </div>
    </>;
}

import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContainerType, ContainerRefType, PowerLayerType, ProductType, formatPrice } from "../Interfaces";
import { useFEContext } from "./FEContext";
import Breadcrumbs from "./Blocks/Breadcrumbs";
import { HighlightContainer, HighlightsWrapper } from "./Blocks/Highlight";
import MainSlider from "./Blocks/MainSlider";
import { OrderingType, ResponseType } from "../DataBackend";
import { Link, useFetcher, useSearchParams } from "react-router-dom";
import ViewProdotto from "./ViewProdotto";
import classNames from "classnames";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { BodyArticles } from "./Blocks/BodyArticle";
import ScrollGallery from "./Blocks/ScrollGallery";
import LightGallery from "./Blocks/LightGallery";
import NavSiblings from "./Blocks/NavSiblings";
import ProductsHighlights from "./Blocks/ProductsHighlights";
import MosaicRow from "./Blocks/MosaicRow";
import { Icon } from "../IconLib";
import { IntroAzienda } from "./Blocks/Azienda";
import TimelineScrollGallery from "./Blocks/Timeline";
import { IntroStruttura } from "./Blocks/Struttura";
import { BoxedArticles } from "./Blocks/BoxedArticles";
import { IntroHomepage } from "./Blocks/IntroHomepage";
import { IntroSectionLg } from "./Blocks/IntroSectionLg";
import { IntroSection } from "./Blocks/IntroSectionLg";
import { Riconoscimenti } from "./Blocks/Riconoscimenti";
import Referenze from "./Blocks/Referenze";
import { AccordionsArticles } from "./Blocks/AccordionsArticles";

export default function ViewBlocks() {
    const { container, dataBackend, showLoadingOverlay, strings, setGalleryImages, showPowerLayer } = useFEContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const [blocks, setBlocks] = useState<ContainerRefType[]>();
    const [selectedImage, setSelectedImage] = useState<ContainerRefType>();
    const [prodotto, setProdotto] = useState<ProductType>();
    const [responseData, setResponseData] = useState<ResponseType>();
    const prodotti: ProductType[] = responseData && !responseData.error ? responseData.records : [];
    const fetchList = (_sp: any) => {
        const sp = _sp || {};
        sp.limit = 200;


        showLoadingOverlay(true);
        const payload = { "entity": "Product", searchParams: sp };
        const u = new URL(dataBackend.endpointBase + "/wa/DataBackend/list");
        if (dataBackend.lang)
            u.searchParams.set("lang", dataBackend.lang);
        if (dataBackend.wosid)
            u.searchParams.set("wosid", dataBackend.wosid);

        const opt: RequestInit = {
            method: "POST",
            body: JSON.stringify(payload)
        };
        fetch(u, opt).then(d => d.json()).then(data => {
            showLoadingOverlay(false);
            if (!data.error) {
                setResponseData(data);
            }
        });
    }

    useEffect(() => {
        setProdotto(undefined);
        setBlocks(undefined);
        setResponseData(undefined);
        if (container) {
            if (container.containerClass === 'PRODUCT') {
                dataBackend.get({ id: container.id, entity: "Product" }).then(d => {
                    if (!d.error) setProdotto(d.records[0])
                })
            }
            else if (container.containerClass === 'CATEGORY') {
                const sp: any = {};
                for (const key of searchParams.keys()) {
                    const values = searchParams.getAll(key);
                    if (values && values.length > 0) {
                        if (values.length > 1) {
                            sp[key] = searchParams.getAll(key);
                        } else {
                            sp[key] = searchParams.get(key);
                        }
                    }
                }
                sp.category = container.id;
                fetchList(sp)
            }

            if (container.containers) {
                const _blocks = container?.containers?.filter(c => c.containerClass === 'BLOCK' && c.position == 'Center');
                setBlocks(_blocks);
                if (_blocks.length > 0) {
                    const loadImages = async () => {
                        const localImages: ContainerType[] = [];
                        const resp = await dataBackend.getAll(_blocks);
                        const containers: ContainerType[] = resp.records;
                        for (let index = 0; index < containers.length; index++) {
                            const _block = containers[index];
                            const resp2 = await dataBackend.get(_block);
                            if (!resp2.error && resp2.records && resp2.records.length > 0) {
                                const temp: ContainerType = resp2.records[0];
                                const im = temp.containers?.filter(img => img.containerClass === 'MULTIMEDIA_CONTENT' && img.position === 'Center');
                                if (im) {
                                    for (let ii = 0; ii < im.length; ii++) {
                                        localImages.push(im[ii]);
                                    }
                                }
                            }
                        }
                        return localImages;
                    }
                    loadImages().then(images => { setGalleryImages(images) });
                }

            }

        }
        if (searchParams.has("search")) {
            const sp: any = {};
            for (const key of searchParams.keys()) {
                const values = searchParams.getAll(key);
                if (values && values.length > 0) {
                    if (values.length > 1) {
                        sp[key] = searchParams.getAll(key);
                    } else {
                        sp[key] = searchParams.get(key);
                    }
                }
            }
            fetchList(sp);
        }
    }, [container])

    let path = container?.path ? [...container.path] : [];
    if (path.length > 0) {
        path.splice(0, 1);
    }
    if (prodotto && prodotto.categories && prodotto.categories.length > 0) {
        path = [prodotto.categories[0], prodotto];
    }

    const isCategory = container?.containerClass === 'CATEGORY';
    const showCategories = isCategory || searchParams.has("search");
    const hasProducts = prodotti && prodotti.length > 0;
    const subsections = container ? container.containers?.filter(c => c.containerClass === 'SECTION' && c.position === 'Center') : [];

    let selectedOrdering: OrderingType | undefined = undefined;
    if (searchParams.get("order")) {
        const o = searchParams.get("order");
        const founds = dataBackend.orderings.filter(or => or.value === o);
        if (founds && founds.length > 0) {
            selectedOrdering = founds[0];
        }
    }

    //const deliveryMethods = [{ id: 5, label: "Consegna tramite corriere" }];
    const deliveryMethods = dataBackend.metodiConsegna; //[{ id: 5, label: "Consegna tramite corriere" }, { id: 4, label: "Ritiro in Boutique" }];
    const showFilters = () => {
        const pl: PowerLayerType = {
            active: true, content: <FiltriPanel value={searchParams.getAll("filter") || ""} onChange={(values: any[]) => {
                searchParams.delete("filter");
                for (let index = 0; index < values.length; index++) {
                    searchParams.append("filter", values[index]);
                }
                setSearchParams(searchParams);
                const pl: PowerLayerType = { active: false, content: <></> };
                showPowerLayer(pl);
            }} categoria={container} />
        }; showPowerLayer(pl)
    };
    const subcategories = container ? container.containers?.filter(c => c.containerClass === 'CATEGORY') : [];
    const enableFilter = isCategory && subcategories && subcategories?.length > 0;
    const showOrderings = () => {
        const pl: PowerLayerType = {
            active: true, content: <SortOrderings list={dataBackend.orderings} value={selectedOrdering?.value || ""} onChange={(v) => {
                searchParams.set("order", v.value);
                setSearchParams(searchParams);
                const pl: PowerLayerType = { active: false, content: <></> };
                showPowerLayer(pl);
            }} />
        }; showPowerLayer(pl)
    };

    return (<>
        <div className="bg-white text-antoniazzi-900 min-h-[65vh]">
            {showCategories ? <>
                <MenuCategories selected={container} categorie={dataBackend.categorie} />
                <div className={classNames("flex overflow-hidden flex-col max-w-5xl lg:mx-auto lg:relative lg:mt-8", deliveryMethods && deliveryMethods.length > 1 ? "" : "lg:mb-8")}>
                    {deliveryMethods && deliveryMethods.length > 1 ? <div className="flex divide-antoniazzi-900 divide-x w-full border-y border-antoniazzi-900
                    lg:justify-center lg:gap-12 lg:border-antoniazzi-900 lg:py-4 lg:my-8 lg:divide-x-0 lg:divide-y-0 lg:order-1 lg:max-w-5xl lg:mx-auto">
                        {deliveryMethods.map((dm, dmk) => {
                            const onClick = (ev) => {
                                const allDeliveryMethods = searchParams.getAll("deliveryMethods");
                                if (!checked) {
                                    searchParams.append("deliveryMethods", dm.id + "")
                                }
                                else {
                                    searchParams.delete("deliveryMethods");
                                    allDeliveryMethods.splice(allDeliveryMethods.indexOf(dm.id + ""), 1)
                                    for (let index = 0; index < allDeliveryMethods.length; index++) {
                                        searchParams.append("deliveryMethods", allDeliveryMethods[index]);
                                    }
                                }
                                setSearchParams(searchParams);
                            };
                            const all = searchParams.getAll("deliveryMethods");
                            const checked = (all.includes(dm.id + ""))
                            return <button className="flex text-p-sm md:text-md-p py-2 px-6 items-center w-1/2 text-ellipsis overflow-hidden whitespace-nowrap lg:basis-auto lg:p-0 lg:w-auto" onClick={onClick} key={dmk}>
                                <div className={classNames("btn-radio shrink-0 grow-0 mr-1 lg:mr-10", checked ? "btn-radio-checked" : "")}></div> {dm.config.label || dm.title}
                            </button>
                        })}
                    </div> : <></>}

                    <div className="flex border-b divide-x divide-antoniazzi-900 border-antoniazzi-900
                    lg:absolute lg:top-6 lg:right-0 lg:divide-x-0 lg:border-0 lg:gap-20">
                        {enableFilter ? <button className="flex w-1/2 text-p-sm md:text-md-p py-2 px-6 items-center text-ellipsis overflow-hidden whitespace-nowrap lg:p-0 lg:w-auto" onClick={showFilters}><Icon name="filtri" className="w-4 h-4 shrink-0 grow-0 mr-1 lg:w-6 lg:h-6 lg:mr-6" />{strings.get("Filtri")}</button> : <></>}
                        <button className="flex w-1/2 text-p-sm md:text-md-p py-2 px-6 items-center text-ellipsis overflow-hidden whitespace-nowrap lg:p-0 lg:w-auto" onClick={showOrderings}><Icon name="sortorder" className="w-4 h-4 shrink-0 grow-0 mr-1 lg:w-6 lg:h-6 lg:mr-6" /> {strings.get("Ordina per")} {selectedOrdering?.label || ""}</button>
                    </div>
                    <div className="py-4 mx-4 lg:mx-0 w-full"><h2 className="text-h2 md:text-md-h2">{container?.title} <span className="text-antoniazzi-600">{(searchParams.get("search") ? " '" + searchParams.get("search") + "'" : "")}</span></h2></div>
                </div>
            </> : <></>}

            {hasProducts ? <>
                <div className="grid grid-cols-2 md:grid-cols-3 max-w-5xl lg:mx-auto gap-[7px] md:gap-2">{prodotti.map((p, pk) => {
                    return <PreviewProdotto key={pk} prodotto={p} />;
                })}</div>
                <Pagination responseData={responseData} />
            </> : <>{showCategories ? <div className="mx-4 max-w-5xl lg:mx-auto "><h2 className="text-h3 lg:text-md-h3">{strings.get("Nessun Prodotto Disponibile")}</h2></div> : <></>}</>}

            {prodotto ? <>
                <Breadcrumbs items={path} />
                <ViewProdotto prodotto={prodotto} />

            </> : <>

            </>}

            {blocks?.map((b, bk) => {
                return <Block path={path} key={bk} container={b} />
            })}
        </div>

    </>);
}

function FiltriPanel(props: { categoria?: ContainerType, value?: any[], onChange?: (value: any[]) => void }) {
    //const [subcategories,setSubcategories] = useState();
    if (!props.categoria) return <></>;
    const subcategories = props.categoria?.containers?.filter(c => c.containerClass === 'CATEGORY') || [];
    const [selections, setSelections] = useState<any[]>(props.value || []);
    const { strings } = useFEContext();
    return <div className="pt-8 px-4 md:px-6 text-antoniazzi-900 pb-20">
        <h4 className="text-h2 md:text-md-h2 mb-8">{strings.get("Filtri")}</h4>
        <h4 className="text-h4 md:text-md-h4 mb-5">{strings.get("Categorie")}</h4>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-y-4">
            {subcategories.map((a, b) => {
                let selected = false;
                let selectedIndex = -1;
                if (selections && selections.length > 0) {
                    for (let index = 0; index < selections.length; index++) {
                        const id = selections[index];
                        if (a.id) {
                            selected = a.id === parseInt(id);
                            if (selected) {
                                selectedIndex = index;
                                break
                            };
                        }
                    }
                }
                return <button onClick={() => {
                    const ss = [...selections];
                    if (selectedIndex > -1) {
                        ss.splice(selectedIndex, 1);
                    } else {
                        ss.push(a.id);
                    }
                    setSelections(ss);
                    /*if (props.onChange) {
                        if (!selected)
                            props.onChange(a, true);
                        else
                            props.onChange(a, false);
                    }*/
                }} key={b} className="flex items-center w-full"><div className={classNames(selected ? "btn-checkbox-checked" : "", "btn-checkbox mr-4")}></div> <div>{a.title}</div></button>
            })}
            {/*<button onClick={() => {
                if (props.onChange) {
                    props.onChange(undefined);
                }
            }} className="flex items-center w-full"><div className={classNames(!props.value ? "btn-radio-checked" : "", "btn-radio mr-4")}></div> <div>{strings.get("Nessuno")}</div></button>*/}
        </div>
        <button onClick={() => { if (props.onChange) props.onChange(selections) }} className="btn block mt-14 w-full rounded-sm text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Mostra i prodotti")}</button>
    </div>
}


export function PreviewProdotto(props: { prodotto: ProductType }) {
    if (!props.prodotto) return <></>;
    const { strings } = useFEContext();
    const p: ProductType = props.prodotto;
    const image = p && p.images && p.images.length > 0 ? p.images[0] : undefined;

    const hasSizes = p && p.sizes && p.sizes.length > 0;
    //@ts-ignore
    const priceDisplay = hasSizes ? p.sizes[0].priceDisplay : p.priceDisplay;
    //@ts-ignore
    const priceStandard = hasSizes ? p.sizes[0].priceStandard || p.priceDisplay : p.priceStandard;
    const hasDiscount = priceDisplay > priceStandard;
    return <Link className="block" to={p.routeTo || "#"}>
        {image ?
            <div className="relative">
                <div className="aspect-w-1 aspect-h-1">
                    <img className="object-cover object-center" src={image.imageSrcs?.small} />
                </div>
                <div className="absolute top-2 left-2 lg:top-4 lg:left-4 flex">
                    {p.deliveryMethods && p.deliveryMethods?.length > 1 ? <>
                        {p.deliveryMethods?.map((dm, dmk) => {
                            return <div key={dmk} className={classNames(!dm.valid ? "hidden" : "")}>{dm.config?.puntoVendita ? <Icon name="shop-alt" className="w-[15px] h-[14px] lg:w-7 lg:h-[25px] mr-2" /> : <Icon name="delivery-alt" className="w-[26px] h-[14px] lg:w-12 lg:h-[25px] mr-2" />}</div>;
                        })}
                    </> : <></>}</div>

                {hasDiscount ? <div className="text-p-sm lg:text-md-p-sm px-2 py-[1] leading-[13px] absolute top-2 right-2 text-white bg-black">black friday</div> : <></>}
            </div> : <></>}

        <div className="px-4 lg:px-0 py-2">
            <h3 className="text-p md:text-md-p mb-2 font-bold">{p.title}</h3>
            {p.sell ? <div className="text-p md:text-md-p">
                {hasDiscount ? <span className="line-through text-antoniazzi-600 mr-3">{(hasSizes ? strings.get("da ") : "") + formatPrice(priceDisplay)}</span> : <></>}
                {(hasSizes ? strings.get("da ") : "") + formatPrice(priceStandard)}
            </div> : <></>}
        </div>
    </Link>
}
function PreviewCategoria(props: { container: ContainerRefType }) {
    const { dataBackend } = useFEContext();
    const [container, setContainer] = useState<ContainerType>();
    useEffect(() => {
        if (props.container) {
            dataBackend.get(props.container).then(r => {
                if (!r.error) {
                    if (r.records.length > 0) {
                        setContainer(r.records[0]);
                    }
                }
            })
        } else {
            setContainer(undefined);
        }

    }, [props.container]);

    if (container) {
        const images = container.containers?.filter(c => c.containerClass === 'MULTIMEDIA_CONTENT' && c.position === 'Center');
        let image: ContainerRefType | undefined = undefined;
        if (images && images?.length > 0) {
            image = images[0];
        }
        const p = container;
        return <Link className="block" to={p.routeTo || "#"}>


            <div className="aspect-w-1 aspect-h-1">
                {image ? <img className="object-cover object-center" src={image.imageSrcs?.small} /> : <div className="bg-antoniazzi-900 w-full h-full"><div className="absolute left-1/2 top-1/2 -translate-x-1/2 text-white/75 font-bold -translate-y-1/2 text-center" data-id={p.id}>{p.title}</div></div>}
            </div>
            <div className="px-4 lg:px-0 pt-2 pb-4">
                <h3 className="text-h4 md:text-md-h4">{p.title}</h3>
            </div>
        </Link>
    }
}
export function Block(props: { container: ContainerRefType, path?: ContainerRefType[] }) {
    const { dataBackend } = useFEContext();
    const containerRef = props.container;
    const [container, setContainer] = useState<ContainerType>();
    useEffect(() => {
        dataBackend.get(containerRef).then(d => {
            if (!d.error) {
                setContainer(d.records[0]);
            }
        });
    }, [containerRef]);
    return <>
        {container?.directAction === 'ProductsHighlights' ? <ProductsHighlights container={container} /> : <></>}
        {container?.directAction === 'MosaicRow' ? <MosaicRow container={container} /> : <></>}
        {container?.directAction === 'NavSiblings' ? <NavSiblings container={container} /> : <></>}
        {container?.directAction === 'Breadcrumbs' ? <Breadcrumbs items={props.path || []} /> : <></>}
        {container?.directAction === 'MainSlider' ? <MainSlider container={container} /> : <></>}
        {container?.directAction === 'LightGallery' ? <LightGallery container={container} /> : <></>}
        {container?.directAction === 'Highlight' ? <HighlightContainer container={container} /> : <></>}
        {container?.directAction === 'Highlights' ? <HighlightsWrapper container={container} /> : <></>}
        {container?.directAction === 'BodyArticles' ? <BodyArticles container={container} /> : <></>}
        {container?.directAction === 'ScrollGallery' ? <ScrollGallery container={container} /> : <></>}
        {container?.directAction === 'Timeline' ? <TimelineScrollGallery container={container} /> : <></>}
        {container?.directAction === 'MenuStripe' ? <MenuStripe container={container} selected={container.container} /> : <></>}
        {container?.directAction === 'IntroAzienda' ? <IntroAzienda container={container} /> : <></>}
        {container?.directAction === 'IntroStruttura' ? <IntroStruttura container={container} /> : <></>}
        {container?.directAction === 'BoxedArticles' ? <BoxedArticles container={container} /> : <></>}
        {container?.directAction === 'IntroHomepage' ? <IntroHomepage container={container} /> : <></>}
        {container?.directAction === 'IntroSection' ? <IntroSection container={container} /> : <></>}
        {container?.directAction === 'Riconoscimenti' ? <Riconoscimenti container={container} /> : <></>}
        {container?.directAction === 'Referenze' ? <Referenze container={container} /> : <></>}
        {container?.directAction === 'IntroSectionLg' ? <IntroSectionLg container={container} /> : <></>}
        {container?.directAction === 'CategoriesList' ? <CategoriesList /> : <></>}
        {container?.directAction === 'AccordionsArticles' ? <AccordionsArticles container={container} /> : <></>}
    </>
}
function MenuCategories(props: { categorie: ContainerRefType[], selected?: ContainerRefType }) {
    const { strings } = useFEContext();
    const categorieDesktop: ContainerRefType[] = [];
    if (props.categorie) {
        props.categorie.forEach(cat => {
            if (cat.highlight) {
                categorieDesktop.push(cat);
            }
        });
    }
    let initialSlide: any = 0;
    if (props.selected && props.categorie) {
        for (let index = 0; index < props.categorie.length; index++) {
            const c = props.categorie[index];
            if (c.id === props.selected.id) {
                initialSlide = index;
                break;
            }
        }
    }
    initialSlide = initialSlide + "";
    //console.log("initialSlide", initialSlide);
    return <>{
        props.categorie ?
            <div className="pt-0 lg:pt-11 bg-white">
                <div className="stripe-menu">
                    <div>
                        <div className="max-w-5xl text-white h-10 leading-10 flex-nowrap gap-x-1 overflow-auto lg:hidden swiper-menu-categories">
                            <swiper-container initial-slide={initialSlide} slides-per-view="auto">{props.categorie?.map((c, ck) => {
                                const isSelected = props.selected ? c.id === props.selected.id : false;
                                return <swiper-slide key={ck}><MenuItemCategoriaMobile desktop={false} container={c} isSelected={isSelected} /></swiper-slide>;
                            })}</swiper-container>
                        </div>
                        <div className="hidden lg:flex max-w-5xl lg:mx-auto text-white h-10 leading-10 flex-nowrap gap-x-1 ">
                            {categorieDesktop?.map((c, ck) => {
                                const isSelected = props.selected ? c.id === props.selected.id : false;
                                return <MenuItemCategoria key={ck} desktop={true} container={c} isSelected={isSelected} />;
                            })}
                            <Link className="px-4 whitespace-nowrap" to="/shop">{strings.get("Tutte le Categorie")}</Link>
                        </div>
                    </div>
                </div>
            </div> : <></>
    }</>;
}
function MenuItemCategoriaMobile(props: { container: ContainerRefType, isSelected: boolean, desktop: boolean }) {
    /**const ref = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
        if (!props.desktop) {
            if (props.isSelected && ref.current) {
                ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
            }
        }
    }, [props.isSelected]);*/
    return <Link id={"mobile-categoria-" + props.container.id} className={classNames("whitespace-nowrap inline-flex px-[14px]", props.isSelected ? "underline" : "")} to={props.container.routeTo || "#"}>{props.container.title}</Link>
}
function MenuItemCategoria(props: { container: ContainerRefType, isSelected: boolean, desktop: boolean }) {
    /**const ref = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
        if (!props.desktop) {
            if (props.isSelected && ref.current) {
                ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
            }
        }
    }, [props.isSelected]);*/
    return <Link id={"categoria-" + props.container.id} className={classNames("px-4 whitespace-nowrap", props.isSelected ? "underline" : "")} to={props.container.routeTo || "#"}>{props.container.title}</Link>
}
export function MenuStripe(props: { container?: ContainerType, selected?: ContainerRefType }) {
    const [containers, setContainers] = useState<ContainerRefType[]>();
    const { dataBackend } = useFEContext();
    useEffect(() => {
        if (props.container && props.container.path && props.container.path.length > 1) {
            const topSection = props.container.path[1];
            if (topSection.id === props.container.id) {
                setContainers(props.container.containers?.filter(c => c.containerClass === 'SECTION' && c.position === 'Center'));
            } else {
                dataBackend.get(topSection).then((c) => {
                    if (!c.error) {
                        if (c.records && c.records.length > 0) {
                            const con = c.records[0] as ContainerType;
                            setContainers(con.containers?.filter(c => c.containerClass === 'SECTION' && c.position === 'Center'));
                        }
                    }
                })
            }
        }
    }, [props.container]);

    //console.log(props.selected);
    return <>{
        containers ?
            <div className="pt-0 lg:pt-11 bg-white">
                <div className="stripe-menu"><div>
                    <div className="flex max-w-5xl lg:mx-auto text-white h-10 leading-10 snap-x flex-nowrap gap-x-1 overflow-auto">
                        {containers?.map((c, ck) => {
                            const isSelected = props.selected ? c.id === props.selected.id : false;
                            return <Link id={"categoria-" + c.id} className={classNames("px-4 whitespace-nowrap", isSelected ? "underline" : "")} to={c.routeTo || "#"}>{c.title}</Link>
                        })}
                    </div></div></div>
            </div> : <></>
    }</>;
}
function SortOrderings(props: { value?: string, onChange?: Function, list: OrderingType[] }) {
    const { strings } = useFEContext();
    return <div className="pt-8 px-4 md:px-6 text-antoniazzi-900 pb-20">
        <h4 className="text-h2 md:text-md-h2 mb-8">{strings.get("Ordina per")}</h4>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-y-4">
            {props.list.map((a, b) => {
                const selected = a.value === props.value;
                return <button onClick={() => {
                    if (props.onChange) {
                        props.onChange(a);
                    }
                }} key={b} className="flex items-center w-full"><div className={classNames(selected ? "btn-radio-checked" : "", "btn-radio mr-4")}></div> <div>{a.label}</div></button>
            })}</div>
    </div>
}
function Pagination(props: any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const responseData: ResponseType = props.responseData;

    const pag: any[] = [];
    if (responseData) {
        for (var i = 0; i < responseData?.pages; i++) {
            const c = i;
            pag.push(
                <button key={i} className={classNames(responseData.currentPage === i ? "bg-antoniazzi-900 text-white" : "", " grow-0 shrink-0 w-6 block aspect-square text-center hover:bg-antoniazzi-300")} onClick={() => {
                    searchParams.set("page", c + "");
                    //fetchList(searchParams);
                    setSearchParams(searchParams);
                }}>{i + 1}</button>
            );
        }
    }
    return <>
        {responseData && responseData.pages > 1 ? <div className="pb-20">
            <div className="flex divide-x my-2 border border-antoniazzi-900/40  text-p lg:text-md-p lg:max-w-5xl lg:mx-auto">
                <button title="Vai alla prima pagina" onClick={() => {
                    searchParams.delete("page");
                    //fetchList(searchParams);
                    setSearchParams(searchParams);
                }} className={classNames(responseData.currentPage === 0 ? "opacity-30 pointer-events-none cursor-not-allowed" : "", "p-1.5 w-8 block aspect-square text-center hover:bg-antoniazzi-300")}><ChevronDoubleLeftIcon className="w-4 mx-auto h-4" /></button>
                <button title="Vai alla pagina precedente" onClick={() => {
                    if (responseData.currentPage > 0) {
                        searchParams.set("page", (responseData.currentPage - 1) + "");
                        //fetchList(searchParams);
                        setSearchParams(searchParams);
                    }
                }} className={classNames(responseData.currentPage === 0 ? "opacity-30 pointer-events-none cursor-not-allowed" : "", "p-1.5 w-8 block aspect-square text-center hover:bg-antoniazzi-300")} > <ChevronLeftIcon className="w-4 mx-auto h-4" /></button>
                <div className="flex divide-x overflow-hidden mx-auto">
                    {pag}
                </div>
                <div className="flex divide-x ml-auto">
                    <button title="Vai alla pagina successiva" onClick={() => {
                        if (responseData.currentPage < responseData.pages) {
                            searchParams.set("page", (responseData.currentPage - 1) + "");
                            setSearchParams(searchParams);
                        }
                    }} className={classNames(responseData.currentPage === (responseData.pages - 1) ? "opacity-30 pointer-events-none cursor-not-allowed" : "", "p-1.5 w-8 block aspect-square text-center hover:bg-antoniazzi-300")} > <ChevronRightIcon className="w-4 mx-auto h-4" /></button>
                    <button title="Vai all'ultima pagina" onClick={() => {
                        searchParams.set("page", (responseData.pages - 1) + "");
                        setSearchParams(searchParams);
                    }} className={classNames(responseData.currentPage === (responseData.pages - 1) ? "opacity-30 pointer-events-none cursor-not-allowed" : "", "p-1.5 w-8 block aspect-square text-center hover:bg-antoniazzi-300")}><ChevronDoubleRightIcon className="w-4 mx-auto h-4" /></button>
                </div>
            </div></div> : <div className="pb-20"></div>}
    </>
}
function CategoriesList() {
    const { dataBackend } = useFEContext();
    const categories = dataBackend.categorie;
    if (!categories) return <></>;
    return <div className="categories-list py-10 lg:py-14 grid grid-cols-2 md:grid-cols-3 max-w-5xl lg:mx-auto gap-[7px] md:gap-2">{categories.map((p, pk) => {
        return <PreviewCategoria key={pk} container={p} />;
    })}</div>;
}
import { MouseEventHandler } from "react";

function IconsLibrary() {
    return (
        <svg style={{ display: "none" }} xmlns="http://www.w3.org/2000/svg">
            <symbol fill="currentColor" id="regalo" viewBox="0 0 16.04 18.01"><path id="uuid-97740736-1d94-4c06-a891-dbb1cf3168d7" d="m10.48,4.37c.38-.19.74-.43,1.05-.73,1.4-1.4,1.41-2.64.8-3.25-.4-.37-.98-.49-1.5-.32-.67.21-1.27.6-1.75,1.11-.49.52-.85,1.15-1.06,1.83-.21-.68-.57-1.31-1.05-1.83-.48-.52-1.08-.9-1.75-1.11-.52-.18-1.1-.06-1.5.32-.61.61-.6,1.85.8,3.25.31.3.66.54,1.05.73H0v13.64h16.02l.02-13.64h-5.56Zm-.88-2.67c.38-.42.87-.74,1.41-.92.11-.03.22-.05.33-.05.17,0,.34.06.46.17.35.35.19,1.22-.8,2.21-.73.61-1.61,1-2.55,1.14.14-.94.53-1.82,1.14-2.55m-5.36-.79c.12-.12.29-.18.46-.17.11,0,.22.02.33.05.55.18,1.04.49,1.42.92.61.73,1,1.61,1.14,2.55-.94-.14-1.82-.53-2.55-1.14-.99-.99-1.15-1.86-.8-2.21m11.05,16.37H.74V5.1h6.92v12.17h.74V5.1h6.92l-.02,12.17Z" /></symbol>
            <symbol fill="currentColor" id="filtri" viewBox="0 0 21 14"><path d="m14.3,6c-.43,0-.78.27-.93.64-.03.09-.05.19-.06.29H0v.5h13.41c.11.22.28.39.5.49.12.05.25.08.39.08.09,0,.16-.03.24-.05.29-.07.52-.26.65-.52h5.81v-.5h-5.71c-.04-.52-.46-.93-.99-.93Z" /><path d="m5.78.91C5.73.4,5.32,0,4.8,0s-.93.4-.98.91H0v.5h3.89c.16.35.5.59.91.59s.75-.24.91-.59h15.29v-.5H5.78Zm-.98.59c-.1,0-.19-.04-.27-.09-.13-.09-.23-.23-.23-.41,0-.03.01-.06.02-.09.04-.23.24-.41.48-.41s.44.18.48.41c0,.03.02.06.02.09,0,.17-.09.32-.23.41-.08.05-.17.09-.27.09Z" /><path d="m6.7,12c-.52,0-.93.4-.98.91H0v.5h5.79c.16.35.5.59.91.59s.75-.24.91-.59h13.39v-.5H7.68c-.05-.51-.46-.91-.98-.91Zm0,1.5c-.1,0-.19-.04-.27-.09-.13-.09-.23-.24-.23-.41,0-.03.01-.06.02-.09.04-.23.24-.41.48-.41s.44.18.48.41c0,.03.02.06.02.09,0,.17-.09.32-.23.41-.08.05-.17.09-.27.09Z" /></symbol>
            <symbol fill="currentColor" id="lente" viewBox="0 0 24 24"><path d="m20.51,19.95l-4-4.04c1.22-1.34,1.98-3.11,1.98-5.06,0-4.14-3.36-7.5-7.5-7.5s-7.5,3.36-7.5,7.5,3.36,7.5,7.5,7.5c1.83,0,3.5-.66,4.8-1.75l4.01,4.05.71-.7ZM4.49,10.85c0-3.58,2.92-6.5,6.5-6.5s6.5,2.92,6.5,6.5-2.92,6.5-6.5,6.5-6.5-2.92-6.5-6.5Z" /></symbol>
            <symbol fill="currentColor" id="borsa" viewBox="0 0 24 24"><path d="m16.5,5.99v-2.98h-1v2.98h-6v-2.98h-1v2.98H3v15h18V5.99h-4.5Zm3.5,14H4V6.99h16v13Z" /></symbol>
            <symbol fill="currentColor" id="shop" viewBox="0 0 42 42"><path id="Tracciato_45" d="m35.86,13.76l-2.89-5.97H9.02l-2.82,5.83-.07.17.04.05v3.19c0,.88.54,1.67,1.36,1.99v15.21h26.92v-15.21c.82-.32,1.36-1.11,1.37-1.99v-3.2l.04-.07Zm-26.44-5.34h23.15l2.42,5.02H7l2.43-5.02Zm1.02,5.65h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm3.63,0h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm3.63,0h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm3.63,0h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm3.63,0h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm3.63,0h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23v-2.95Zm-21.76,2.95v-2.95h2.99v2.95c.06.83-.55,1.55-1.38,1.61-.83.06-1.55-.55-1.61-1.38,0-.08,0-.15,0-.23m17.04,5.13h6.53v11.43h-6.53v-11.43Zm9.98,11.43h-2.81v-12.06h-7.8v12.06h-15.04v-14.43s.09,0,.13,0c.74,0,1.43-.39,1.81-1.02.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.61,1,1.92,1.32,2.92.71.29-.18.53-.42.71-.71.39.63,1.07,1.02,1.81,1.02.04,0,.09,0,.13,0v14.43Zm-.13-15.06c-.83,0-1.5-.67-1.5-1.5v-2.95h2.99v2.95c0,.83-.67,1.5-1.5,1.5" /></symbol>
            <symbol fill="currentColor" id="delivery" viewBox="0 0 42 42"><path d="m8.76,30.3H3.26v-9.77h.61v9.16h4.9v.61Z" /><path d="m3.25,8.4v3.85h.61v-3.24h21.87v20.69h-10.35v.6h10.95V8.4H3.25Z" /><path id="Tracciato_107" d="m25.73,12.58v17.72h5.15v-.61h-4.54V13.18h7.04l3.29,4,4.05,5.14v7.37h-3.12v.61h3.72v-8.2l-4.18-5.31-3.47-4.22h-7.94Z" /><path id="Tracciato_108" d="m11.99,33.6c-1.99,0-3.61-1.62-3.61-3.61s1.62-3.61,3.61-3.61c1.99,0,3.61,1.62,3.61,3.61,0,1.99-1.62,3.61-3.61,3.61m0-6.61c-1.66,0-3,1.34-3,3s1.34,3,3,3,3-1.34,3-3-1.34-3-3-3h0" /><path id="Tracciato_109" d="m34.41,33.6c-1.99,0-3.61-1.62-3.61-3.61s1.62-3.61,3.61-3.61c1.99,0,3.61,1.62,3.61,3.61,0,1.99-1.62,3.61-3.61,3.61m0-6.61c-1.66,0-3,1.34-3,3s1.34,3,3,3,3-1.34,3-3-1.34-3-3-3h0" /><rect id="Rettangolo_299" x="2.65" y="11.94" width="11.74" height=".61" /><rect id="Rettangolo_300" x=".67" y="14.71" width="11.74" height=".61" /><rect id="Rettangolo_301" x="2" y="17.47" width="11.74" height=".61" /><rect id="Rettangolo_302" x="1.17" y="20.23" width="11.74" height=".61" /><path id="Tracciato_110" d="m38.21,22.42h-9.92v-7.86h3.83l6.09,7.86Zm-9.31-.61h8.07l-5.15-6.64h-2.92v6.64Z" /></symbol>
            <symbol fill="currentColor" id="minus" viewBox="0 0 24 24"><rect x="3.37" y="11.55" width="17.27" height=".9" /></symbol>
            <symbol fill="currentColor" id="chevron" viewBox="0 0 24 24"><polygon points="8.63 20.92 7.93 20.34 14.89 12 7.93 3.66 8.63 3.08 16.07 12 8.63 20.92" /></symbol>
            <symbol fill="currentColor" id="plus" viewBox="0 0 24 24"><polygon points="20.63 11.92 12.45 11.92 12.45 3.37 11.55 3.37 11.55 11.92 3.37 11.92 3.37 12.82 11.55 12.82 11.55 20.63 12.45 20.63 12.45 12.82 20.63 12.82 20.63 11.92" /></symbol>
            <symbol fill="currentColor" id="sortorder" viewBox="0 0 19 16.32"><path d="m19,16.32h-8.43v-7.06h8.43v7.06Zm-7.93-.5h7.43v-6.06h-7.43v6.06Z" /><path d="m19,6.88h-8.43V0h8.43v6.88Zm-7.93-.5h7.43V.5h-7.43v5.88Z" /><path d="m8.23,16.32H0v-7.06h8.23v7.06Zm-7.73-.5h7.23v-6.06H.5v6.06Z" /><path d="m8.23,6.88H0V0h8.23v6.88Zm-7.73-.5h7.23V.5H.5v5.88Z" /></symbol>
            <symbol fill="currentColor" id="delivery-alt" viewBox="0 0 48.67 25.38"><path id="uuid-e60f4c0e-3b25-475c-b5f8-c8ba64c94d99" d="m7.87,3.57h-3.03v.61h11.83v-.61h-8.18V.61h22.02v20.83h-10.22c-.17-2-1.93-3.48-3.93-3.31-1.76.15-3.16,1.55-3.31,3.31h-4.56v-8.92h3.34v-.61H0v.61h7.87v9.53h5.18c.17,2,1.93,3.48,3.93,3.31,1.76-.15,3.16-1.55,3.31-3.31h10.83V0H7.87v3.57Zm8.8,21.2c-1.67,0-3.02-1.35-3.02-3.02,0-1.67,1.35-3.02,3.02-3.02s3.02,1.35,3.02,3.02h0c0,1.67-1.35,3.02-3.02,3.02" /><path id="uuid-9f7d4e82-2afc-4952-8198-5e1017111e1e" d="m48.67,13.8l-4.21-5.35-3.5-4.25h-8v17.85h5.12c.17,2,1.93,3.48,3.93,3.31,1.76-.15,3.16-1.55,3.31-3.31h3.34v-8.25Zm-6.96,10.96c-1.67,0-3.02-1.35-3.02-3.02s1.35-3.02,3.02-3.02c1.67,0,3.02,1.35,3.02,3.02,0,1.67-1.35,3.02-3.02,3.02m6.35-3.33h-2.73c-.17-2-1.93-3.48-3.93-3.31-1.76.15-3.16,1.55-3.31,3.31h-4.51V4.82h7.09l3.31,4.02,4.07,5.17v7.42Z" /><rect id="uuid-0f338ae5-40d0-4a4c-906e-8fa47a353048" x="1.52" y="6.35" width="11.83" height=".61" /><rect id="uuid-71bc1e58-44c4-4cb4-a3b5-eb17211b481d" x="3.57" y="9.13" width="11.83" height=".61" /><path id="uuid-01eca114-1943-4244-b60d-896d57619845" d="m35.55,6.22v7.91h9.99l-6.13-7.91h-3.86Zm.61,7.3v-6.69h2.94l5.18,6.68h-8.12Z" /></symbol>
            <symbol fill="currentColor" id="shop-alt" viewBox="0 0 28.54 25.38"><path id="uuid-e7cdc421-bef4-45e2-b991-25bd01d78eba" d="m28.54,5.74l-2.77-5.74H2.77L.07,5.6l-.07.16.04.05v3.06c0,.85.52,1.6,1.31,1.91v14.6h25.85v-14.6c.79-.3,1.31-1.06,1.31-1.91v-3.07l.03-.06ZM3.15.61h22.23l2.33,4.82H.83L3.15.61Zm.97,5.43h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83Zm3.48,0h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83Zm3.48,0h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83Zm3.48,0h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83Zm3.48,0h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83Zm3.48,0h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44v-2.83ZM.64,8.87v-2.83h2.87v2.83c0,.79-.64,1.44-1.44,1.44s-1.44-.64-1.44-1.44m16.36,4.93h6.27v10.97h-6.27v-10.97Zm9.58,10.97h-2.7v-11.58h-7.49v11.58H1.96v-13.86s.08,0,.12,0c.71,0,1.37-.37,1.74-.98.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.59.96,1.84,1.26,2.81.68.28-.17.51-.4.68-.68.37.61,1.03.98,1.74.98.04,0,.08,0,.12,0v13.86Zm-.12-14.46c-.79,0-1.43-.64-1.44-1.44v-2.84h2.88v2.83c0,.79-.64,1.43-1.44,1.44" /><path id="uuid-bfb38440-dea1-4881-879b-b03002a636c3" d="m3.81,21.95h10.8v-8.76H3.81v8.76Zm.61-8.14h9.58v7.53H4.42v-7.53Z" /></symbol>
            <symbol fill="currentColor" id="borsa-topbar" viewBox="0 0 17.14 18.95"><path d="m12.85,4.38v-.1C12.85,1.92,10.93,0,8.56,0s-4.28,1.92-4.28,4.28v.1H0v14.57h17.11l.03-14.57h-4.29Zm-7.78-.1c0-.1,0-.21,0-.31.09-1.93,1.72-3.43,3.65-3.34,1.93.09,3.43,1.72,3.34,3.65v.1h-6.99v-.1Zm11.25,13.89H.79V5.17h3.5v1.58h.79v-1.58h6.99v1.58h.79v-1.58s3.5,0,3.5,0l-.02,13Z" /></symbol>
            <symbol fill="currentColor" id="lente-topbar" viewBox="0 0 17.33 18.95"><path d="m17.34,18.42l-5.32-5.83c1.48-1.32,2.41-3.23,2.41-5.37C14.43,3.23,11.2,0,7.21,0S0,3.23,0,7.21s3.23,7.22,7.21,7.22c1.56,0,3-.5,4.18-1.34l5.35,5.87.59-.54ZM.8,7.21C.8,3.67,3.67.8,7.21.8c3.54,0,6.42,2.88,6.42,6.42,0,3.55-2.87,6.42-6.42,6.42S.8,10.76.8,7.21Z" /></symbol>
            <symbol fill="currentColor" id="omino-topbar" viewBox="0 0 17.11 18.95"><path id="uuid-27cae4b4-f66f-4575-9ffc-1d4941c81361" d="m8.56,9.84c-2.72,0-4.92-2.21-4.92-4.92C3.64,2.2,5.85,0,8.57,0c2.72,0,4.92,2.2,4.92,4.92,0,2.72-2.2,4.92-4.92,4.92m0-9.05c-2.28,0-4.14,1.85-4.14,4.13s1.85,4.14,4.13,4.14,4.14-1.85,4.14-4.13c0,0,0,0,0,0,0-2.28-1.85-4.13-4.13-4.13" /><path id="uuid-4a9e5f00-6826-4ef9-87e6-3e7ac4907e1e" d="m17.11,18.96H0v-.39c0-4.02,3.84-7.29,8.56-7.29s8.56,3.27,8.56,7.29v.39Zm-16.31-.79h15.52c-.24-3.4-3.63-6.11-7.76-6.11S1.04,14.77.8,18.17" /></symbol>
            <symbol fill="currentColor" id="alert-warning-icon" viewBox="0 0 14 14"><path id="uuid-7b26e5c8-bf25-4dcb-ac98-b66fa8e254fd" d="m7,14c-3.87,0-7-3.13-7-7S3.13,0,7,0s7,3.13,7,7c0,3.86-3.14,7-7,7M7,.8C3.57.8.79,3.57.79,7s2.78,6.2,6.21,6.2,6.2-2.78,6.2-6.2h0c0-3.43-2.78-6.2-6.2-6.2" /><rect id="uuid-5f262d0c-8570-4f29-ad6a-ee37794681ca" x="6.6" y="3.86" width=".8" height="4.55" /><rect id="uuid-46080b20-d6eb-4151-b19a-e7256b1276b2" x="6.6" y="9.35" width=".8" height=".79" /></symbol>
            <symbol fill="currentColor" id="alert-success-icon" viewBox="0 0 14 14"><g id="uuid-efa35f7d-d39d-4b75-97e7-28a08d120ecd"><path id="uuid-2cb94198-afff-4fb8-a891-0eabb2b36e6f" d="m7,14c-3.87,0-7-3.13-7-7S3.13,0,7,0s7,3.13,7,7c0,3.86-3.14,7-7,7M7,.8C3.57.8.79,3.57.79,7s2.78,6.2,6.21,6.2,6.2-2.78,6.2-6.2h0c0-3.43-2.78-6.2-6.2-6.2" /><path id="uuid-0fce87d7-a9f3-4da9-9980-6af1b2c46a58" d="m6.87,9.72l-2.44-2.44.56-.56,1.87,1.87,4.04-4.05.56.56-4.61,4.61Z" /></g></symbol>
            <symbol fill="currentColor" id="alert-error-icon" viewBox="0 0 14 14"><path id="uuid-71a453cb-95ee-4667-a54b-60a949ee07cd" d="m7,14c-3.87,0-7-3.13-7-7S3.13,0,7,0s7,3.13,7,7c0,3.86-3.14,7-7,7M7,.8C3.57.8.79,3.57.79,7s2.78,6.2,6.21,6.2,6.2-2.78,6.2-6.2h0c0-3.43-2.78-6.2-6.2-6.2" /><path id="uuid-eb48010a-ec09-46dd-ab82-dc6f7f6eb381" d="m10.29,4.27l-.56-.56-2.73,2.73-2.73-2.73-.56.56,2.73,2.73-2.73,2.73.56.56,2.73-2.73,2.73,2.73.56-.56-2.73-2.73,2.73-2.73Z" /></symbol>
            <symbol fill="currentColor" id="bars" viewBox="0 0 21 14.59"><rect y="6.79" width="21" height="1" /><rect width="21" height="1" /><rect y="13.59" width="21" height="1" /></symbol>
            <symbol fill="currentColor" id="occhio-barrato" viewBox="0 0 24.48 15.47"><path id="uuid-51686f5c-7b60-42c2-85e8-3190eb890e98" d="m24.25,7.46c-.21-.24-5.19-5.96-12.01-5.96-1.66.01-3.3.33-4.84.94L4.89,0l-.63.61,2.27,2.2C4.15,3.93,2,5.51.23,7.46l-.23.27.23.27c.21.24,5.19,5.96,12.01,5.96,1.66-.01,3.3-.33,4.84-.94l2.52,2.44.62-.61-2.27-2.2c2.39-1.12,4.53-2.7,6.3-4.65l.24-.27-.24-.27Zm-6.45.27c0,1.28-.48,2.52-1.33,3.48l-2.23-2.16c.28-.39.42-.85.42-1.32-.02-1.31-1.1-2.36-2.41-2.34-.49,0-.96.14-1.36.41l-2.23-2.16c1.01-.84,2.29-1.29,3.6-1.29,3.02-.04,5.5,2.36,5.55,5.38m-11.1,0c0-1.28.48-2.52,1.33-3.48l2.23,2.16c-.28.39-.42.85-.42,1.32.02,1.31,1.1,2.36,2.41,2.34.49,0,.96-.14,1.36-.41l2.23,2.16c-1.01.84-2.29,1.29-3.6,1.29-3.02.05-5.5-2.36-5.55-5.38m6.89.7l-2.06-2c.22-.12.47-.18.72-.18.83-.01,1.52.65,1.53,1.48,0,.25-.07.49-.19.7m-.63.61c-.22.12-.47.18-.72.18-.83.01-1.52-.65-1.53-1.48,0-.25.07-.49.19-.7l2.06,2ZM1.17,7.73c1.73-1.79,3.78-3.24,6.04-4.27l.18.18c-2.26,2.47-2.09,6.31.38,8.57.01.01.03.02.04.04-2.51-1.02-4.77-2.56-6.65-4.52m16.1,4.27l-.18-.18c2.26-2.47,2.09-6.31-.38-8.57-.01-.01-.03-.02-.04-.03,2.51,1.02,4.77,2.56,6.65,4.52-1.73,1.79-3.78,3.24-6.04,4.27" /><path id="uuid-007be234-e2de-48c1-9ab8-b92b91ef666b" d="m12.24,10.15c1.33,0,2.41-1.08,2.41-2.41,0-1.33-1.08-2.41-2.41-2.41s-2.41,1.08-2.41,2.41h0c0,1.33,1.08,2.41,2.41,2.41m0-3.94c.84,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53c-.84,0-1.53-.69-1.53-1.53,0-.84.69-1.53,1.53-1.53" /></symbol>
            <symbol fill="currentColor" id="occhio" viewBox="0 0 24.48 15.47"><path id="uuid-2b2fb25f-6504-4df8-988d-a7fa90f63f98" d="m24.25,7.45c-.21-.25-5.19-6.15-12.01-6.15S.44,7.2.24,7.45L0,7.73l.23.28c.21.25,5.19,6.15,12.01,6.15s11.8-5.9,12.01-6.15l.24-.28-.24-.28Zm-6.46.28c0,3.07-2.48,5.55-5.55,5.55s-5.55-2.48-5.55-5.55,2.48-5.55,5.55-5.55c3.06,0,5.55,2.49,5.55,5.55M1.17,7.73c1.86-2.01,4.13-3.59,6.65-4.66-2.57,2.43-2.69,6.48-.27,9.06.09.09.17.18.27.27-2.52-1.07-4.79-2.66-6.65-4.66m15.5,4.66c2.57-2.43,2.69-6.48.27-9.06-.09-.09-.17-.18-.27-.27,2.52,1.07,4.79,2.66,6.65,4.66-1.86,2-4.13,3.59-6.65,4.66" /><path id="uuid-26b4374e-41f3-4412-aa09-8f73368752bf" d="m12.24,10.15c1.33,0,2.41-1.08,2.41-2.41,0-1.33-1.08-2.41-2.41-2.41s-2.41,1.08-2.41,2.41h0c0,1.33,1.08,2.41,2.41,2.41m0-3.94c.84,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53c-.84,0-1.53-.69-1.53-1.53,0-.84.69-1.53,1.53-1.53" /></symbol>
        </svg >);
}

interface IconPropsType {
    className?: string,
    onClick?: MouseEventHandler
    name: "occhio" | "occhio-barrato" | "regalo" | "bars" | "alert-error-icon" | "alert-success-icon" | "alert-warning-icon" | "omino-topbar" | "borsa-topbar" | "lente-topbar" | "shop-alt" | "delivery-alt" | "lente" | "borsa" | "shop" | "delivery" | "chevron" | "plus" | "minus" | "filtri" | "sortorder"
}
export function Icon(props: IconPropsType) {
    return <svg role="img" className={props.className} onClick={props.onClick}>
        <use xlinkHref={"#" + props.name}></use>
    </svg>;
}

export default IconsLibrary
import React, { useEffect, useState } from "react";
import { MenuAccount } from "./ViewProfilo";
import { useNavigate, useParams } from "react-router-dom";
import { AlertType, ContainerRefType, OrderRowType, OrderType, ProductType, formatPrice } from "../Interfaces";
import { useFEContext } from "./FEContext";
import { AvvisoAreaRiservata, OrderHead } from "./ViewOrdini";

export default function ViewOrdineDettaglio() {
    const { dataBackend, user, strings } = useFEContext();
    const { idOrdine } = useParams();
    const [ordine, setOrdine] = useState<OrderType>();
    useEffect(() => {
        if (idOrdine) {
            dataBackend.get({ entity: "Order", id: idOrdine }).then((r) => {
                if (!r.error) {
                    setOrdine(r.records[0]);
                }
            })
        }
    }, [idOrdine])
    return (
        <div className="bg-white text-antoniazzi-900 min-h-[65vh]">
            {user?.confermato ? <>
                <MenuAccount />
                <div className="px-4 lg:px-0 max-w-5xl mx-auto py-6">
                    <div className="text-h1 md:text-md-h1 pb-4 border-b border-antoniazzi-900">{strings.get("Riepilogo ordine") + " " + ordine?.id}</div>
                    {ordine ?

                        <>


                            <div className="grid grid-cols-2 lg:grid-cols-4 py-6 relative gap-x-16 gap-y-6 text-p md:text-md-p border-b border-antoniazzi-900"> <OrderHead order={ordine} /></div>


                            <h4 className="text-h3 md:text-md-h3 mt-4 mb-3">{strings.get("Dettagli ordine")}</h4>

                            {ordine.lines?.map((l, lk) => {
                                return <PreviewOrderLine key={lk} line={l} />;
                            })}
                        </>

                        : <></>}
                </div>
            </>
                : <><AvvisoAreaRiservata /></>}
        </div>
    );
}

export function ViewPagamento(props: { ok: boolean }) {
    const { user, strings, setCart, hideCart, showAlert } = useFEContext();
    const nav = useNavigate();
    useEffect(() => {
        if (props.ok) {
            hideCart();
            setCart({});
            const al: AlertType = {
                active: true,
                message: strings.get("Il tuo ordine è stato confermato, riceverai presto una mail di riepilogo"),
                severity: "success"
            }
            showAlert(al);
            nav("/ordini");
        } else {
            const al: AlertType = {
                active: true,
                message: strings.get("Il processo di pagamento non è riuscito"),
                severity: "warning"
            }
            showAlert(al);
        }
    }, [props.ok])
    return <>
        <div className="bg-white text-antoniazzi-900 min-h-[65vh]">
            {user?.confermato ? <>
                <MenuAccount />
                <div className="px-4 lg:px-0 max-w-5xl mx-auto py-6">
                </div>
            </> : <></>}
        </div>

    </>;
}

function PreviewOrderLine(props: { line: OrderRowType }) {
    const { dataBackend, strings } = useFEContext();
    const [prodotto, setProdotto] = useState<ProductType>();
    const [parentProdotto, setParentProdotto] = useState<ProductType>();
    useEffect(() => {
        if (props.line) {
            dataBackend.get({ entity: "Product", id: props.line.idContainer }).then(r => {
                if (!r.error) {
                    const c: ProductType = r.records[0];
                    setProdotto(c);
                    if (c.container?.containerClass === 'PRODUCT') {
                        dataBackend.get({ entity: "Product", id: c.idParentContainer }).then(r2 => {
                            if (!r2.error) setParentProdotto(r2.records[0]);
                        });
                    }
                }
            })
        }
    }, [props.line])
    let firstImage: ContainerRefType | undefined = undefined;
    if (prodotto && prodotto.images && prodotto.images.length > 0) {
        firstImage = prodotto.images[0];
    }
    if (!firstImage && parentProdotto && parentProdotto.images && parentProdotto.images.length > 0) {
        firstImage = parentProdotto.images[0];
    }
    return <>{props.line ? <>
        <div className="flex items-stretch text-p md:text-md-p">
            <div className="grow-0 shrink-0 w-32 lg:w-56 mr-4">
                <div className="aspect-w-1 aspect-h-1 ">
                    {firstImage ? <img src={firstImage?.imageSrcs?.small} className="object-cover object-center" /> : <></>}
                </div>
            </div>
            <div className="flex flex-col w-full grow-1 justify-stretch">
                <div className="flex grow w-full justify-between">
                    <div>
                        <div className="font-bold text-h4 lg:text-md-h4">{props.line.description}</div>
                        {props.line.options ? <div className="mt-2 mb-2">
                            {props.line.options.ricorrenza ? <div className="text-p mb-2"><span className="font-bold">{strings.get("Ricorrenza")}</span> {props.line.options.ricorrenza}</div> : <></>}
                            {props.line.childrenLines?.map((subline, indx) => {
                                return <div className="text-p-sm ml-2 lg:text-md-p-sm mb-1 flex" key={indx}>
                                    <div className="mr-2 w-4 shrink-0 lg:w-6">{subline.quantity}</div>
                                    <div>{subline.description}</div>
                                </div>
                            })}
                        </div> : <></>}
                    </div>

                    <div className="ml-auto">{formatPrice(props.line.total)}</div>
                </div>
                <div className="grow-0">{strings.get("Quantità")} {props.line.quantity}</div>
            </div>
        </div>
        <div className="text-p-sm md:text-md-p-sm mt-2 mb-4">Ref. {props.line.productorCode || "n.d."}</div>

    </> : <></>}</>;
}
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'

import 'dayjs/locale/it';
import { DataParticolareType } from '../../Interfaces';
import { Icon } from '../../IconLib';

export default function POSCalendar(props: { value?: dayjs.Dayjs, onChange?: Function }) {
    dayjs.locale("it");
    const [month, setMonth] = useState<number>(dayjs().month());
    const [year, setYear] = useState<number>(dayjs().year());
    const now = dayjs(new Date(year, month, 1));

    const days: any = [];

    useEffect(() => {
        if (props.value) {
            setMonth(props.value.month());
            setYear(props.value.year());
        }
    }, [props.value]);

    let first = now.startOf('month');
    let last = now.endOf('month');

    first = first.startOf('week');
    last = last.endOf('week');
    let d = first;
    const minDate: Dayjs = props.minDate /*dayjs().startOf('day')*/;

    console.log("mindate", minDate);

    const today = dayjs().startOf('day');
    while (d.isBefore(last)) {
        const isCurrentMonth = d.month() === month;
        const isToday = d.isSame(today);
        const isSelected = props.value ? d.isSame(props.value) : false;
        let isDisabled = /*d.isSame(minDate) || */d.isBefore(minDate);
        let orari: DataParticolareType | undefined = undefined;
        let prioritaOrario = -1;
        if (props.timetable) {
            for (let index = 0; index < props.timetable.length; index++) {
                const dp = props.timetable[index];
                if (dp.giornoSettimana !== undefined && !dp.giornoDelMese) {
                    //Disabilitato quando è il giorno della settimana 
                    if (dp.mese === undefined && dp.anno === undefined && prioritaOrario < 0) {
                        //Match giornoSettimana mese anno... 0 livello
                        if (d.day() === dp.giornoSettimana) {
                            orari = dp;
                            prioritaOrario = 0;
                        }
                    }
                    //Se c'è il mese confronto giorno settimana e mese
                    else if (dp.mese && dp.anno === undefined && prioritaOrario < 1) {
                        //Match giornoSettimana mese anno... 1 livello
                        if (d.day() === dp.giornoSettimana && d.month() === dp.mese - 1) {
                            orari = dp;
                            prioritaOrario = 1;
                        }
                    }
                    //Se c'è mese e anno (es tutti i lunedì di dicembre 2023)
                    else if (dp.mese && dp.anno && prioritaOrario < 2) {
                        //Match giornoSettimana mese anno... 2 livello
                        if (d.day() === dp.giornoSettimana && d.month() === dp.mese - 1 && d.year() === dp.anno) {
                            orari = dp;
                            prioritaOrario = 2;
                        }
                    }
                } else if (dp.giornoDelMese && dp.mese && dp.anno === undefined && prioritaOrario < 3) {
                    //Match giorno/mese... 3 livello
                    if (d.date() === dp.giornoDelMese && d.month() === dp.mese - 1) {
                        orari = dp;
                        prioritaOrario = 3;
                    }
                } else if (dp.giornoDelMese && dp.mese && dp.anno && prioritaOrario < 3) {
                    //Match data esatta... il più prioritario
                    if (d.date() === dp.giornoDelMese && d.month() === dp.mese - 1 && d.year() === dp.anno) {
                        orari = dp;
                        prioritaOrario = 4;
                    }
                }
            }
        }
        if (!orari || orari?.chiusura) {
            isDisabled = true;
        }
        days.push({
            date: d.format('YYYY-MM-DD'),
            isCurrentMonth: isCurrentMonth,
            isToday: isToday,
            isSelected: isSelected,
            isDisabled: isDisabled,
            orari: orari
        });
        d = d.add(1, 'day');
    }


    return (
        <div className='text-antoniazzi-900 border border-antoniazzi-900 pb-4 mb-6'>
            <div className="flex items-center justify-center bg-antoniazzi-300/50 py-4">
                <button onClick={() => {
                    let y = year;
                    let next = month - 1;
                    if (next === 0) { next = 11; y = year - 1 }
                    setMonth(next);
                    setYear(y);
                }}
                    type="button"
                    className="hover:text-antoniazzi-900/50 px-4"
                >
                    <Icon name='chevron' className='w-6 h-6 rotate-180' />
                </button>

                <h2 className="flex-auto grow-1 text-center text-p md:text-md-p capitalize">{now.format("MMMM")} {year}</h2>

                <button onClick={() => {
                    let y = year;
                    let next = month + 1;
                    if (next > 11) { next = next - 12; y = year + 1 }
                    setMonth(next);
                    setYear(y);
                }}
                    type="button"
                    className="hover:text-antoniazzi-900/50 px-4"
                >
                    <Icon name='chevron' className='w-6 h-6' />
                </button>
            </div>
            <div className="mt-4 grid grid-cols-7 text-center text-p md:text-md-p">
                <div>Lun</div>
                <div>Mar</div>
                <div>Mer</div>
                <div>Gio</div>
                <div>Ven</div>
                <div>Sab</div>
                <div>Dom</div>
            </div>
            <div className="mt-2 grid grid-cols-7 text-p md:text-md-p">
                {days.map((day, dayIdx) => (
                    <div key={day.date} className={classNames(dayIdx > 6 && '', 'py-0')}>
                        <button
                            onClick={() => {
                                if (day.isDisabled) {

                                } else {
                                    if (props.onChange) props.onChange(dayjs(day.date), day.orari);
                                }
                            }}
                            type="button"
                            className={classNames(
                                day.isDisabled && 'opacity-50 cursor-not-allowed',
                                day.isSelected && 'text-white',
                                !day.isSelected && day.isToday && 'text-bordeaux',
                                !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-antoniazzi-900',
                                !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-antoniazzi-900/40 hover:text-white',
                                day.isSelected && day.isToday && 'bg-antoniazzi-600',
                                day.isSelected && !day.isToday && 'bg-antoniazzi-900',
                                (!day.isSelected && !day.isDisabled) && 'hover:bg-antoniazzi-300',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                'mx-auto flex h-8 w-8 items-center justify-center'
                            )}
                        >
                            <time dateTime={day.date}>{day.date.split('-').pop().replace(/^0/, '')}</time>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export function Calendar(props: { value?: dayjs.Dayjs, onChange?: (d: Dayjs) => void}) {
    dayjs.locale("it");
    const [month, setMonth] = useState<number>(dayjs().month());
    const [year, setYear] = useState<number>(dayjs().year());
    const now = dayjs(new Date(year, month, 1));

    const days: any = [];

    useEffect(() => {
        if (props.value) {
            setMonth(props.value.month());
            setYear(props.value.year());
        }
    }, [props.value]);

    let first = now.startOf('month');
    let last = now.endOf('month');

    first = first.startOf('week');
    last = last.endOf('week');
    let d = first;
    const minDate: Dayjs = dayjs().startOf('day').add(1, 'day');


    const today = dayjs().startOf('day');
    while (d.isBefore(last)) {
        const isCurrentMonth = d.month() === month;
        const isToday = d.isSame(today);
        const isSelected = props.value ? d.isSame(props.value) : false;
        let isDisabled = /*d.isSame(minDate) || */d.isBefore(minDate);
        
        days.push({
            date: d.format('YYYY-MM-DD'),
            isCurrentMonth: isCurrentMonth,
            isToday: isToday,
            isSelected: isSelected,
            isDisabled: isDisabled
        });
        d = d.add(1, 'day');
    }


    return (
        <div className='text-antoniazzi-900 border border-antoniazzi-900 pb-4 mb-6'>
            <div className="flex items-center justify-center bg-antoniazzi-300/50 py-4">
                <button onClick={() => {
                    let y = year;
                    let next = month - 1;
                    if (next === 0) { next = 11; y = year - 1 }
                    setMonth(next);
                    setYear(y);
                }}
                    type="button"
                    className="hover:text-antoniazzi-900/50 px-4"
                >
                    <Icon name='chevron' className='w-6 h-6 rotate-180' />
                </button>

                <h2 className="flex-auto grow-1 text-center text-p md:text-md-p capitalize">{now.format("MMMM")} {year}</h2>

                <button onClick={() => {
                    let y = year;
                    let next = month + 1;
                    if (next > 11) { next = next - 12; y = year + 1 }
                    setMonth(next);
                    setYear(y);
                }}
                    type="button"
                    className="hover:text-antoniazzi-900/50 px-4"
                >
                    <Icon name='chevron' className='w-6 h-6' />
                </button>
            </div>
            <div className="mt-4 grid grid-cols-7 text-center text-p md:text-md-p">
                <div>Lun</div>
                <div>Mar</div>
                <div>Mer</div>
                <div>Gio</div>
                <div>Ven</div>
                <div>Sab</div>
                <div>Dom</div>
            </div>
            <div className="mt-2 grid grid-cols-7 text-p md:text-md-p">
                {days.map((day, dayIdx) => (
                    <div key={day.date} className={classNames(dayIdx > 6 && '', 'py-0')}>
                        <button
                            onClick={() => {
                                if (day.isDisabled) {

                                } else {
                                    if (props.onChange) props.onChange(dayjs(day.date));
                                }
                            }}
                            type="button"
                            className={classNames(
                                day.isDisabled && 'opacity-50 cursor-not-allowed',
                                day.isSelected && 'text-white',
                                !day.isSelected && day.isToday && 'text-bordeaux',
                                !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-antoniazzi-900',
                                !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-antoniazzi-900/40 hover:text-white',
                                day.isSelected && day.isToday && 'bg-antoniazzi-600',
                                day.isSelected && !day.isToday && 'bg-antoniazzi-900',
                                (!day.isSelected && !day.isDisabled) && 'hover:bg-antoniazzi-300',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                'mx-auto flex h-8 w-8 items-center justify-center'
                            )}
                        >
                            <time dateTime={day.date}>{day.date.split('-').pop().replace(/^0/, '')}</time>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}
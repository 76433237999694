import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../IconLib";
import { AlertType, CartItemType, CartType, ContainerRefType, ContainerType, DataParticolareType, DeliveryMethodType, DiscountCouponType, PowerLayerType, ProductType, formatPrice } from "../../Interfaces";
import { AlertInline, useFEContext } from "../FEContext";
import POSCalendar from "./POSCalendar";

interface XPayPayloadType {
    importo?: string
    codTrans?: string
    divisa?: string
    mac?: string
    alias?: string
    url?: string
    url_back?: string
    urlpost?: string
    action?: string
}
export default function CartPanel(props: { checkout?: boolean }) {
    const { user, cart, strings, showPowerLayer, setCart, hideCart, showAlert, dataBackend } = useFEContext();
    const [invalidMethod, setInvalidMethod] = useState<DeliveryMethodType>();
    const [checkout, setCheckout] = useState<boolean>();
    const [pos, setPos] = useState<ContainerType>();
    const [xpayPayload, setXPayPayload] = useState<XPayPayloadType>();
    let totalDelivery = cart ? parseFloat(cart.totalDelivery || "0") : 0;
    let totalProducts = cart ? parseFloat(cart.totalProducts || "0") : 0;
    let total = totalDelivery + totalProducts;
    const nav = useNavigate();
    if (cart && cart.discountCoupon && cart.discountCoupon.value) {
        total = total - cart.discountCoupon.value;
    }


    useEffect(() => {
        if (cart && cart.consegna && cart.consegna.idPos) {
            dataBackend.getContainer(cart.consegna.idPos).then(c => { setPos(c) })
        } else {
            setPos(undefined);
        }
    }, [cart]);
    useEffect(() => {
        setCheckout(props.checkout);
    }, [props.checkout]);
    useEffect(() => {
        if (checkout) {
            dataBackend.createXPay().then((json) => {
                if (!json.error) {
                    setXPayPayload(json.payload);
                } else {
                    setXPayPayload(undefined);
                    const al: AlertType = {
                        active: true,
                        message: strings.get("Il servizio non è al momento disponibile, riprova più tardi"),
                        severity: "warning"
                    }
                    showAlert(al);
                }
            });
        } else {
            setXPayPayload(undefined);
        }
    }, [checkout])

    const [puntoVenditaSelection, setPuntoVenditaSelection] = useState<boolean>(false);

    const confermaTipoConsegna = () => {
        if (!cart?.deliveryMethod) {
            const al: AlertType = { active: true, message: "Devi selezionare un metodo di consegna", severity: "warning" };
            showAlert(al);
            return false;
        }
        if (!cart?.rows || cart?.rows?.length === 0) {
            const al: AlertType = { active: true, message: "Il carrello è vuoto", severity: "warning" };
            showAlert(al);
            return false;
        }
        if (cart.deliveryMethod.config.puntoVendita) {
            setPuntoVenditaSelection(true);
            return false;
        }
        if (!user?.confermato) {
            const pl: PowerLayerType = { active: false };
            showPowerLayer(pl);
            window.location.hash = "login";
            return false;
        }

        setCheckout(true);
    };

    const confermaCarrello = () => {
        if (!cart?.deliveryMethod) {
            const al: AlertType = { active: true, message: "Devi selezionare un metodo di consegna", severity: "warning" };
            showAlert(al);
            return false;
        }
        if (!cart?.rows || cart?.rows?.length === 0) {
            const al: AlertType = { active: true, message: "Il carrello è vuoto", severity: "warning" };
            showAlert(al);
            return false;
        }
        if (cart.deliveryMethod.config.puntoVendita && (!cart.consegna || !cart.consegna.fasciaOraria || !cart.consegna.idPos || !cart.consegna.giornoConsegna)) {
            const al: AlertType = { active: true, message: "Devi selezionare il punto vendita, un giorno e una fascia oraria", severity: "warning" };
            showAlert(al);
            setPuntoVenditaSelection(true);
            return false;
        }
        if (!user?.confermato) {
            const pl: PowerLayerType = { active: false };
            showPowerLayer(pl);
            window.location.hash = "login";
            return false;
        }
        setPuntoVenditaSelection(false);
        setCheckout(true);
    };
   /* const onApprove = function (data) {
        //console.log("onApprove", data);
        return dataBackend.approveOrder(data).then((response) => {
            if ("APPROVED" === response.status || "COMPLETED" === response.status) {
                hideCart();
                setCart({});
                const al: AlertType = {
                    active: true,
                    message: strings.get("Il tuo ordine è stato confermato, riceverai presto una mail di riepilogo"),
                    severity: "success"
                }
                showAlert(al);
                nav("/ordini");

            }
        });
    }

    const createOrder = () => {
        return dataBackend.createOrder().then((json) => json.id);
    }
    const goToXPayWeb = () => {
        return dataBackend.createXPayWeb().then((json) => {
            if (!json.error) {
                window.location.href = json.url;
            } else {
                const al: AlertType = {
                    active: true,
                    message: strings.get("Il servizio non è al momento disponibile, riprova più tardi"),
                    severity: "warning"
                }
                showAlert(al);
            }
        });
    }*/

    return (<>
        {!cart || !cart.rows || cart.rows.length === 0 ? <>
            <div className="text-antoniazzi-900 pt-8 px-4 pb-16">
                <h4 className="text-h1 md:text-md-h2 mb-6">{strings.get("Carrello")}</h4>
                <div>{strings.get("Il carrello è vuoto")}</div>
                <button onClick={() => { showPowerLayer({ active: false }); nav("/shop"); }} className="btn mt-5 text-center block w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Inizia gli acquisti")}</button>
            </div>
        </> : <>

            {!puntoVenditaSelection ? <>
                <div className="text-antoniazzi-900 pt-8 px-4">
                    <h4 className="text-h1 md:text-md-h2 mb-6">Carrello</h4>
                    {cart?.rows?.map((row, rowk) => {
                        const del = invalidMethod ? row.deliveryMethods?.filter(dm => invalidMethod?.id === dm.id) : undefined;
                        const isInvalid = del !== undefined && !del[0].valid;
                        return <CartPanelRow readonly={checkout} error={isInvalid ? { active: true, severity: "error" } : undefined} onDelete={() => {
                            const onConfirm = () => {
                                const c: CartType = { ...cart };
                                c.rows?.splice(rowk, 1);
                                setCart(c);
                                const sl = { active: false };
                                showAlert(sl);
                                setInvalidMethod(undefined);
                            }
                            const al: AlertType = { active: true, confirm: true, onConfirm: onConfirm, message: "Vuoi davvero rimuovere il prodotto dal carrello?" };
                            showAlert(al);
                        }} onQuantityUpdate={(q: number) => {
                            const c: CartType = { ...cart };
                            if (c.rows) {
                                const row = c.rows[rowk];
                                if (!row.customization) {
                                    c.rows[rowk].quantity = q;
                                    setCart(c);
                                    setInvalidMethod(undefined);
                                }
                            }
                        }} key={rowk} row={row} />
                            ;
                    })}
                </div>
                <div className="mx-4 pt-10 mt-8 pb-8 border-t border-antoniazzi-900">
                    <h4 className="text-h3 md:text-md-h3">{strings.get("Modalità di Consegna")}</h4>
                    {!checkout ? <div className="lg:grid lg:grid-cols-2 mt-6">

                        {dataBackend?.metodiConsegna.map((dm, dmk) => {
                            const righePerMetodo = cart?.rows?.filter(row => {
                                const validRows = row.deliveryMethods?.filter(cdm => cdm.id === dm.id && cdm.valid);
                                return validRows && validRows?.length > 0;
                            })
                            const metodoValido = righePerMetodo?.length === cart?.rows?.length;
                            const selectMethod = () => {
                                if (metodoValido) {
                                    const c = { ...cart };
                                    c.deliveryMethod = dm;
                                    setCart(c);
                                    setInvalidMethod(undefined);
                                } else {
                                    const al: AlertType = { active: true, message: "Alcuni prodotti non possono essere consegnati con questa modalità", title: "Errore", severity: "error" }
                                    showAlert(al);
                                    setInvalidMethod(dm);
                                }
                            }

                            const checked = cart?.deliveryMethod?.id === dm.id;
                            return <button onClick={selectMethod} key={dmk} className={classNames("flex items-center mb-2 w-full text-left", !metodoValido ? "text-bordeaux" : "")}>

                                <div className={classNames("btn-radio shrink-0 grow-0 mr-1 lg:mr-10", checked ? "btn-radio-checked" : "", !metodoValido ? "!border-bordeaux" : "")}></div>

                                {dm.config?.puntoVendita ? <Icon name="shop" className="w-8 h-8 mr-2" /> : <Icon name="delivery" className="w-8 h-8 mr-2" />}
                                <div className="grow text-p md:text-md-p">
                                    <div className="">{dm.title} {!metodoValido ? <>{": "}<span className="underline">non disponibile</span></> : <></>}</div>

                                </div>
                            </button>;
                        })}
                    </div> : <>
                        <div className="flex items-center w-full mt-2 text-left">
                            {cart?.deliveryMethod?.config?.puntoVendita ? <Icon name="shop-alt" className="w-8 h-8 mr-2" /> : <Icon name="delivery" className="w-8 h-8 mr-2" />}
                            <div className="grow text-p md:text-md-p">
                                <div className="">{cart?.deliveryMethod?.title}</div>
                                {cart?.deliveryMethod?.config?.puntoVendita ? <><div className="">{pos?.title}</div>
                                    {cart.consegna?.giornoConsegna + " " + cart.consegna?.fasciaOraria} </> : <></>}
                            </div>
                        </div>
                        {!cart?.deliveryMethod?.config?.puntoVendita ? <>
                            {<><div className="text-p lg:text-md-p mt-4">
                                <div>{cart.indirizzoDestinazione?.nominativo || user?.nome + " " + user?.cognome}</div>
                                {cart.indirizzoDestinazione?.presso ? <div>{cart.indirizzoDestinazione?.presso}</div> : <></>}
                                <div>{cart.indirizzoDestinazione?.indirizzo}</div>
                                <div>{cart.indirizzoDestinazione?.cap + " " + cart.indirizzoDestinazione?.citta + " " + cart.indirizzoDestinazione?.provincia + " " + cart.indirizzoDestinazione?.paese}</div>
                            </div>
                                {cart.regalo ? <div className="rounded-sm inline-flex items-center bg-antoniazzi-900 text-white text-h5 lg:text-md-h5 px-1.5 py-1 mt-6"><Icon name="regalo" className="w-[16px] h-[18px] mr-1" /> {strings.get("è un regalo")}</div> : <></>}

                            </>
                            }
                            <button onClick={() => {
                                window.location.hash = "cart-destination";
                            }} className="btn btn-outline-primary rounded-sm w-full block text-center text-p lg:text-md-p mt-4">{strings.get("Modifica l'indirizzo di spedizione")}</button>

                        </> : <></>}
                    </>}


                </div>
                <div className="text-antoniazzi-900 bg-antoniazzi-300/50 pb-20 px-4 pt-6">
                    <div className="flex text-h4 md:text-md-h4 items-center justify-between mb-2">
                        <div>Subtotale <span className="text-p-sm md:text-md-p-sm">esclusi costi di consegna</span></div>
                        <div>{formatPrice(cart?.totalProducts) || formatPrice("0")}</div>
                    </div>
                    <div className="flex text-h4 md:text-md-h4 items-center justify-between">
                        <div>Spedizione</div>
                        <div>{totalDelivery > 0 ? formatPrice(totalDelivery) : "Gratuita"}</div>
                    </div>
                    <DiscountCouponPanel readonly={checkout} />
                    <div className="flex text-h3 md:text-md-h3 font-bold items-center justify-between">
                        <div>Totale</div>
                        <div>{formatPrice(total) || formatPrice("0")}</div>
                    </div>
                    {checkout ? <div className="my-8"><p className="text-p md:text-md-p mb-2">{strings.get("Paga ora con:")}</p>
                        {/*<PayPalButtons onApprove={onApprove} style={{ color: "black", tagline: false, label: "pay" }} createOrder={createOrder}></PayPalButtons>*/}
                        {/*<button className="btn block mt-8 w-full rounded-sm text-white bg-antoniazzi-900 hover:bg-antoniazzi-600" onClick={goToNexi}>Carta di credito</button>*/}
                        {!xpayPayload ?
                            <button className="btn block mt-8 w-full rounded-sm text-white bg-antoniazzi-900 hover:bg-antoniazzi-600" disabled >Carta di credito</button> :
                            <>
                                <form action={xpayPayload?.action}>
                                    <input type="hidden" value={xpayPayload?.alias} name="alias" />
                                    <input type="hidden" value={xpayPayload?.importo} name="importo" />
                                    <input type="hidden" value={xpayPayload?.codTrans} name="codTrans" />
                                    <input type="hidden" value={xpayPayload?.divisa} name="divisa" />
                                    <input type="hidden" value={xpayPayload?.mac} name="mac" />
                                    <input type="hidden" value={xpayPayload?.url} name="url" />
                                    <input type="hidden" value={xpayPayload?.url_back} name="url_back" />
                                    <input type="hidden" value={xpayPayload?.urlpost} name="urlpost" />

                                    <button type="submit" className="btn block mt-8 w-full rounded-sm text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">Carta di credito</button>

                                </form>
                            </>
                        }
                    </div> : <button onClick={confermaTipoConsegna} className="btn block mt-8 w-full rounded-sm text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">Concludi ordine</button>}
                </div>
            </> : <>
                <PuntoVenditaSelectionPanel />
                <div className="mx-4 pb-10">
                    <button onClick={confermaCarrello} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Conferma e prosegui")}</button>
                </div>
            </>}
        </>}
    </>);
}

function PuntoVenditaSelectionPanel() {
    const { dataBackend, cart, setCart, strings } = useFEContext();
    const [posList, setPosList] = useState<ContainerType[]>([]);
    const [selectedPos, setSelectedPos] = useState<ContainerType>();

    const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs>();
    const [selectedFasciaOrariaId, setSelectedFasciaOrariaId] = useState<number>();
    const [orari, setOrari] = useState<DataParticolareType>();

    let minDate = dayjs().startOf('day');
    if (selectedPos && selectedPos.config) {
        const conf = selectedPos.config;
        const h = conf.minGiorniRitiro !== undefined ? conf.minGiorniRitiro : 0;
        minDate = minDate.add(h > 0 ? h : 0, 'day');
    }


    useEffect(() => {
        dataBackend?.list({ entity: "Address" }).then((r) => {
            if (!r.error) {
                setPosList(r.records);
            }
        });
    }, []);
    useEffect(() => {
        if (cart?.consegna?.idPos) {
            dataBackend?.get({ entity: "Container", id: cart?.consegna?.idPos }).then((r) => {
                if (!r.error) {
                    setSelectedPos(r.records[0]);
                }
            });
        } else {
            setSelectedPos(undefined);
        }
    }, [cart]);

    const selectDay = (d: dayjs.Dayjs, or) => {

        const c = { ...cart };
        if (!c.consegna) {
            c.consegna = {};
        }
        if (d) {
            c.consegna.giornoConsegna = d.format("DD-MM-YYYY");
        }
        else {
            delete c.consegna.giornoConsegna;
        }
        delete c.consegna.fasciaOraria;
        setCart(c);
        setSelectedDay(d);
        setOrari(or);
        setSelectedFasciaOrariaId(-1);
    }
    const giorni = selectedPos?.config?.minGiorniRitiro || 1;
    return <div className="text-antoniazzi-900 pt-8 px-4">
        <h4 className="text-h1 md:text-md-h2 mb-6">Ritiro a Punto Vendita</h4>
        <div className="text-h3 md:text-md-h3">Seleziona il luogo del ritiro </div>
        {posList.map((p, pk) => {
            const selectPos = () => {
                const c = { ...cart };
                if (!c.consegna) {
                    c.consegna = {};
                }
                if (p)
                    c.consegna.idPos = p.id;
                else
                    delete c.consegna.idPos;
                setCart(c);
                setSelectedDay(undefined);
                setOrari(undefined);
                setSelectedFasciaOrariaId(undefined);
            };
            const checked = cart?.consegna?.idPos === p.id;
            return <button onClick={selectPos} key={pk} className={classNames("flex mt-2 w-full text-left")}>
                <div className={classNames("btn-radio shrink-0 grow-0 mr-1 lg:mr-10", checked ? "btn-radio-checked" : "")}></div>
                <div className="grow text-p md:text-md-p">
                    <div >{p.title}</div>
                    <div className="text-p-sm md:text-md-p-sm">{p.address?.indirizzo}</div>
                </div>
            </button>;
        })}
        <div>
            {selectedPos ? <div className="mt-8 pt-8 border-t border-antoniazzi-900">

                <div className="text-h3 md:text-md-h3 mb-2">Seleziona il giorno del ritiro </div>
                <div className="text-p mb-6">Prodotto disponibile tra {giorni} {giorni > 1 ? "giorni" : "giorno"}</div>
                <POSCalendar minDate={minDate} timetable={selectedPos.config.configurazioneOrari} value={selectedDay} onChange={selectDay} />
                {orari ? <>
                    <div className="text-h3 md:text-md-h3 mb-4 border-t border-antoniazzi-900 pt-8">Seleziona la fascia oraria</div>
                    <div className="flex w-full justify-stretch gap-x-2">
                        {orari?.orari?.map((o, ok) => {
                            const selected = selectedFasciaOrariaId === ok;
                            const selectFasciaOraria = () => {
                                setSelectedFasciaOrariaId(ok);
                                const fo = orari?.orari ? orari.orari[ok] : undefined;
                                if (fo) {
                                    const c = { ...cart };
                                    if (!c.consegna) {
                                        c.consegna = {};
                                    }
                                    c.consegna.fasciaOraria = fo.orarioApertura + " " + fo.orarioChiusura;
                                    setCart(c);
                                }
                            };
                            return <button className={classNames(selected ? "bg-antoniazzi-900 hover:bg-antoniazzi-600 text-white" : "", "btn border w-full border-antoniazzi-900")} onClick={selectFasciaOraria} key={ok}>{o.orarioApertura + " " + o.orarioChiusura}</button>
                        })}
                    </div>
                </> : <>
                    {selectedDay ? <div className="bg-bordeaux/25 p-4">{strings.get("Spiacenti, ma per la data scelta non ci sono fasce orarie disponibili per il ritiro")}</div> : <></>}
                </>}
            </div> : <></>}
        </div>
    </div>
}

function CartPanelRow(props: { readonly?: boolean, row: CartItemType, error?: AlertType, onDelete: () => void, onQuantityUpdate: Function }) {
    const { dataBackend, strings } = useFEContext();
    const [selectedQuantity, _setSelectedQuantity] = useState<number>(props.row.quantity);
    const [container, setContainer] = useState<ProductType>();
    const [image, setImage] = useState<ContainerRefType>();
    useEffect(() => {
        if (dataBackend) {
            dataBackend.get({ entity: "Product", id: props.row.idProduct }).then(c => {
                if (!c.error) {
                    const cur: ProductType = c.records[0];
                    setContainer(cur);
                    if (!cur.images || cur.images.length === 0) {
                        if (cur.container?.containerClass === 'PRODUCT') {
                            dataBackend.get({ entity: "Product", id: cur.idParentContainer }).then(pare => {
                                if (!pare.error) {
                                    const parProd: ProductType = pare.records[0];
                                    if (parProd.images && parProd.images.length > 0)
                                        setImage(parProd.images[0]);
                                }
                            })
                        }
                    } else {
                        setImage(cur.images[0]);
                    }
                }
            })
        }
    }, [props.row]);

    const setSelectedQuantity = (n: number) => {
        _setSelectedQuantity(n);
        props.onQuantityUpdate(n)
    }


    return <div className={classNames(props.error ? "ring ring-offset-8 rounded ring-bordeaux/25 text-bordeaux" : "")}>
        <div className="flex text-p md:text-md-p gap-x-4 mb-5 lg:mb-6 justify-stretch">
            <div className=" shrink-0 grow-0"><div className="aspect-w-1 w-28 lg:w-52 aspect-h-1">{image ? <img className="object-cover object-center" src={image.imageSrcs?.small} /> : <></>}</div></div>
            <div className="flex w-full grow-1 flex-col justify-between">
                <div>
                    <div className="font-bold lg:text-md-h3">{props.row.description}</div>
                    {props.row.customization ? <div className="text-h5-sm lg:text-md-h4 flex items-center mt-4 lg:mt-6"><Icon name="plus" className="block w-3 h-3 lg:w-4 lg:h-4 mr-2" /> {strings.get("personalizzazioni")}</div> : <></>}
                </div>
                <div className="flex border text-p-sm md:text-md-p border-antoniazzi-900 items-center w-full">
                    {!props.row.customization ? <button disabled={props.readonly} className="block pl-2 py-2 text-antoniazzi-900" onClick={() => { if (selectedQuantity > 1) { const newQ = selectedQuantity - 1; setSelectedQuantity(newQ) } }}><Icon name="minus" className="w-4 h-4" /></button> : <></>}
                    <div className="grow text-center py-3">{selectedQuantity}</div>
                    {!props.row.customization ? <button disabled={props.readonly} className="block pr-2 py-2 text-antoniazzi-900" onClick={() => { const newQ = selectedQuantity + 1; setSelectedQuantity(newQ); }}><Icon name="plus" className="w-4 h-4" /></button> : <></>}
                </div>
            </div>
            <div className="flex flex-col justify-between">
                <div className="text-right lg:text-md-h4">{formatPrice(props.row.priceStandard)}</div>
                {props.onDelete ? <button disabled={props.readonly} onClick={props.onDelete} className="block py-3 text-p-sm md:text-md-p px-4 border border-antoniazzi-900">{strings.get("Eliminare")}</button> : <></>}
            </div>
        </div>
    </div>
}


function DiscountCouponPanel(props: { readonly?: boolean }) {
    const { showAlert, dataBackend, cart, setCart } = useFEContext();
    const [codiceSconto, setCodiceSconto] = useState<string>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertType>();
    useEffect(() => {
        if (cart && cart.discountCoupon)
            setCodiceSconto(cart?.discountCoupon?.code)
    }, [cart])
    const applica = () => {
        if (props.readonly) return;
        if (codiceSconto) {
            const be = dataBackend?.endpointBase;
            if (be) {
                const url = new URL(be + "/wa/DataBackend/checkDiscountCoupon");
                url.searchParams.append("code", codiceSconto);
                dataBackend?.getJSON(url).then(d => {
                    if (!d.error) {
                        const disc: DiscountCouponType = d.records[0];
                        setCodiceSconto(disc.code);
                        const cc = { ...cart };
                        if (cc) {
                            const al: AlertType = { onCancel: () => { setAlert(undefined) }, active: true, message: "il codice di sconto digitato è valido ed è stato applicato", severity: "success" };
                            setAlert(al);
                            cc.discountCoupon = disc;
                            setCart(cc);
                        }

                    } else {
                        const al: AlertType = { onCancel: () => { setAlert(undefined) }, active: true, message: "il codice di sconto digitato non sembra essere valido" };
                        setAlert(al);
                    }
                }).catch(err => console.error(err));
            }
        }
    }

    const rimuovi = () => {
        if (props.readonly) return;
        const al: AlertType = {
            active: true, message: "Vuoi veramente rimuovere il codice sconto?", severity: "warning", confirm: true, onConfirm: () => {
                const cc = { ...cart };
                if (cc) {
                    delete cc.discountCoupon;
                    setCart(cc);
                    setCodiceSconto(undefined);
                    setShowDetails(false);
                    showAlert({ active: false });
                }
            }
        }
        showAlert(al);
    }
    return <div className="py-5 my-5 border-y border-antoniazzi-900/60">
        <button onClick={() => setShowDetails(!showDetails)} className="text-p md:text-md-p font-bold w-full flex items-center justify-between"><div>Hai un codice sconto?</div><Icon name="chevron" className={classNames("h-6 w-6", showDetails ? "rotate-90" : "")} /></button>
        {showDetails ? <> {!cart?.discountCoupon ?
            <div className="text-p md:text-md-p w-full flex mt-4 mb-1 items-center justify-between">
                <input readOnly={props.readonly} autoComplete="off" placeholder="Inserisci codice promozionale " className="placeholder:text-antoniazzi-900 text-p md:text-md-p grow border border-antoniazzi-900 bg-transparent py-3" type="text" value={codiceSconto} onChange={(ev) => {
                    setCodiceSconto(ev.target.value);
                }} />
                <button onClick={applica} className="py-3 px-4 border border-l-0 border-antoniazzi-900">Applica</button>
            </div>
            :
            <div className="text-p md:text-md-p w-full flex mt-4 mb-1 items-stretch justify-between">
                <div className="text-p md:text-md-p px-4 flex items-center grow border border-antoniazzi-900 bg-transparent py-2"><CheckIcon className="w-4 h-4 mr-2" /> <span className="font-mono rounded bg-antoniazzi-900/20 p-0.5">{cart.discountCoupon.code}</span></div>
                <button onClick={rimuovi} className="py-3 px-4 border border-l-0 border-antoniazzi-900">Rimuovi</button>
            </div>}</> : <></>}

        {alert ? <AlertInline alert={alert} /> : <></>}
    </div>
}
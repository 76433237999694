import React, { MouseEventHandler, ReactElement, useEffect, useRef, useState } from "react";
import { Icon } from "../IconLib";
import { ContainerType, ContainerRefType, PowerLayerType, ProductType, ProductRefType, formatPrice, productPriceDisplay } from "../Interfaces";
import { useFEContext } from "./FEContext";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { Pagination } from 'swiper/modules';
import { Link, useNavigate } from "react-router-dom";
import ProductsHighlights, { ProductsRelateds } from "./Blocks/ProductsHighlights";
import Personalizzazione from "./Subcomponents/Personalizzazione";

export function AddedToCartPowerLayer() {
    const nav = useNavigate();
    const { showPowerLayer, strings } = useFEContext();
    return <>
        <div className="px-10 pt-9 pb-20">
            <div className="text-center pb-6 mb-6 border-b border-antoniazzi-900">{strings.get("È stato aggiunto un articolo al carrello")}</div>
            <a href="#cart" className="btn mt-5 text-center block w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Vai al pagamento")}</a>
            <button onClick={() => { nav(-1); showPowerLayer({ active: false }) }} className="btn mt-5 text-center block border text-antoniazzi-900 w-full  border-antoniazzi-900 bg-white hover:bg-antoniazzi-600">{strings.get("Continua gli acquisti")}</button>
            <p className="text-p-sm lg:text-md-p-sm pt-6 mt-6 border-t text-center border-antoniazzi-900">{strings.get("Effettuando l’ordine, accetti i")}<Link className="underline hover:no-underline" onClick={() => { window.location.hash = "" }} to="/termini-utilizzo">{" " + strings.get("termini d'utilizzo")}</Link></p>
        </div>

    </>;
}

export default function ViewProdotto(props: { prodotto: ProductType }) {
    const prodotto: ProductType = props.prodotto;
    const [selectedSize, setSelectedSize] = useState<ProductType>();
    const [relateds, setRelateds] = useState<ProductType[]>();
    const { cart, dataBackend, setCart, showPowerLayer, strings } = useFEContext();
    const [showSizes, setShowSizes] = useState<boolean>(false);
    const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
    const swiperElRef = useRef(null);
    const sizes = prodotto && prodotto.sizes ? prodotto.sizes.filter(p => p.sell) : undefined;
    let price = prodotto ? (selectedSize ? selectedSize.priceStandard : (sizes && sizes?.length > 0 ? sizes[0].priceStandard : prodotto.priceStandard)) : "";
    let priceDisplay = prodotto ? (selectedSize ? selectedSize.priceDisplay : (sizes && sizes?.length > 0 ? sizes[0].priceDisplay : prodotto.priceDisplay)) : "";
    let percentualeSconto = prodotto ? (selectedSize ? selectedSize.discount : (sizes && sizes?.length > 0 ? sizes[0].discount : prodotto.discount)) : "";

    useEffect(() => {
        const swiperEl = swiperElRef.current;
        const params = {
            modules: [Pagination],
        };
        //@ts-ignore
        Object.assign(swiperEl, params);
        //@ts-ignore
        swiperEl.initialize();

    }, []);

    useEffect(() => {
        if (props.prodotto && props.prodotto.relatedProducts) {
            dataBackend.getAll(props.prodotto.relatedProducts).then(r => {
                if (!r.error) {
                    setRelateds(r.records);
                }
            })
        }
    }, [props.prodotto]);




    const startCustomization = () => {
        const prod = selectedSize || (sizes && sizes.length > 0 ? sizes[0] : prodotto);
        showPowerLayer({ active: true, content: <Personalizzazione prodotto={prodotto} size={prod} /> })
    };
    const addToCart = () => {
        const ca = cart ? { ...cart } : {};
        if (!ca.rows) {
            ca.rows = [];
        }
        if (ca?.deliveryMethod) {
            const dm = prodotto.deliveryMethods?.filter(dm => dm.id === ca.deliveryMethod?.id);
            if (dm && dm.length > 0) {
                if (!dm[0].valid) {
                    ca.deliveryMethod = undefined;
                }
            }
        }
        const prod = selectedSize || (sizes && sizes.length > 0 ? sizes[0] : prodotto);
        let found = false;
        for (let index = 0; index < ca.rows.length; index++) {
            const element = ca.rows[index];
            if (element.idProduct === prod.id && !element.customization) {
                found = true;
                element.quantity = element.quantity + selectedQuantity;
                break;
            }
        }
        if (!found) {
            const desc = prod.id !== prodotto.id ? prodotto.title + " " + prod.title : prodotto.title;
            ca.rows.push({ deliveryMethods: prodotto.deliveryMethods, priceStandard: prod.priceStandard, priceDisplay: prod.priceDisplay, idProduct: prod.id, quantity: selectedQuantity, description: desc || "senza nome" });
        }
        const al: PowerLayerType = {
            active: true, content: <AddedToCartPowerLayer />
        };

        showPowerLayer(al);
        setSelectedQuantity(1);
        setCart(ca);
    }

    if (!prodotto) return <></>;

    return <>
        <div className="lg:flex lg:items-stretch lg:justify-between max-w-5xl mx-auto lg:pb-20 pt-1 lg:pt-7">
            <div className="mb-7 w-full lg:w-[560px] lg:shrink-0">
                <div className="lg:hidden swiper-prodotto">
                    {/*@ts-ignore*/}
                    <swiper-container init="false" ref={swiperElRef} pagination="true">{prodotto.images?.map((img, aak) => { return <swiper-slide class="aspect-w-1 aspect-h-1" key={aak}><img className="object-cover object-center" src={img.src} /></swiper-slide> })}</swiper-container>
                </div>
                <div className="hidden lg:grid lg:gap-2 lg:grid-cols-2">
                    {prodotto.images?.map((img, aak) => {
                        return <div className={classNames(aak === 0 ? "lg:col-span-2" : "", "aspect-w-1 aspect-h-1")} key={aak}><img className="object-cover object-center" src={img.src} /></div>
                    })}
                </div>
            </div>
            <div className="lg:w-[431px] lg:grow-0 lg:shrink-0">

                {prodotto.newProduct ? <div className="mx-4 lg:mx-0 text-antoniazzi-600 mb-2 lg:mb-6">
                    <h6 className="text-p lg:text-md-h4">{strings.get("Novità")}</h6>
                </div> : <></>}
                {prodotto.sell ? <>
                    {percentualeSconto && percentualeSconto > 0 ? <div className="mx-4 lg:mx-0 mb-2 lg:mb-6 text-antoniazzi-600 flex  items-center justify-between">
                        <div className="text-p lg:text-md-h4">{strings.get("Sconto del") + " " + percentualeSconto + "%"}</div>
                        <div className="text-p lg:text-md-h4 line-through">{formatPrice(priceDisplay)}</div>
                    </div> : <></>}

                </> : <></>}

                <div className="mx-4 lg:mx-0 text-antoniazzi-900 flex mb-2 lg:mb-6 justify-between">
                    <div className="text-h2 lg:text-md-h2">{prodotto.title} </div>
                    {prodotto.sell ? <div className="text-h2 lg:text-md-h2 ml-4">{formatPrice(price)}</div> : <></>}
                </div>
                <div className="mx-4 lg:mx-0 text-antoniazzi-900 mb-7 lg:mb-9 text-p-sm lg:text-md-p">{strings.get("Referenza") + ": "}{prodotto.productorCode || "n.d."}</div>
                <div className="mx-4 lg:mx-0 text-antoniazzi-90 mb-7 lg:mb-9 text-p lg:text-md-p" dangerouslySetInnerHTML={{ __html: prodotto.introduction || "" }}></div>

                {prodotto.sell ? <>
                    {sizes && sizes.length > 0 ?
                        <div className="mx-4 lg:mx-0 text-antoniazzi-900 mb-2">
                            <SizeOption className="flex items-center justify-between w-full border border-antoniazzi-900 px-4 py-2.5 text-p lg:text-md-p" onClick={() => { setShowSizes(!showSizes) }} size={selectedSize || sizes[0]}><Icon name="chevron" className={classNames(showSizes ? "rotate-90" : "", "w-6 h-6")} /></SizeOption>
                            {showSizes ?
                                <div className="relative">
                                    <div className="absolute bg-white border border-antoniazzi-900 border-t-0 w-full top-0 left-0 z-40">
                                        {sizes.map((pr, prk) => {
                                            return <SizeOption onClick={() => { setSelectedSize(pr); setShowSizes(false) }} size={pr} key={prk} />;
                                        })}
                                    </div>
                                </div> : <></>}
                        </div> :
                        <></>
                    }

                    <div className="mx-4 lg:mx-0">
                        <div className="flex border border-antoniazzi-900 items-center w-full">
                            <button className="block pl-4 py-3 text-antoniazzi-900" onClick={() => { if (selectedQuantity > 1) setSelectedQuantity(selectedQuantity - 1) }}><Icon name="minus" className="w-5 h-5" /></button>
                            <div className="grow text-center">{selectedQuantity}</div>
                            <button className="block pr-4 py-3 text-antoniazzi-900" onClick={() => { setSelectedQuantity(selectedQuantity + 1) }}><Icon name="plus" className="w-5 h-5" /></button>
                        </div>
                    </div>

                    {prodotto.accessories && prodotto.accessories.length > 0 ? <>
                        <div className="mx-4 lg:mx-0 mt-5">
                            <button onClick={startCustomization} className="btn block w-full bg-white border border-antoniazzi-900 text-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Personalizza il tuo dolce (opzionale)")}</button>
                        </div>
                    </> : <></>}
                    <div className="mx-4 lg:mx-0 mt-5">
                        <button onClick={addToCart} className="btn block w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Aggiungi al carrello")}</button>
                    </div>
                </> :
                    <>

                        {/*<div className="mx-4 lg:mx-0 opacity-60 cursor-not-allowed">
                            <div className="flex border border-antoniazzi-900 items-center w-full">
                                <button className="block pl-4 py-3 text-antoniazzi-900 cursor-not-allowed"><Icon name="minus" className="w-5 h-5" /></button>
                                <div className="grow text-center">{selectedQuantity}</div>
                                <button className="block pr-4 py-3 text-antoniazzi-900 cursor-not-allowed"><Icon name="plus" className="w-5 h-5" /></button>
                            </div>
                        </div>
                        <div className="mx-4 lg:mx-0 mt-5 opacity-60">
                            <button className="btn block w-full text-white bg-antoniazzi-900 cursor-not-allowed">{strings.get("Non acquistabile online")}</button>
                    </div>*/}


                    </>}
                <div className="flex flex-col gap-y-7 py-9 lg:py-14">
                    <>{prodotto.deliveryMethods?.map((dm, dmk) => {
                        const isValid = dm.valid && prodotto.sell;
                        return <div key={dmk} className={classNames("mx-4 lg:mx-0 relative pl-16", !isValid ? "text-bordeaux" : "")}>
                            {dm.config?.puntoVendita ? <Icon name="shop" className="w-10 h-10 absolute left-0 top-1/2 -translate-y-1/2" /> : <Icon name="delivery" className="w-10 h-10 absolute left-0 top-1/2 -translate-y-1/2" />}
                            <div className="grow text-p-sm lg:text-md-h5">
                                <div className="font-bold">{dm.title}: {isValid ? <span className="underline">{strings.get("disponibile")}</span> : <span className="underline">{strings.get("non disponibile")}</span>}</div>
                                {prodotto.sell ? dm.notes : strings.get("Prodotto acquistabile esclusivamente nei punti vendita")}
                            </div>
                        </div>;
                    })}</>

                </div>
                {prodotto.contents && prodotto.contents.length > 0 ?
                    <div className="bg-antoniazzi-300/50 divide-y divide-antoniazzi-300 ">
                        {prodotto.contents?.map((pc, pck) => {
                            return <AccordionContainer key={pck} containerRef={pc} />
                        })}
                    </div> : <></>}</div>
        </div>

        {relateds && relateds.length > 0 ? <div className="mt-10 border-t lg:mt-0 lg:border-t-0 border-antoniazzi-300">{<ProductsRelateds items={relateds} />}</div> : <></>}
    </>;
}



function SizeOption(props: { size: ProductRefType, children?: ReactElement, className?: string, onClick?: MouseEventHandler<HTMLButtonElement> }) {
    const { dataBackend } = useFEContext();
    const sizeRef: ProductRefType = props.size;
    const [size, setSize] = useState<ProductType>();
    useEffect(() => {
        dataBackend?.get(sizeRef).then(d => { if (!d.error) setSize(d.records[0]) });
    }, [sizeRef])
    const pd = productPriceDisplay(props.size);
    return <button className={classNames(props.className ? props.className : "flex text-p lg:text-md-p items-center justify-between w-full px-4 py-2.5 hover:bg-antoniazzi-300/20 border-t-0")} onClick={props.onClick}>{size?.title} {props.children ? props.children : <div> {


        formatPrice(size?.priceStandard)

    }</div>}</button>
}

function AccordionContainer(props: { containerRef: ContainerRefType }) {
    const { dataBackend } = useFEContext();
    const [container, setContainer] = useState<ContainerType>();
    useEffect(() => {
        if (props.containerRef) {
            dataBackend?.get(props.containerRef).then((d) => {
                if (!d.error) {
                    setContainer(d.records[0]);
                }
            })
        }
    }, [props.containerRef]);
    const header = <>{container ? <div className="font-bold lg:text-[1.13rem] text-antoniazzi-900">{container.title}</div> : <></>}</>;
    const content = <>{container ? <div className="mt-2 mb-6 text-sm lg:text-base text-antoniazzi-900" dangerouslySetInnerHTML={{ __html: container?.description || "" }}></div> : <></>}</>;
    return <>{container ? <div className="mx-4"> <Accordion className="py-4" header={header} content={content} /></div> : <></>}</>;
}

interface AccordionPropsType {
    header: ReactElement
    content: ReactElement
    expanded?: boolean
    className?: string
}
function Accordion(props: AccordionPropsType) {
    const [expanded, setExpanded] = useState<boolean>(props.expanded || false);

    return <>
        <button className={classNames(props.className, "flex w-full items-center justify-between")} onClick={() => { setExpanded(!expanded) }}>{props.header} <Icon name="chevron" className={classNames(expanded ? "rotate-90" : "", "w-6 h-6")} /></button>
        <Transition className="pb-5" show={expanded}>{props.content}</Transition>
    </>
}
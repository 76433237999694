import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Multimedia from "../Subcomponents/Multimedia";
import { Icon } from "../../IconLib";

export default function BodyArticle(props: { index: number, container: ContainerType, secondary?: boolean, dark?: boolean, head?: boolean, titleHead?: boolean }) {
    const {strings} = useFEContext();
    let imagesList: ContainerRefType[] = [];
    let ctas: ContainerRefType[] | undefined = undefined;
    useEffect(() => {

    }, [props.container]);


    if (props.container) {
        if (props.container.containers) {
            imagesList = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT" && c.position === 'List');
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }

    const hasCta = props.container.routeTo !== undefined || props.container.externalUrl !== undefined;

    const wide = props.container.config ? props.container.config.wide : false;

    const ctaLabel = props.container.config.ctaLabel || strings.get("Scopri di più");

    const hasBlocksCTA = ctas && ctas.length > 0;
    const head = props.head || false;

    return <>
        <section data-container-id={props.container.id} className={classNames(
            "mx-auto",
            wide ? "max-w-7xl" : "max-w-5xl",
            "riconoscimenti-article",
            props.container.className
        )}>
            <div>
                {props.container.showTitle ? <div className="px-4 xl:px-0 mb-5">
                    {props.container.title ? <h3 className={classNames("title text-h2 lg:text-md-h3")}>{props.container.title}</h3> : <></>}
                    {props.container.subtitle ? <h4 className="subtitle text-[.81rem] md:text-base">{props.container.subtitle}</h4> : <></>}
                </div> : <></>}
                <div className={classNames("px-4 xl:px-0", hasBlocksCTA ? "md:grid md:grid-cols-2 md:gap-x-6" : "")}>
                    <div>
                        {props.container.showContentIntro && props.container.introduction ?
                            <div className={classNames("prose prose-antoniazzi max-w-none introduction text-p md:text-md-p")} dangerouslySetInnerHTML={{ __html: props.container.introduction || "" }} /> : <></>}
                        {props.container.description ?
                            <div className={classNames("prose prose-antoniazzi max-w-none description text-p md:text-md-p")} dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}
                        {hasCta ? <div className="pt-6">
                            {props.container.externalUrl ? <a className=" inline-flex items-center underline text-sm md:text-base" href={props.container.externalUrl}>{ctaLabel} <Icon name="chevron" className="ml-2 w-4 h-4" /></a> : <></>}
                            {props.container.routeTo ? <Link className=" inline-flex items-center underline text-sm md:text-base" to={props.container.routeTo}>{ctaLabel} <Icon name="chevron" className="ml-2 w-4 h-4" /></Link> : <></>}
                        </div> : <></>}
                    </div>
                </div>
            </div>
            {imagesList.length > 0 ? <div className="flex justify-center flex-wrap mt-12 mx-4 lg:mx-auto gap-8">
                {imagesList.map((i, k) => {
                    return <Multimedia loading="lazy" key={k} containerRef={i} className="w-[90px] grow-0 shrink-0" medium />
                })}
            </div> : <></>}
        </section>
    </>;
}


export function Riconoscimenti(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const secondary = props.container.config.secondary || false;
    const dark = props.container.config.dark || false;
    const head = props.container.config.head || false;
    const titleHead = props.container.config.titleHead || false;
    const keepDistances = props.container.config.keepDistances || "";
    const articlesReact =
        articles.map((ba, bak) => {
            return <BodyArticle dark={dark} index={bak} container={ba} key={bak} head={head} titleHead={titleHead} />
        })

    return (
        <section className={classNames(
            "py-7",
            secondary ? "bg-antoniazzi-300/50 text-antoniazzi-900 riconoscimenti-articles-secondary" : "",
            dark ? "bg-dark text-white md:bg-white md:text-antoniazzi-900 riconoscimenti-articles-dark" : "",
            !secondary && !dark ? "bg-white text-antoniazzi-900" : "",
            "riconoscimenti-articles",
           "mx-auto max-w-5xl lg:px-8 lg:text-center"
        )}>
            {articlesReact}
        </section>
    );
}
import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../../IconLib";

export default function Articolo(props: { index: number, container: ContainerType, secondary?: boolean, dark?: boolean, large?: boolean }) {

    let ctas: ContainerRefType[] | undefined = undefined;
    if (props.container) {
        if (props.container.containers) {
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }
    const wide = props.container.config ? props.container.config.wide : false;

    return <>
        <section data-container-id={props.container.id} className={classNames(
            "mx-auto", wide ? "max-w-7xl" : "max-w-5xl", props.container.className
        )}>
            {props.container.showTitle ? <div className={classNames("px-4 xl:px-0", props.index === 0 ? "mb-6 lg:mb-8" : "mb-7 lg:mb-10")}>
                {props.container.title ? <h3 className={classNames(props.index === 0 ? "text-h1 lg:text-md-h2" : "text-h2 lg:text-md-h3")}>{props.container.title}</h3> : <></>}
                {props.container.subtitle ? <h4 className="subtitle text-h5 lg:text-md-p text-antoniazzi-600">{props.container.subtitle}</h4> : <></>}
            </div> : <></>}
            {props.large ? <div className="px-4 xl:px-0 text-h3 lg:text-md-h4" dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}
            {!props.large ? <div className="px-4 xl:px-0 text-p lg:text-md-p" dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}
            {ctas && ctas.length > 0 ? <div className="px-4 xl:px-0 mt-6 lg:mt-8">{ctas.map((cta, ctak) => {
                return <div key={ctak} >
                    {cta.routeTo ? <Link className="underline hover:no-underline flex items-center text-p lg:text-md-p" to={cta.routeTo || "/"} >
                        {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                    </Link> : <></>}
                    {cta.externalUrl ? <a className="underline hover:no-underline flex items-center text-p lg:text-md-p" href={cta.externalUrl || "/"}>
                        {cta.title} <Icon name="chevron" className="ml-5 w-5 h-5" />
                    </a> : <></>}</div>
            })}</div> : <></>}
        </section>
    </>;
}

export function IntroSection(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    const paddingBottom = props.container.config.paddingBottom || false;
    const paddingTop = props.container.config.paddingTop || false;
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const articlesReact =
        articles.map((ba, bak) => {
            return <Articolo large={false} index={bak} key={bak} container={ba} />
        })

    return (
        <section className={classNames(paddingTop ? "pt-8 lg:pt-16" : "", paddingBottom ? "pb-8 lg:pb-14" : "", "flex flex-col gap-y-8 lg:gap-y-14 intro-section")}>
            {articlesReact}
        </section>
    );
}
export function IntroSectionLg(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    const paddingBottom = props.container.config.paddingBottom || false;
    const paddingTop = props.container.config.paddingTop || false;
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const articlesReact =
        articles.map((ba, bak) => {
            return <Articolo large={true} index={bak} key={bak} container={ba} />
        })

    return (
        <section className={classNames(paddingTop ? "pt-8 lg:pt-16" : "", paddingBottom ? "pb-10 lg:pb-16" : "", "flex flex-col gap-y-8 lg:gap-y-16 intro-section")}>
            {articlesReact}
        </section>
    );
}
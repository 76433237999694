import React, { useCallback, useEffect, useState } from "react";
import { AlertInline, useFEContext } from "./FEContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { AlertType } from "../Interfaces";
import { Icon } from "../IconLib";
export default function ViewResetPassword() {
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const [password, setPassword] = useState<string>("");
    const [currentAlert, setCurrentAlert] = useState<AlertType>();
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordInputType, setPasswordInputType] = useState<"password" | "text">("password");
    const nav = useNavigate();
    const { showAlert, cart, strings, dataBackend, refreshSession } = useFEContext();
    let [searchParams, setSearchParams] = useSearchParams();
    const uid = searchParams.get("uid");
    const cc = searchParams.get("cc");
    const create = searchParams.get("create") && searchParams.get("create") === "true";

    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);

    const update = () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (uid && cc) {
            dataBackend.updatePassword(uid, cc, password, confirmPassword, create ? "true" : undefined, recaptchaToken).then(resp => {
                const al: AlertType = { active: true }
                if (!resp.error) {
                    al.severity = "success";
                    al.message = resp.message || strings.get("Operazione riuscita")
                    al.onClick = () => {
                        showAlert({ active: false });
                        if (cart && cart.rows && cart.rows.length > 0)
                            nav("/profilo" + (create ? "#checkout" : ""));
                        else
                            nav("/profilo");
                        refreshSession();
                    }
                    showAlert(al);
                } else {
                    al.severity = "error";
                    al.onCancel = () => { setCurrentAlert(undefined) };
                    al.message = resp.message || strings.get("Si è verificato un errore");
                    setCurrentAlert(al);
                }
            });
        }
    }
    const onVerify = useCallback((token: string) => {
        //console.log(token);
        setRecaptchaToken(token);
    }, []);


    return <div className="bg-white text-antoniazzi-900 py-8 min-h-[65vh]">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
        <div className="mx-4 max-w-5xl lg:mx-auto ">
            <h3 className="text-h1 md:text-md-h1 mb-6">{create ? strings.get("Crea la tua password") : strings.get("Aggiorna la password")}</h3>
            <div className="flex flex-col md:flex-row gap-2">
                <div className="form-control-alt !flex items-center">
                    <input className="grow-1" placeholder={strings.get("Nuova password")} type={passwordInputType} value={password} onChange={(ev) => { setPassword(ev.target.value) }} />
                </div>
                <div className="form-control-alt !flex items-center">
                    <input className="form-control-alt" placeholder={strings.get("Conferma la nuova password")} type={passwordInputType} value={confirmPassword} onChange={(ev) => { setConfirmPassword(ev.target.value) }} />
                    <button className="px-4" onClick={() => { if ("text" === passwordInputType) setPasswordInputType("password"); else setPasswordInputType("text") }}>{passwordInputType === 'password' ? <Icon name="occhio" className="w-[25px] h-[16px]" /> : <Icon name="occhio-barrato" className="w-[25px] h-[16px]" />}</button>
                </div>
            </div>
            <p className="text-p lg:text-md-p mt-4">{strings.get("La password richiede una lunghezza minima di 8 caratteri e deve includere almeno un carattere maiuscolo, un numero e un carattere speciale (Es. !?$*,.-+)")}</p>
            {currentAlert ? <AlertInline alert={currentAlert} /> : <></>}
            <button onClick={update} className="btn bg-antoniazzi-900 w-full md:w-auto hover:bg-antoniazzi-600 mt-4 text-white">{create ? strings.get("Attiva l'account") : strings.get("Modifica la password")}</button>

        </div>
    </div>
}

import React, { useCallback, useEffect, useState } from "react";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { AlertType } from "../../Interfaces";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Icon } from "../../IconLib";
import { Link } from "react-router-dom";

interface DataCandidaturaType {
    subject?: string
    nome?: string
    cognome?: string
    telefono?: string
    email?: string
    paese?: string
    indirizzo?: string
    citta?: string
    provincia?: string
    note?: string
    recaptcha?: string
    privacy?: boolean

}
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}
const initData: DataCandidaturaType = {
    email: "",
    telefono: "",
    nome: "",
    paese: "",
    provincia: "",
    citta: "",
    note: "",
    cognome: "",
    indirizzo: "",
    recaptcha: "",
    privacy: false
}
const maxFileSize = 5 * 1024 * 1024;
export default function Candidatura() {

    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const { strings, user, dataBackend, showAlert } = useFEContext();
    const [curriculumFile, setCurriculumFile] = useState<File>();
    const [data, setData] = useState<DataCandidaturaType>({});
    const onChange = (ev: any) => {
        const name = ev.target.name;
        if (name) {
            const dd = { ...data };
            dd[name] = ev.target.value;
            setData(dd);
        }
    }
    const inviaRichiestaCandidatura = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setRefreshReCaptcha(new Date().getTime() + "");
        const al: AlertType = { active: true };
        if (!data.privacy)
            al.message = strings.get("Accettare le condizioni di privacy")
        if (!data.email)
            al.message = strings.get("Email obbligatoria")
        else if (!data.telefono)
            al.message = strings.get("Telefono obbligatorio")
        else if (!data.nome)
            al.message = strings.get("Nome obbligatorio")
        else if (!data.cognome)
            al.message = strings.get("Cognome obbligatorio")
        else if (!data.paese)
            al.message = strings.get("Paese obbligatorio")
        else if (!data.indirizzo)
            al.message = strings.get("Indirizzo obbligatorio")
        else if (!data.citta)
            al.message = strings.get("Città obbligatoria")
        else if (!data.provincia)
            al.message = strings.get("Provincia obbligatoria")
        else if (!recaptchaToken)
            al.message = strings.get("Recaptcha obbligatorio")
        if (!al.message) {
            const dd = { ...data };
            dd.subject = window.location.hash.split("_")[1];
            dd.recaptcha = recaptchaToken;
            fetch(dataBackend.endpointBase + "/wa/ToolsAction/candidatura", {
                method: "POST",
                body: JSON.stringify(dd)
            }).then(d => d.json()).then((r: any) => {
                if (r.error) {
                    al.severity = 'error';
                    al.message = r.message || strings.get("Si è verificato un errore")
                    showAlert(al);
                } else {
                    const confermaUrl = dataBackend.endpointBase + "/wa/ToolsAction/confermaCandidatura";
                    let opts: any = {};
                    if (curriculumFile) {
                        const formData = new FormData();
                        formData.append("upload", curriculumFile);
                        opts = {
                            method: "POST",
                            headers: {
                                "Content-Type": "multipart/form-data",
                                "x-requestid": r.requestId
                            },
                            body: formData
                        }
                    } else {
                        opts = {
                            method: "POST",
                            headers: {
                                "x-requestid": r.requestId
                            }
                        }
                    }
                    fetch(confermaUrl, opts).then(conf => conf.json()).then(jsonConf => {
                        if (!jsonConf.error) {
                            al.severity = 'success';
                            al.message = r.message || strings.get("La candidatura è stata inviata con successo");
                            setCurriculumFile(undefined);
                            setData(initData);
                        }
                        else {
                            al.severity = 'error';
                            al.message = r.message || strings.get("Si è verificato un errore")
                        }
                        showAlert(al);
                    })
                }
            })
        } else {
            al.severity = "error";
            showAlert(al);
        }
        return false;
    }

    useEffect(() => {
        if (user?.confermato) {
            const d = { ...data };
            if (user.email)
                d.email = user?.email;
            if (user.nome)
                d.nome = user?.nome;
            if (user.cognome)
                d.cognome = user?.cognome;
            if (user.nome && user.telefono)
                d.telefono = user?.telefono;
            setData(d);
        }
    }, [user])
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);
    return <>
        <div className="text-antoniazzi-900 px-4 pb-16">
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
            <h3 className="text-h1 md:text-md-h1 mt-10 mb-4">{strings.get("Candidatura")}</h3>
            <p className="text-p-sm md:text-md-p-sm mb-6">{"*" + strings.get("campi obbligatori")}</p>

            <div className="form-floating">
                <input onChange={onChange} value={data.nome} placeholder="nome" name="nome" type="text" required className="form-floating-control" />
                <label htmlFor="nome">{strings.get("Nome") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} value={data.cognome} placeholder="cognome" name="cognome" type="text" required className="form-floating-control" />
                <label htmlFor="cognome">{strings.get("Cognome") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} value={data.telefono} placeholder="telefono" name="telefono" type="tel" required className="form-floating-control" />
                <label htmlFor="telefono">{strings.get("Telefono") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} value={data.email} name="email" type="email" required className="form-floating-control" placeholder={strings.get("Email") + "*"} />
                <label htmlFor="email">{strings.get("E-mail") + "*"}</label>
            </div>
            <div className="form-floating">
                <select name="paese" className="form-floating-control" required value={data.paese} onChange={(ev) => { const u: DataCandidaturaType = { ...data }; u.paese = ev.target.value; setData(u) }} placeholder={strings.get("Paese") + "*"}><option value="">{strings.get("Seleziona...")}</option>{dataBackend.paesi.map((val, valk) => {
                    return <option key={valk} value={val.value}>{val.label}</option>;
                })}
                </select>
                <label htmlFor="paese">{strings.get("Paese") + "*"}</label></div>
            <div className="form-floating">
                <input onChange={onChange} value={data.indirizzo} placeholder="indirizzo" name="indirizzo" type="text" required className="form-floating-control" />
                <label htmlFor="indirizzo">{strings.get("Indirizzo (via e numero civico)") + "*"}</label>
            </div>
            <div className="form-floating">
                <input onChange={onChange} value={data.citta} placeholder="citta" name="citta" type="text" required className="form-floating-control" />
                <label htmlFor="citta">{strings.get("Città") + "*"}</label>
            </div>
            {data.paese === 'IT' ?
                <div className="form-floating">
                    <select name="provincia" placeholder={strings.get("Provincia")} className="form-floating-control" required value={data.provincia} onChange={(ev) => { const u: DataCandidaturaType = { ...data }; u.provincia = ev.target.value; setData(u) }}><option value="">{strings.get("Seleziona...")}</option>{dataBackend.province.map((val, valk) => {
                        return <option key={valk} value={val.value}>{val.label}</option>;
                    })}
                    </select>
                    <label htmlFor="provincia">{strings.get("Provincia") + "*"}</label>

                </div> : <>
                    <div className="form-floating">
                        <input onChange={onChange} value={data.provincia} placeholder="provincia" name="provincia" type="text" required className="form-floating-control" />
                        <label htmlFor="provincia">{strings.get("Provincia") + "*"}</label>
                    </div>
                </>}
            <div className="mb-5 border-t  border-antoniazzi-900">
                {!curriculumFile ? <>
                    <button className="btn cursor-pointer text-center block relative mt-5 rounded-sm w-full border border-antoniazzi-900 bg-white text-antoniazzi-900 hover:bg-antoniazzi-900 hover:text-white">

                        {strings.get("Carica il tuo curriculum (PDF/DOC, Max 5MB)")}
                        <input onChange={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();

                            if (ev.target.files && ev.target.files.length === 1) {
                                const f = ev.target.files[0];
                                if (f.size <= maxFileSize)
                                    setCurriculumFile(ev.target.files[0])
                                else {
                                    const al: AlertType = { active: true, message: strings.get("Il documento selezionato è troppo grande"), severity: "warning", title: strings.get("Attenzione") }
                                }
                            }
                        }} type="file" className="absolute opacity-0 top-0 left-0 bottom-0 right-0"

                            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        />

                    </button>
                </> : <>
                    <button onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setCurriculumFile(undefined);
                    }} className="btn cursor-pointer flex items-center text-center justify-between relative mt-5 rounded-sm w-full border border-antoniazzi-900 bg-white text-antoniazzi-900 hover:bg-antoniazzi-900 hover:text-white">

                        {humanFileSize(curriculumFile.size) + " " + curriculumFile.name + " "} <span className="mr-2 rounded-full p-0.5 bg-bordeaux text-white"><Icon name="minus" className="w-3 h-3" /></span>

                    </button>
                </>}

            </div>
            <div className="">
                <div className="text-[0.8rem] text-antoniazzi-900/70 px-3">{strings.get("Messaggio")}</div>
                <textarea className="mt-2 block border-antoniazzi-900 w-full" name="note" value={data.note} onChange={onChange} />
            </div>

            <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...data }; u.privacy = !u.privacy; setData(u) }}>
                <div className={classNames("btn-checkbox mr-2", !data.privacy ? "" : "btn-checkbox-checked")} />
                <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
            </button>

            <button onClick={inviaRichiestaCandidatura} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Invia candidatura")}</button>

        </div>
    </>;
}

import React, { useEffect, useState } from "react";
import { ContainerType, ContainerRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Multimedia from "../Subcomponents/Multimedia";
import { Icon } from "../../IconLib";

export default function BodyArticle(props: { index: number, container: ContainerType, secondary?: boolean, dark?: boolean, head?: boolean, titleHead?: boolean }) {
    let leftImage: ContainerRefType | undefined = undefined;
    let rightImage: ContainerRefType | undefined = undefined;
    let topImage: ContainerRefType | undefined = undefined;
    let bottomImage: ContainerRefType | undefined = undefined;
    let defaultImage: ContainerRefType | undefined = undefined;
    let imagesList: ContainerRefType[] = [];
    let ctas: ContainerRefType[] | undefined = undefined;
    let hasImages = false;
    const { strings } = useFEContext();
    useEffect(() => {

    }, [props.container]);


    if (props.container) {
        if (props.container.containers) {
            const _images = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT");
            _images?.forEach(element => {
                if (element.position === "Left") leftImage = element;
                else if (element.position === "Right") rightImage = element;
                else if (element.position === "Top") topImage = element;
                else if (element.position === "Bottom") bottomImage = element;
                else if (element.position === "Center") defaultImage = element;
            });
            hasImages = leftImage !== undefined || rightImage !== undefined || topImage !== undefined || bottomImage !== undefined || defaultImage !== undefined;
            imagesList = props.container.containers?.filter(c => c.containerClass === "MULTIMEDIA_CONTENT" && c.position === 'List');
            const _blocks = props.container.containers?.filter(c => c.containerClass === "BLOCK");
            ctas = _blocks;
        }
    }

    const hasSideImage = leftImage !== undefined || rightImage !== undefined || defaultImage !== undefined;

    const hasCta = props.container.routeTo !== undefined || props.container.externalUrl !== undefined;

    const wide = props.container.config ? props.container.config.wide : false;

    const ctaLabel = props.container.config.ctaLabel || strings.get("Scopri di più");

    const hasBlocksCTA = ctas && ctas.length > 0;
    const head = props.head || false;
    const titleHead = props.titleHead || false;

    return <>
        <section data-container-id={props.container.id} className={classNames(
            "mx-auto",
            wide ? "max-w-7xl" : "max-w-5xl",
            hasSideImage ? "flex flex-col md:grid items-center md:grid-cols-2 md:gap-x-6" : "",
            topImage ? "flex flex-col" : "",
            "body-article",
            props.container.className
        )}>

            {defaultImage ? <Multimedia loading="lazy" containerRef={defaultImage} large className={classNames("block w-full mb-6 lg:mb-0", props.index % 2 !== 0 ? " md:order-3" : "")} /> : <></>}

            <div className={classNames(topImage ? "order-1" : "", hasSideImage ? "pb-6 md:pb-0" : "", props.dark ? "order-1" : "", "relative md:order-2")}>


                {props.container.showTitle ? <div className="px-4 xl:px-0 mb-5 md:mb-7">
                    {props.container.title ? <h3 className={classNames("title", titleHead && props.index === 0 ? "text-h1 md:text-md-h2" : "text-h2 md:text-md-h2")}>{props.container.title}</h3> : <></>}
                    {props.container.subtitle ? <h4 className="subtitle text-[.81rem] md:text-base">{props.container.subtitle}</h4> : <></>}
                </div> : <></>}

                <div className={classNames("px-4 xl:px-0", !props.container.showTitle ? "pt-6 md:pt-0" : "", hasBlocksCTA ? "md:grid md:grid-cols-2 md:gap-x-6" : "")}>

                    <div>
                        {props.container.showContentIntro && props.container.introduction ?
                            <div className={classNames("prose prose-antoniazzi max-w-none introduction", !head ? "text-p md:text-md-p" : "text-h3 md:text-md-h4")} dangerouslySetInnerHTML={{ __html: props.container.introduction || "" }} /> : <></>}
                        {props.container.description ?
                            <div className={classNames("prose prose-antoniazzi max-w-none description", !head ? "text-p md:text-md-p" : "text-h3 md:text-md-h4")} dangerouslySetInnerHTML={{ __html: props.container.description || "" }} /> : <></>}
                        {hasCta ? <div className="pt-6">
                            {props.container.externalUrl ? <a className=" inline-flex items-center underline text-sm md:text-base" href={props.container.externalUrl}>{ctaLabel} <Icon name="chevron" className="ml-2 w-4 h-4" /></a> : <></>}
                            {props.container.routeTo ? <Link className=" inline-flex items-center underline text-sm md:text-base" to={props.container.routeTo}>{ctaLabel} <Icon name="chevron" className="ml-2 w-4 h-4" /></Link> : <></>}
                        </div> : <></>}
                    </div>

                    {ctas && ctas.length > 0 ? <div>{ctas.map((cta, ctak) => {
                        return <div key={ctak} className="mt-6 md:mt-0">
                            {cta.routeTo ? <Link to={cta.routeTo || "/"} className="btn btn-outline-primary w-full block text-center text-sm">
                                {cta.title}
                            </Link> : <></>}
                            {cta.externalUrl ? <a href={cta.externalUrl || "/"} className="btn btn-outline-primary w-full block text-center text-sm">
                                {cta.title}
                            </a> : <></>}</div>
                    })}</div>

                        : <></>}
                </div>
            </div>
            {imagesList.length > 0 ? <div className="flex justify-center flex-wrap mt-8 mx-4 lg:mx-auto gap-8">
                {imagesList.map((i, k) => {
                    return <Multimedia loading="lazy" key={k} containerRef={i} className="w-32 grow-0 shrink-0" medium />
                })}
            </div> : <></>}
            {topImage ? <Multimedia loading="lazy" containerRef={topImage} large className="img-top block md:mt-2 order-0 w-full mb-2 lg:mb-14" /> : <></>}
            {leftImage ? <Multimedia loading="lazy" containerRef={leftImage} medium className="img-left block w-full order-2 mt-6 md:mt-0" /> : <></>}
            {rightImage ? <Multimedia loading="lazy" containerRef={rightImage} medium className={classNames("img-right block md:mt-0  md:order-2 w-full", props.dark ? "order-0" : "")} /> : <></>}
            {bottomImage ? <Multimedia loading="lazy" containerRef={bottomImage} large className="img-bottom block w-full" /> : <></>}

        </section>
    </>;
}


export function BodyArticles(props: { container: ContainerType }) {
    let [articles, setArticles] = useState<ContainerType[]>([]);
    const { dataBackend } = useFEContext();
    useEffect(() => {
        setArticles([]);
        if (props.container) {
            const _articles = props.container.containers?.filter(c => c.containerClass === 'CONTENT' && c.position === 'Center');
            if (_articles)
                dataBackend.getAll(_articles).then(r => { if (!r.error) { setArticles(r.records) } });
        }
    }, [props.container])
    const secondary = props.container.config.secondary || false;
    const dark = props.container.config.dark || false;
    const head = props.container.config.head || false;
    const titleHead = props.container.config.titleHead || false;
    const keepDistances = props.container.config.keepDistances || "";
    const padding = props.container.config.padding || "";
    const paddingTop = props.container.config.paddingTop || "";
    const centered = props.container.config.centered || "";
    const articlesReact =
        articles.map((ba, bak) => {
            return <BodyArticle dark={dark} index={bak} container={ba} key={bak} head={head} titleHead={titleHead} />
        })

    return (
        <section className={classNames(
            secondary ? "bg-antoniazzi-300/50 text-antoniazzi-900 block-articles-secondary pb-8 md:py-14" : "",
            dark ? "bg-dark text-white md:bg-white md:text-antoniazzi-900 block-articles-dark" : "",
            !secondary && !dark ? "bg-white text-antoniazzi-900" : "",
            padding ? "py-8 md:py-14" : "",
            paddingTop ? "pt-9 md:pt-14" : "",
            "block-articles",
            keepDistances ? "flex flex-col gap-y-8 md:gap-y-14 py-8 md:py-14" : "",
            centered ? "mx-auto max-w-5xl lg:px-8 lg:text-center" : ""
        )}>
            {articlesReact}
        </section>
    );
}
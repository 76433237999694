import React, { useCallback, useEffect, useState } from "react";
import { useFEContext } from "./FEContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { AlertType } from "../Interfaces";
export default function ViewStartResetPassword() {
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const [email, setEmail] = useState<string>("");
    const { showAlert, strings, dataBackend } = useFEContext();

    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);
    const update = () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (email) {
            dataBackend.startResetPassword(email, recaptchaToken).then(resp => {
                const al: AlertType = { active: true }
                if (!resp.error) {
                    al.severity = "success";
                    al.message = resp.message || strings.get("Operazione riuscita")
                    al.onClick = () => {
                        showAlert({ active: false });
                    }
                } else {
                    al.severity = "error";
                    al.message = resp.message || strings.get("Si è verificato un errore")
                }
                showAlert(al);
            });
        }
    }
    const onVerify = useCallback((token: string) => {
        //console.log(token);
        setRecaptchaToken(token);
    }, []);

    return <div className="bg-white text-antoniazzi-900 py-8 min-h-[65vh]">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
        <div className="mx-4 max-w-5xl lg:mx-auto ">
            <h3 className="text-h1 md:text-md-h1 mb-4">{strings.get("Password dimenticata?")}</h3>
            <p className="text-p md:text-md-p mb-6">{strings.get("Introduzione al recupero della password")}</p>
            <input required className="form-control-alt" placeholder={strings.get("Email") + "*"} type="email" value={email} onChange={(ev) => { setEmail(ev.target.value) }} />

            <button onClick={update} className="btn bg-antoniazzi-900 w-full md:w-auto hover:bg-antoniazzi-600 mt-2 text-white">{strings.get("Recupera password")}</button>
        </div>
    </div>
}

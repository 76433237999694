import React, { useEffect, useRef, useState } from "react";
import { ContainerRefType, formatPrice, ProductType, ContainerType, ProductRefType } from "../../Interfaces";
import { useFEContext } from "../FEContext";
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import classNames from "classnames";
import { Icon } from "../../IconLib";
import { Link } from "react-router-dom";
import { PreviewProdotto } from "../ViewBlocks";

function ProductScrollGallery(props: { items: ProductType[], currentSlide: number, setCurrentSlide: Function }) {

    const { currentSlide, setCurrentSlide, items } = props;
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        loop: false,
        initial: 0,
        rtl: false,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        mode: "snap",
        slides: { perView: "auto", spacing: 6 },
    })
    return <>
        {loaded && instanceRef.current && (
            <div className="absolute top-2 scroll-gallery-nav scroll-gallery-nav-products">
                <button className="pr-2 cursor-pointer" onClick={(e) => {
                    if (currentSlide > 0)
                        setCurrentSlide(currentSlide - 1);
                    e.stopPropagation();
                    instanceRef.current?.prev()
                }}
                    disabled={currentSlide === 0}
                ><Icon name="chevron" className="w-5 h-5 rotate-180" /> </button>
                <button className="pl-2 cursor-pointer" onClick={(e) => {
                    setCurrentSlide(currentSlide + 1);
                    e.stopPropagation();
                    instanceRef.current?.next()
                }
                }
                    disabled={
                        currentSlide ===
                        instanceRef.current.track.details.slides.length - 1
                    }> <Icon name="chevron" className="w-5 h-5" /></button>
            </div>
        )
        }
        <div className={classNames("scroll-gallery scroll-gallery-boxed scroll-gallery-products")}>
            <div ref={sliderRef} className="keen-slider">
                {items?.map((aa, aak) => {
                    return <div className="keen-slider__slide min-w-[16rem] max-w-[16rem]" key={aak}><PreviewProdotto prodotto={aa} /></div>
                })}
            </div>
        </div>
        {loaded && instanceRef.current && (<>
            <div className="mx-4 pt-0 pb-7 max-w-5xl xl:mx-auto">
                <input className={classNames(
                    "relative w-full box-border appearance-none  flex items-center cursor-pointer bg-transparent z-[19]",
                    "[&::-webkit-slider-thumb]:border-0 [&::-webkit-slider-thumb]:h-[3px] [&::-webkit-slider-thumb]:rounded-sm [&::-webkit-slider-thumb]:-translate-y-[1px] [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-antoniazzi-900 [&::-webkit-slider-runnable-track]:h-[1px] [&::-webkit-slider-runnable-track]:bg-antoniazzi-300 [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:appearance-none bg-opacity-80",
                    "[&::-webkit-slider-thumb]:w-[29px]")} type="range" step={1} min={0} max={items.length - 1} value={(currentSlide)} onChange={(ev) => {
                        setCurrentSlide(ev.target.valueAsNumber);
                        instanceRef.current?.moveToIdx(ev.target.valueAsNumber)
                    }} />
            </div>
        </>
        )} </>;
}

export default function ProductsHighlights(props: { container: ContainerType }) {
    const container = props.container;
    const [items, setItems] = useState<ProductType[]>([]);
    useEffect(() => {
        if (props.container) {
            setItems([]);
            setCurrentSlide(0);
            const payload = { "entity": "Product", searchParams: { highlight: true, limit: 10 } };
            const u = new URL(dataBackend.endpointBase + "/wa/DataBackend/list");
            u.searchParams.set("lang", "ita");
            u.searchParams.set("wosid", dataBackend.wosid);
            const opt: RequestInit = {
                method: "POST",
                body: JSON.stringify(payload)
            };
            fetch(u, opt).then(d => d.json()).then(data => {
                if (!data.error) {
                    setItems(data.records);
                }
            });
        }
    }, [props.container])

    const [currentSlideContainer, setCurrentSlideContainer] = useState<ProductType>();
    const [currentSlide, _setCurrentSlide] = useState(0);
    let ctas: ContainerRefType[] = [];
    const { dataBackend } = useFEContext();
    const setCurrentSlide = (inde) => {
        _setCurrentSlide(inde);
        if (items && items.length > 0) {
            setCurrentSlideContainer(items[inde])
        }
    }


    return <section data-container-id={container.id} className="scroll-gallery-section pt-9 md:pt-16">
        <div className="relative">
            <div className="mx-4 max-w-5xl xl:mx-auto min-h-[2rem] flex items-center justify-between mb-6">
                {container?.showTitle ? <h3 className="title text-h2 md:text-md-h2">{container.title}</h3> : <div className="text-h2 md:text-md-h2">&nbsp;</div>}
            </div>
            {container && container.introduction && container.introduction?.length > 0 ? <div className="relative mx-4 max-w-7xl xl:mx-auto mb-4">
                <div dangerouslySetInnerHTML={{ __html: container.introduction || "" }} />
                {ctas.map((cta, ctak) => {
                    return <div className="mt-4" key={ctak}>{cta.externalUrl ? <a className="inline-flex items-center underline" href={cta.externalUrl}>{cta.title || "Scopri di più"} <Icon name="chevron" className="ml-2 w-5 h-5" /></a> : <></>}</div>;
                })}
            </div> : <></>}

            {currentSlideContainer && currentSlideContainer.description && currentSlideContainer.description?.length > 0 ? <div className="relative mx-4 max-w-7xl xl:mx-auto mb-4" dangerouslySetInnerHTML={{ __html: currentSlideContainer.description || "" }} /> : <></>}

            {items && items.length > 0 ? <><ProductScrollGallery currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} items={items} /></> : <></>}
        </div>
    </section>;
}

export function ProductsRelateds(props: { items: ProductType[] }) {
    const { strings } = useFEContext();
    const { items } = props;
    //const [currentSlideContainer, setCurrentSlideContainer] = useState<ProductType>();
    const [currentSlide, _setCurrentSlide] = useState(0);
    let ctas: ContainerRefType[] = [];
    const setCurrentSlide = (inde) => {
        _setCurrentSlide(inde);
        /*if (items && items.length > 0) {
            setCurrentSlideContainer(items[inde])
        }*/
    }


    return <section className="scroll-gallery-section pt-9 md:pt-16">
        <div className="relative">
            <div className="mx-4 max-w-5xl xl:mx-auto min-h-[2rem] flex items-center justify-between mb-6">
                <h3 className="title text-h2 md:text-md-h2">{strings.get("Prodotti Suggeriti")}</h3>
            </div>
            {items && items.length > 0 ? <><ProductScrollGallery currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} items={items} /></> : <></>}
        </div>
    </section>;
}
/*
function ProductItem(props: { product: ProductType }) {
    const { product } = props;

    let price = (product.sizes && product.sizes?.length > 0 ? product.sizes[0].priceStandard : product.priceStandard);

    return <Link to={product.routeTo || "/"}>
        {product?.images && product.images.length > 0 ? <div className="aspect-w-1 aspect-h-1"><img className="object-cover object-center" src={product.images[0].imageSrcs?.small} /></div> : <></>}
        <h3 title={product.title} className="font-bold mt-4 whitespace-nowrap text-ellipsis overflow-hidden">{product.title}</h3>
        {product.sell ? <div className="text-sm">{price ? formatPrice(price) : "n.d."}</div> : <></>}
    </Link>;
}*/
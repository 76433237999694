import React, { useCallback, useEffect, useState } from "react";
import { AlertInline, useFEContext } from "./FEContext";
import { AlertType, UserType } from "../Interfaces";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { AvvisoAreaRiservata } from "./ViewOrdini";

export default function ViewProfilo() {


    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshReCaptcha(new Date().getTime() + "");
        }, 100 * 1000);
        return () => clearInterval(interval);
    }, []);

    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<any>("");
    const { showAlert, user, setUser, strings, dataBackend } = useFEContext();
    const [currentAlert, setCurrentAlert] = useState<AlertType>();
    const [passwordAlert, setPasswordAlert] = useState<AlertType>();
    const [currentUser, setCurrentUser] = useState<UserType>();
    useEffect(() => {
        if (!user?.ospite)
            setCurrentUser(user);
    }, [user]);
    const onVerify = useCallback((token: string) => {
        setRecaptchaToken(token);
    }, []);

    const startResetPassword = () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (currentUser?.email) {
            dataBackend.startResetPassword(currentUser?.email, recaptchaToken).then(resp => {
                const al: AlertType = { active: true }
                if (!resp.error) {
                    al.severity = "success";
                    al.message = resp.message || strings.get("Operazione riuscita")
                    al.onCancel = () => { setPasswordAlert(undefined) };
                    setPasswordAlert(al);
                } else {
                    al.severity = "error";
                    al.message = resp.message || strings.get("Si è verificato un errore");
                    showAlert(al);
                }
            });
        }
    };
    const confermaDatiAccount = () => {
        setRefreshReCaptcha(new Date().getTime() + "");
        if (currentUser && dataBackend.storeSessionId()) {
            setUser(currentUser);
            const al: AlertType = { active: true }
            al.severity = "success";
            al.message = strings.get("Operazione riuscita");
            al.onCancel = () => { setCurrentAlert(undefined) };
            setCurrentAlert(al)
        }
    }
    const updateProvincia = (ev) => { const u: UserType = currentUser ? { ...currentUser } : { altriIndirizzi: [], indirizzoPrincipale: {} }; u.indirizzoPrincipale.provincia = ev.target.value; setCurrentUser(u) };
    return <div className="bg-white text-antoniazzi-900 min-h-[65vh]">



        {
            currentUser ? <>

                <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
                <MenuAccount />
                <section className="px-4 mx-auto max-w-5xl py-6 md:px-0">

                    <h3 className="text-h1 md:text-md-h1 mb-4">{strings.get("Ciao") + " " + currentUser.nome}</h3>

                    <h3 className="text-h3 md:text-md-h3 mb-4">{strings.get("Account")}</h3>
                    <input type="text" className="form-control" required value={currentUser.email} onChange={(ev) => { const u = { ...currentUser }; u.email = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Email") + "*"} />
                    <input type="text" className="form-control" required value={currentUser.nome} onChange={(ev) => { const u = { ...currentUser }; u.nome = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Nome") + "*"} />
                    <input type="text" className="form-control" required value={currentUser.cognome} onChange={(ev) => { const u = { ...currentUser }; u.cognome = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Cognome") + "*"} />
                    <p className="text-p-sm md:text-md-p-sm">{strings.get("Per cambiare la tua password") + ", "}<button onClick={startResetPassword} className="underline">{strings.get("clicca qui")}</button></p>
                    {passwordAlert ? <AlertInline alert={passwordAlert} /> : <></>}
                    <div className="mt-4">
                        <button onClick={() => {
                            const u = { ...currentUser };
                            u.newsletter = !currentUser.newsletter;
                            setCurrentUser(u);
                        }} className="flex w-full text-left">
                            <div className={classNames("btn-checkbox mr-4", currentUser.newsletter ? "btn-checkbox-checked" : "")}></div>
                            <div>
                                <div className="text-p md:text-md-p mb-1">{strings.get("Newsletter")}</div>
                                <div className="text-p-sm md:text-md-p-sm">{strings.get("Acconsento al trattamento dei miei dati personali per ricevere comunicazioni commerciali o promozionali, tramite la newsletter.")}
                                </div>  </div>
                        </button>

                    </div>
                    <h3 className="text-h3 md:text-md-h3 mt-6 mb-4">{strings.get("Dati fatturazione")}</h3>
                    <div className="flex py-4 items-center">
                        <div className="mr-4">{strings.get("Sei un'azienda?")}</div>
                        <button className="px-4 items-center flex" onClick={() => {
                            const u = { ...currentUser };
                            u.azienda = true;
                            setCurrentUser(u);
                        }}>
                            <div className={classNames("btn-radio mr-2", !currentUser.azienda ? "" : "btn-radio-checked")}></div> {strings.get("Si")}</button>
                        <button className="px-4 items-center flex" onClick={() => {
                            const u = { ...currentUser };
                            u.azienda = false;
                            setCurrentUser(u);
                        }}>
                            <div className={classNames("btn-radio mr-2", currentUser.azienda ? "" : "btn-radio-checked")}></div> {strings.get("No")}</button>
                    </div>
                    <input type="text" className="form-control" required value={currentUser.codiceFiscale} onChange={(ev) => { const u = { ...currentUser }; u.codiceFiscale = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Codice fiscale") + "*"} />
                    {currentUser.azienda ? <>

                        <input type="text" className="form-control" required value={currentUser.ragioneSociale} onChange={(ev) => { const u = { ...currentUser }; u.ragioneSociale = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Ragione sociale") + "*"} />

                        <input type="text" className="form-control" required value={currentUser.partitaIva} onChange={(ev) => { const u = { ...currentUser }; u.partitaIva = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Partita IVA") + "*"} />
                        <input type="text" className="form-control" value={currentUser.codiceDestinatario} onChange={(ev) => { const u = { ...currentUser }; u.codiceDestinatario = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Codice SDI")} />
                        <input type="text" className="form-control" value={currentUser.pec} onChange={(ev) => { const u = { ...currentUser }; u.pec = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("PEC")} />
                    </> : <></>}
                    <input type="text" className="form-control" required value={currentUser.telefono} onChange={(ev) => { const u = { ...currentUser }; u.telefono = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Telefono") + "*"} />
                    <select className="form-control" required value={currentUser.indirizzoPrincipale.paese} onChange={(ev) => { const u: UserType = { ...currentUser }; u.indirizzoPrincipale.paese = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Paese") + "*"}><option value="">{strings.get("Paese...")}</option>{dataBackend.paesi.map((val, valk) => {
                        return <option key={valk} value={val.value}>{val.label}</option>;
                    })}</select>
                    <input type="text" className="form-control" required value={currentUser.indirizzoPrincipale.indirizzo} onChange={(ev) => { const u: UserType = { ...currentUser }; u.indirizzoPrincipale.indirizzo = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Indirizzo (Via e numero civico)") + "*"} />
                    <input type="text" className="form-control" required value={currentUser.indirizzoPrincipale.cap} onChange={(ev) => { const u: UserType = { ...currentUser }; u.indirizzoPrincipale.cap = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Codice postale") + "*"} />
                    <input type="text" className="form-control" required value={currentUser.indirizzoPrincipale.citta} onChange={(ev) => { const u: UserType = { ...currentUser }; u.indirizzoPrincipale.citta = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Città") + "*"} />
                    {currentUser.indirizzoPrincipale.paese === 'IT' ? <select className="form-control" onChange={updateProvincia} value={currentUser.indirizzoPrincipale.provincia}><option value="">{strings.get("Provincia...")}</option>{dataBackend.province.map((val, valk) => {
                        return <option key={valk} value={val.value}>{val.label}</option>
                    })}</select> : <input type="text" className="form-control !border-b" required value={currentUser.indirizzoPrincipale.provincia} onChange={updateProvincia} placeholder={strings.get("Provincia") + "*"} />}

                    <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={() => {
                        const u = { ...currentUser }; u.spedizioneDiversa = !u.spedizioneDiversa;
                        if (u.altriIndirizzi.length === 0 && u.spedizioneDiversa) {
                            u.altriIndirizzi.push({});
                        }
                        setCurrentUser(u)
                    }}>
                        <div className={classNames("btn-checkbox mr-2", currentUser.spedizioneDiversa ? "" : "btn-checkbox-checked")} /> {strings.get("Coincide con l'indirizzo di spedizione")}
                    </button>

                    {currentUser.spedizioneDiversa ? <>
                        {currentUser.altriIndirizzi.map((ai, aik) => {
                            const updateCurrentProvincia = (ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].provincia = ev.target.value; setCurrentUser(u) };
                            return <div key={aik}>
                                <h3 className="text-h3 md:text-md-h3 mb-4 mt-8">{strings.get("Indirizzo di spedizione")}</h3>

                                <div>
                                    <input type="text" className="form-control" required value={ai.presso} onChange={(ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].presso = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("C/O presso") + "*"} />
                                    <select className="form-control" required value={ai.paese} onChange={(ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].paese = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Paese") + "*"}><option value="">{strings.get("Paese...")}</option>{dataBackend.paesi.map((val, valk) => {
                                        return <option key={valk} value={val.value}>{val.label}</option>;
                                    })}</select>
                                    <input type="text" className="form-control" required value={ai.indirizzo} onChange={(ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].indirizzo = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Indirizzo (Via e numero civico)") + "*"} />
                                    <input type="text" className="form-control" required value={ai.cap} onChange={(ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].cap = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Codice postale") + "*"} />
                                    <input type="text" className="form-control" required value={ai.citta} onChange={(ev) => { const u: UserType = { ...currentUser }; u.altriIndirizzi[aik].citta = ev.target.value; setCurrentUser(u) }} placeholder={strings.get("Città") + "*"} />
                                    {ai.paese === 'IT' ? <select className="form-control" onChange={updateCurrentProvincia} value={ai.provincia}><option value="">{strings.get("Provincia...")}</option>{dataBackend.province.map((val, valk) => {
                                        return <option key={valk} value={val.value}>{val.label}</option>
                                    })}</select> : <input type="text" className="form-control" required value={ai.provincia} onChange={updateCurrentProvincia} placeholder={strings.get("Provincia") + "*"} />}
                                </div></div>
                        })}

                    </> : <></>}
                    <button className="flex text-left text-p md:text-md-p py-4 items-start" onClick={(ev) => { ev.preventDefault(); const u = { ...currentUser }; u.privacy = !u.privacy; setCurrentUser(u) }}>
                        <div className={classNames("btn-checkbox mr-2", !currentUser.privacy ? "" : "btn-checkbox-checked")} />
                        <div>{strings.get("Acconsento al trattamento dei dati personali secondo le ")} <a href="https://www.iubenda.com/privacy-policy/17913266" className="underline hover:no-underline iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe">{strings.get("normative privacy")}</a></div>
                    </button>

                    <button onClick={confermaDatiAccount} className="btn block mt-8 rounded-sm w-full text-white bg-antoniazzi-900 hover:bg-antoniazzi-600">{strings.get("Registra modifiche")}</button>
                    {currentAlert ? <AlertInline alert={currentAlert} /> : <></>}
                </section>

            </>
                :
                <>
                    <AvvisoAreaRiservata /></>
        }
    </div >
}


export function MenuAccount() {
    const { strings, user, terminateSession, showAlert } = useFEContext();
    const logout = () => {
        const al: AlertType = {
            active: true,
            confirm: true,
            onConfirm: () => { terminateSession(); showAlert({ active: false }); window.location.hash = ""; },
            severity: "warning",
            title: strings.get("Logout"),
            message: strings.get("Vuoi davvero uscire?")
        };
        showAlert(al);
    }
    return <div className="pt-0 lg:pt-11 bg-white">
        <div className="stripe-menu"> <div>
            <div className="flex max-w-5xl lg:mx-auto text-white h-10 leading-10 flex-nowrap gap-x-1 overflow-auto">
                {!user?.ospite ? <Link className="px-4 whitespace-nowrap" to="/profilo">{strings.get("Il mio profilo")}</Link> : <></>}
                <Link className="px-4 whitespace-nowrap" to="/ordini">{strings.get("I miei ordini")}</Link>
                {!user?.ospite ? <button onClick={logout} className="px-4 whitespace-nowrap">{strings.get("Esci")}</button> : <></>}
            </div>
        </div>
        </div>
    </div>;
}